import { Injectable } from "@angular/core";
import { UiSchemaCollection } from "@vp/formly/ui-schema-config";
import { OrganizationFeatures, RecordDataMapper } from "@vp/models";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, mergeAll, scan, shareReplay } from "rxjs/operators";

type Feature<T> = {
  [K in keyof T]: { key: K; value: T[K] };
}[keyof T];

export interface FeatureConfig {
  [key: string]: any;
}

@Injectable({
  providedIn: "root"
})
export class FeatureService {
  /**
   * Publish (next) new added values to this observable it emits emits instances of observables
   * that are accumulated by feature$.
   */
  private features$$: BehaviorSubject<Observable<Feature<FeatureConfig>>> = new BehaviorSubject<
    Observable<Feature<FeatureConfig>>
  >(of());

  /**
   * mergeAll, scan here accumulates all of the features as they are added to the service
   * any subscribers will receive ALL features that have been added.
   */
  private features$: Observable<Feature<FeatureConfig>[]> = this.features$$.pipe(
    mergeAll(),
    scan((acc: Feature<FeatureConfig>[], filter: Feature<FeatureConfig>) => {
      acc.push(filter);
      return acc;
    }, [] as Feature<FeatureConfig>[]),
    shareReplay(1)
  );

  add(feature: FeatureConfig): void {
    this.features$$.next(
      of({
        key: feature.friendlyId,
        value: feature
      })
    );
  }

  // returns a specific feature from the accumulated features
  feature$ = (feature: string): Observable<FeatureConfig | null> => {
    return this.features$.pipe(
      map((features: FeatureConfig[]) => {
        return (
          features.find((featureConfig: FeatureConfig) => {
            return featureConfig.key === feature;
          })?.value ?? null
        );
      })
    );
  };

  configurationLists$(feature: string): Observable<Record<string, string[]>> {
    return this.feature$(feature).pipe(
      map((featureConfig: FeatureConfig | null) => {
        return featureConfig !== null ? featureConfig["configurationLists"] : null;
      })
    );
  }

  configurationListValue$(feature: string, key: string): Observable<string[]> {
    return this.feature$(feature).pipe(
      map((featureConfig: FeatureConfig | null) => {
        return featureConfig !== null ? featureConfig["configurationLists"][key] : [];
      })
    );
  }

  configurationValues$(feature: string): Observable<Record<string, string>> {
    return this.feature$(feature).pipe(
      map((featureConfig: FeatureConfig | null) => {
        return featureConfig !== null ? featureConfig["configurationValues"] : null;
      })
    );
  }

  configurationValue$(feature: string, key: string): Observable<string> {
    return this.feature$(feature).pipe(
      map((featureConfig: FeatureConfig | null) => {
        return featureConfig !== null ? featureConfig["configurationValues"][key] : null;
      })
    );
  }

  configurationMappers$(feature: string): Observable<Record<string, RecordDataMapper[]>> {
    return this.feature$(feature).pipe(
      map((featureConfig: FeatureConfig | null) => {
        return featureConfig !== null ? featureConfig["configurationMappers"] : null;
      })
    );
  }

  configurationMapper$(feature: string, key: string): Observable<RecordDataMapper[]> {
    return this.feature$(feature).pipe(
      map((featureConfig: FeatureConfig | null) => {
        return featureConfig !== null ? featureConfig["configurationMappers"][key] : null;
      })
    );
  }

  featurePermission$(feature: string): Observable<Record<string, string[]>> {
    return this.feature$(feature).pipe(
      map((featureConfig: FeatureConfig | null) => {
        return featureConfig !== null ? featureConfig["featurePermissions"] : null;
      })
    );
  }

  featurePermissions$(feature: string, permission: string): Observable<string[]> {
    return this.feature$(feature).pipe(
      map((permissions: Record<string, string[]> | null) => {
        if (!!permissions && Object.keys(permission).length > 0 && !!permissions[permission]) {
          return permissions[permission];
        }
        return [];
      })
    );
  }

  messages$(feature: string): Observable<Record<string, string>> {
    return this.feature$(feature).pipe(
      map((featureConfig: FeatureConfig | null) => {
        return featureConfig !== null ? featureConfig["messages"] : null;
      })
    );
  }

  featureFlags$(feature: string): Observable<Record<string, boolean>> {
    return this.feature$(feature).pipe(
      map((featureConfig: FeatureConfig | null) => {
        return featureConfig !== null ? featureConfig["featureFlags"] : null;
      })
    );
  }

  featureEnabled$(feature: string): Observable<boolean> {
    return this.feature$(feature).pipe(
      map((featureConfig: FeatureConfig | null) => {
        return featureConfig !== null ? featureConfig["enabled"] : false;
      })
    );
  }

  featureFlagEnabled(feature: string, flag: string): Observable<boolean> {
    return this.featureFlags$(feature).pipe(
      map((featureItem: any) => {
        if (!featureItem) return false;
        return featureItem[flag] === true;
      })
    );
  }

  featureSchema$(feature: string, schema: string): Observable<Record<string, unknown>> {
    return this.feature$(feature).pipe(
      map((featureConfig: FeatureConfig | null) => {
        const schemas: Record<string, unknown>[] = featureConfig?.schemas;
        const result: Record<string, unknown> | undefined = schemas.find(s => s.id === schema);
        return result ?? {};
      })
    );
  }

  featureLayouts$(feature: string): Observable<UiSchemaCollection | null> {
    return this.feature$(feature).pipe(
      map((featureConfig: FeatureConfig | null) => {
        return featureConfig?.layoutConfig ?? null;
      })
    );
  }

  filterCommon(): Observable<unknown> {
    return new Observable(observer => {
      return this.feature$(OrganizationFeatures.common).subscribe({
        next: f => {
          observer.next(f);
        },
        error: err => {
          observer.error(err);
        },
        complete: () => {
          observer.complete();
        }
      });
    });
  }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { adminPermissions } from "@vp/models";
import { DepartmentSelectorModule } from "@vp/shared/components/department-selector";
import { AuthenticationGuard } from "@vp/shared/guards";
import { ContextDisplayModule } from "@vp/shared/pipes/context-display";
import { StatusIndicatorModule } from "@vp/virtual-care/ui/status-indicator";
import { NgxPermissionsGuard } from "ngx-permissions";
import { UserAdministrationPageComponent } from "../user-admin-page/user-administration-page.component";
import { DeviceListPageComponent } from "./device-list-page.component";

export const routes = [
  {
    path: "",
    canActivate: [AuthenticationGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: adminPermissions.Device.Read,
        redirectTo: "/administration/users"
      }
    },
    children: [
      {
        path: "",
        component: DeviceListPageComponent,
        data: {
          quickSearchTag: "deviceSearch",
          breadcrumb: "Device Admininistration"
        }
      },
      {
        path: ":id",
        component: UserAdministrationPageComponent,
        data: {
          breadcrumb: "Device Edit",
          permissions: [adminPermissions.Device.Write]
        }
      }
    ]
  }
];
@NgModule({
  imports: [
    CommonModule,
    ContextDisplayModule,
    DepartmentSelectorModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    MatTableModule,
    MatTooltipModule,
    MatSortModule,
    RouterModule.forChild(routes),
    StatusIndicatorModule
  ],
  declarations: [DeviceListPageComponent],
  exports: [DeviceListPageComponent]
})
export class DeviceListPageModule {}

import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { CaseDataFilter } from "@vp/data-access-case-filter";
import {
  CaseData,
  CaseFile,
  CaseResponse,
  CaseResultData,
  CaseResultDetail,
  CaseSearchSummary,
  CaseServiceFee,
  CaseStatusCount,
  CASETYPE_STATUS_CATEGORY__ALL,
  DicomStudy,
  Group,
  PageResult,
  PaymentRequestResponse
} from "@vp/models";
import { API_BASE_URL } from "@vp/shared/tokens";
import { Operation } from "rfc6902";
import { Observable, of, throwError } from "rxjs";
import { concatMap, map } from "rxjs/operators";

export interface PaymentTransactionRequest {
  amount?: number | null; // as decimal in API
  billToStreet?: string;
  billToZip?: string;
  creditCardNumber: string;
  creditCardExpirationMonth: string;
  creditCardExpirationYear: string;
  creditCardCvv2: string;
}

export interface CaseStatusUpdateResponse {
  statusChanged: boolean;
}

@Injectable({
  providedIn: "root"
})
export class CaseApiService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private readonly _http: HttpClient
  ) {}

  filteredCases = (
    filter: Partial<CaseDataFilter>,
    feature: string | undefined
  ): Observable<PageResult<CaseData>> => {
    if (!feature) {
      return throwError("Feature is required");
    }

    if (filter.take === undefined || filter.take === null) {
      filter.take = 10;
    }
    if (filter.skip === undefined || filter.skip === null) {
      filter.skip = 0;
    }
    let apiURL = `${this.apiBaseUrl}/cases/?take=${filter.take}&skip=${filter.skip}`;

    if (status && status !== CASETYPE_STATUS_CATEGORY__ALL.friendlyId) {
      apiURL = apiURL + `&filter=status.friendlyId=${status}`;
    }

    if (filter.caseTypeIds) {
      for (let i = 0; i < filter.caseTypeIds.length; i++) {
        apiURL = apiURL + `&filter=caseType.caseTypeId=${filter.caseTypeIds[i]}`;
      }
    }

    if (filter.search) {
      apiURL = apiURL + `&search=${filter.search}`;
    }
    if (filter.sort) {
      apiURL = apiURL + `&sort=${filter.sort}`;
    }
    if (filter.sortDir) {
      apiURL = apiURL + `&sortDir=${filter.sortDir}`;
    }
    if (filter.unassigned !== undefined) {
      apiURL = apiURL + `&unassigned=${filter.unassigned}`;
    }
    if (filter.tagIds && filter.tagIds?.length > 0) {
      filter.tagIds.forEach((tagId: string) => (apiURL += `&tag=${tagId}`));
    }
    if (filter) {
      apiURL = apiURL + `&feature=${feature}`;
    }

    return this._http.get<PageResult<CaseData>>(apiURL);
  };

  // Function parameters with default values should be last (typescript:S1788)
  // NOTE: To avoid moving arg positions, removed default assignments
  searchCases = (
    take: number | undefined | null,
    skip: number | undefined | null,
    status: string | undefined | null,
    caseType: string | undefined | null,
    department: string | undefined | null,
    search: string | undefined | null,
    unread: boolean | undefined | null,
    unresolved: boolean | undefined | null,
    tagIds: string[]
  ): Observable<PageResult<CaseSearchSummary>> => {
    // if getting unresolved or unread cases, clear search and status params so
    // the proper cases are retrieved
    if (unread || unresolved) {
      search = null;
      status = null;
    }

    if (take === undefined || take === null) {
      take = 10;
    }
    if (skip === undefined || skip === null) {
      skip = 0;
    }
    let apiURL = `${this.apiBaseUrl}/casesearch/?take=${take}&skip=${skip}`;
    if (status && status !== CASETYPE_STATUS_CATEGORY__ALL.friendlyId) {
      apiURL = apiURL + `&filter=status.friendlyId=${status}`;
    }
    if (caseType) {
      apiURL = apiURL + `&filter=caseType.caseTypeId=${caseType}`;
    }
    if (department) {
      apiURL = apiURL + `&filter=department.departmentId=${department}`;
    }
    if (search) {
      apiURL = apiURL + `&search=${search}`;
    }

    if (unresolved !== null && unresolved !== undefined) {
      apiURL += `&unresolved=${unresolved}`;
    }

    if (unread !== null && unread !== undefined) {
      apiURL += `&unread=${unread}`;
    }

    if (tagIds?.length > 0) {
      tagIds.forEach((tagId: string) => (apiURL += `&tag=${tagId}`));
    }

    return this._http.get<PageResult<CaseSearchSummary>>(apiURL);
  };

  getCase(caseId: string, caseTypeId: string | null = null): Observable<CaseData> {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}`;
    let params = new HttpParams();
    if (caseTypeId) {
      params = params.append("caseTypeId", String(caseTypeId));
    }
    return this._http.get<CaseData>(apiURL, {
      params: params
    });
  }

  getCaseSummaryForCurrentUser = (): Observable<CaseStatusCount[]> => {
    const apiURL = `${this.apiBaseUrl}/casesummary`;
    return this._http.get<CaseStatusCount[]>(apiURL);
  };

  getAssignableGroupsForCase(caseId: string): Observable<Group[]> {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/assignableGroups`;
    return this._http.get<Group[]>(apiURL);
  }

  deleteCase = (caseId: string): Observable<boolean> => {
    if (!caseId) {
      throw new Error("Case Id is required.");
    }

    const apiURL = `${this.apiBaseUrl}/case/${caseId}`;
    return this._http
      .delete(apiURL, {
        observe: "response"
      })
      .pipe(
        map(response => {
          if (response.status === 200) {
            return true;
          }
          return false;
        })
      );
  };

  updateCaseStatus = (caseId: string, statusId: string): Observable<boolean> => {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/status/${statusId}`;
    return this._http
      .put<CaseStatusUpdateResponse>(apiURL, null, {
        observe: "response"
      })
      .pipe(
        map((response: HttpResponse<unknown>) => {
          if (response.status === 200) {
            return true;
          }
          return false;
        })
      );
  };

  createCase(createCaseData: Partial<CaseData>, save: boolean = true): Observable<CaseData> {
    const apiURL = `${this.apiBaseUrl}/case`;
    let params = new HttpParams();
    params = params.append("save", String(save));
    return this._http
      .post<CaseData>(apiURL, createCaseData, {
        params: params,
        observe: "response"
      })
      .pipe(
        concatMap((response: HttpResponse<unknown>) => {
          if (response.status === 200) {
            return of(response.body as CaseData);
          }
          // could throw something other than a string error here for more
          // contextual handling.
          return throwError("Case Create Failed!");
        })
      );
  }

  /** @deprecated */
  updateCaseSummary(caseId: string, summary: string) {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/summary`;
    return this._http
      .put(
        apiURL,
        { summary },
        {
          observe: "response"
        }
      )
      .pipe(
        map((response: HttpResponse<unknown>) => {
          if (response.status === 200) {
            return true;
          }
          return false;
        })
      );
  }

  updateCaseSummaryViewNotes(caseId: string, summaryViewNotes: string) {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/summaryViewNotes`;
    return this._http
      .put(
        apiURL,
        { summaryViewNotes },
        {
          observe: "response"
        }
      )
      .pipe(
        map((response: HttpResponse<unknown>) => {
          if (response.status === 200) {
            return true;
          }
          return false;
        })
      );
  }

  paymentRequest(
    caseId: string,
    paymentRequest: PaymentTransactionRequest
  ): Observable<PaymentRequestResponse> {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/payment`;
    return this._http.post<PaymentRequestResponse>(apiURL, paymentRequest, {
      observe: "body"
    });
  }

  submitCase(caseId: string): Observable<boolean> {
    if (!caseId) {
      throw Error("caseId is required");
    }
    const apiUrl = `${this.apiBaseUrl}/case/${caseId}/submit`;
    return this._http
      .put(apiUrl, null, {
        observe: "response"
      })
      .pipe(
        map((response: HttpResponse<unknown>) => {
          return response.status === 200;
        })
      );
  }

  approveCase(caseId: string, isApproved: boolean): Observable<CaseData> {
    const apiUrl = `${this.apiBaseUrl}/case/${caseId}/approval/${isApproved}`;

    return this._http.get<CaseData>(apiUrl);
  }

  // Service Functions

  addCaseService(caseId: string, caseService: CaseServiceFee): Observable<boolean> {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/servicefees`;
    return this._http.post<boolean>(apiURL, caseService);
  }

  editCaseService(caseId: string, caseService: CaseServiceFee): Observable<boolean> {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/servicefees`;
    return this._http.put<boolean>(apiURL, caseService);
  }

  deleteCaseService(caseId: string, serviceFeeId: string): Observable<boolean> {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/servicefees/${serviceFeeId}`;
    return this._http.delete<boolean>(apiURL);
  }

  // Group Functions

  public removeGroupFromCase = (caseId: string, groupId: string): Observable<boolean> => {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/group/${groupId}`;
    return this._http
      .delete(apiURL, {
        observe: "response"
      })
      .pipe(map((httpResponse: HttpResponse<unknown>) => httpResponse.ok));
  };

  public assignGroupToCase = (caseId: string, groupId: string): Observable<boolean> => {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/group/${groupId}`;
    return this._http
      .put(apiURL, null, {
        observe: "response"
      })
      .pipe(map((httpResponse: HttpResponse<unknown>) => httpResponse.ok));
  };

  // Result Functions

  createResult(
    caseId: string,
    caseResultData: Partial<CaseResultData>,
    finishLater = false
  ): Observable<boolean> {
    let apiURL = `${this.apiBaseUrl}/case/${caseId}/result`;
    if (finishLater) {
      apiURL += `?finish-later=${finishLater}`;
    }
    return this._http
      .post<CaseResultDetail>(apiURL, caseResultData, {
        observe: "response"
      })
      .pipe(map((httpResponse: HttpResponse<unknown>) => httpResponse.ok));
  }

  updateResult(
    caseId: string,
    caseResultData: Partial<CaseResultData>,
    finishLater = false
  ): Observable<boolean> {
    let apiURL = `${this.apiBaseUrl}/case/${caseId}/result/${caseResultData.resultId}`;
    if (finishLater) {
      apiURL += `?finish-later=${finishLater}`;
    }
    return this._http
      .put<CaseResultDetail>(apiURL, caseResultData, {
        observe: "response"
      })
      .pipe(map((httpResponse: HttpResponse<unknown>) => httpResponse.ok));
  }

  deleteResult(caseId: string, resultId: string): Observable<boolean> {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/result/${resultId}`;
    return this._http
      .delete(apiURL, {
        observe: "response"
      })
      .pipe(map((httpResponse: HttpResponse<unknown>) => httpResponse.ok));
  }

  // Response Functions

  public getResponse(
    caseId: string,
    resultId: string,
    templateId: string
  ): Observable<null | string> {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/response/result/${resultId}/template/${templateId}`;
    return this._http
      .get(apiURL, {
        observe: "response",
        responseType: "text"
      })
      .pipe(
        map((response: HttpResponse<unknown>) => {
          if (response.ok) {
            return response.body as string;
          }
          return null;
        })
      );
  }

  public saveResponse(caseId: string, caseResponse: Partial<CaseResponse>): Observable<boolean> {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/response`;
    return this._http
      .post<boolean>(apiURL, caseResponse, {
        observe: "response"
      })
      .pipe(map((httpResponse: HttpResponse<unknown>) => httpResponse.ok));
  }

  public updateResponse(
    caseId: string,
    caseResponse: Partial<CaseResponse>
  ): Observable<CaseResponse> {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/response/${caseResponse.responseId}`;
    return this._http.put<CaseResponse>(apiURL, caseResponse, {
      observe: "body"
    });
  }

  public daleteResponse(caseId: string, responseId: string): Observable<boolean> {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/response/${responseId}`;
    return this._http
      .delete(apiURL, {
        observe: "response"
      })
      .pipe(map((httpResponse: HttpResponse<unknown>) => httpResponse.ok));
  }

  public generateResponsePdf(
    caseId: string,
    caseResponse: CaseResponse,
    fileName?: string
  ): Observable<CaseFile> {
    let apiUrl = `${this.apiBaseUrl}/case/${caseId}/pdf`;
    if (fileName) {
      apiUrl += `/${fileName}`;
    }
    return this._http.post<CaseFile>(apiUrl, caseResponse, { observe: "body" });
  }

  // Dicom functions

  public updateDicomStudy(caseId: string, dicomStudy: DicomStudy) {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/dicom-study-update`;
    return this._http.post(apiURL, dicomStudy);
  }

  public deleteDicomStudy(caseId: string, studyInstanceUid: string) {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}/dicom-study-delete/${studyInstanceUid}`;
    return this._http.delete(apiURL);
  }

  // Shared functions

  public patch = (caseId: string, operations: Operation[]) => {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}`;
    return this._http.patch(apiURL, operations);
  };
}

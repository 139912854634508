import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { User, UserRole } from "@vp/models";
import { Logger } from "@vp/shared/logging-service";
import { parseError } from "@vp/shared/utilities";
import { throwError } from "rxjs";
import { catchError, take, tap } from "rxjs/operators";
import { UserApiService } from "../api/user-api.service";
import { defaultUserFilter, UserFilter } from "../models/user-filter";
import * as UserStateActions from "./user.actions";

export type UserStateModel = {
  currentFilter: UserFilter | null;
  filteredUsers: User[];
  currentUser: User | null;
  errors: string[];
};

@State<UserStateModel>({
  name: "user",
  defaults: {
    currentFilter: defaultUserFilter(),
    filteredUsers: [],
    currentUser: null,
    errors: []
  }
})
@Injectable()
export class UserState {
  constructor(private logger: Logger, private api: UserApiService) {}

  @Selector()
  public static currentUser(state: UserStateModel) {
    return state.currentUser;
  }

  @Selector()
  public static currentUserRole(state: UserStateModel) {
    return state.currentUser?.roles.find(
      (userRole: UserRole) => userRole.roleId === state.currentUser?.selectedRoleId
    );
  }

  @Selector()
  public static currentFilter(state: UserStateModel) {
    return state.currentFilter;
  }

  @Selector()
  public static filteredUsers(state: UserStateModel) {
    return state.filteredUsers;
  }

  /**
   * Retrieves a user by its userId from the server and sets the state with the response
   */
  @Action(UserStateActions.SetCurrentUser)
  setCurrentUser(ctx: StateContext<UserStateModel>, { userId }: UserStateActions.SetCurrentUser) {
    return this.api.getUser(userId).pipe(
      tap(user => {
        ctx.patchState({ currentUser: user });
      }),
      catchError(error => {
        ctx.patchState({
          currentUser: null,
          errors: parseError(error)
        });
        return throwError(error);
      }),
      take(1)
    );
  }

  @Action(UserStateActions.SetCurrentUserRole)
  setCurrentUserRole(
    ctx: StateContext<UserStateModel>,
    { roleId }: UserStateActions.SetCurrentUserRole
  ) {
    const user: User | null = ctx.getState().currentUser;
    if (user === null) {
      const error: Error = {
        name: "CurrentUser null exception",
        message: "Current user not set in user state"
      };
      this.logger.logException(error, "UserStateActions.SetCurrentUserRole");
    } else {
      user.selectedRoleId = roleId;
      ctx.patchState({ currentUser: user });
    }
  }

  @Action(UserStateActions.SetFilter)
  setFilter(ctx: StateContext<UserStateModel>, { filter: filter }: UserStateActions.SetFilter) {
    return this.api
      .getUsersPageResult(
        filter.take,
        filter.skip,
        filter.sort,
        filter.sortDirection,
        filter.search,
        filter.filters
      )
      .pipe(
        tap(pageResult => {
          ctx.patchState({ filteredUsers: pageResult.results });
        }),
        catchError(error => {
          ctx.patchState({
            filteredUsers: [],
            errors: parseError(error)
          });
          return throwError(error);
        })
      );
  }
}

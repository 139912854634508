import { Injectable } from "@angular/core";
import { Select } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import {
  AssignedRolePerDepartment,
  Department,
  DepartmentRole,
  Organization,
  OrganizationFeatures,
  Role,
  User,
  UserRole
} from "@vp/models";
import { IAssignmentService } from "@vp/shared/assignments/models";
import { FeatureService } from "@vp/shared/features";
import { filterNullMap } from "@vp/shared/operators";
import { AppStoreService } from "@vp/shared/store/app";
import { sortBy } from "lodash";
import { combineLatest, Observable } from "rxjs";
import { map, shareReplay, switchMap, withLatestFrom } from "rxjs/operators";
import { UserAdministrationService } from "./user-administration.service";

@Injectable()
export class RolesAssignmentService implements IAssignmentService {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;

  assignableEntities$: Observable<AssignedRolePerDepartment[]>;

  private _assignedRoles$: Observable<AssignedRolePerDepartment[]>;
  private _allRoles$: Observable<AssignedRolePerDepartment[]>;

  constructor(
    private readonly appStore: AppStoreService,
    private readonly featureService: FeatureService,
    private readonly userAdministrationService: UserAdministrationService
  ) {
    this._allRoles$ = this.featureService
      .featureEnabled$(OrganizationFeatures.allRolesAssignable)
      .pipe(
        withLatestFrom(
          this.featureService.configurationLists$(OrganizationFeatures.allRolesAssignable),
          this.appStore.selectedRole,
          this.userAdministrationService.workingCopy$.pipe(filterNullMap())
        ),
        switchMap(
          ([featureEnabled, lists, selectedRole, workingCopy]: [
            boolean,
            Record<string, string[]>,
            Role,
            User
          ]) => {
            if (featureEnabled === true) {
              const roles: string[] = lists["roles"];
              if (roles.includes(selectedRole.friendlyId)) {
                return this.organization$.pipe(
                  map((org: Organization) => {
                    const models: AssignedRolePerDepartment[] = [];
                    const assignableRoles: string[] | undefined = org.userTypeConfig.find(
                      ut => ut.type == workingCopy.userType.friendlyId
                    )?.assignableRoleFriendlyId;
                    org.departments.map((dept: Department) => {
                      dept.roles.forEach((deptRole: DepartmentRole) => {
                        if (assignableRoles && assignableRoles.includes(deptRole.friendlyId)) {
                          const model: AssignedRolePerDepartment = {
                            departmentId: dept.departmentId,
                            roleId: deptRole.roleId
                          };
                          models.push(model);
                        }
                      });
                    });
                    return models;
                  })
                );
              }
            }
            return this.appStore.userStream.pipe(
              filterNullMap(),
              map(user => mapToVm(user.roles))
            );
          }
        )
      );

    this._assignedRoles$ = this.userAdministrationService.workingCopy$.pipe(
      filterNullMap(),
      map(user => mapToVm(user.roles))
    );

    this.assignableEntities$ = combineLatest([this._assignedRoles$, this._allRoles$]).pipe(
      map(([assigned, all]: [AssignedRolePerDepartment[], AssignedRolePerDepartment[]]) => {
        return all.filter(
          e =>
            // Exclude already assigned items from assignable list
            assigned.findIndex(r => r.departmentId === e.departmentId && r.roleId === e.roleId) < 0
        );
      }),
      withLatestFrom(this.organization$.pipe(shareReplay())),
      map(([assignables, org]: [AssignedRolePerDepartment[], Organization]) => {
        const models = assignables.map(item => {
          const roleMatch = org.roles.find(r => r.roleId === item.roleId);
          const deptMatch = org.departments.find(d => d.departmentId === item.departmentId);
          // Stuff the searchable content together in a string that is not displayed
          // but inspected by the filterTerm pipe for the purposes of searching.
          item.search = `
            ${deptMatch?.displayName ?? ""}
            ${deptMatch?.description ?? ""}
            ${roleMatch?.displayName ?? ""}`;
          return item;
        });
        return sortBy(models, "[search]");
      })
    );
  }
}

const mapToVm = (userRoles: UserRole[]) => {
  return userRoles.reduce((a: AssignedRolePerDepartment[], role: UserRole) => {
    const w = role.departments.map(d => {
      return {
        departmentId: d.departmentId,
        roleId: role.roleId
      } as AssignedRolePerDepartment;
    });
    a = a.concat(w);
    return a;
  }, []);
};

<form [formGroup]="formGroup">
  <angular-editor
    id="templateEditor"
    formControlName="htmlContent"
    [config]="config"
    [placeholder]="'...'"
  >
  </angular-editor>
  <div fxLayout="row" fxLayoutAlign="end center">
    <button type="button"
    mat-flat-button
    color="primary" (click)="onCancel()">
      <span >Close</span>
    </button>
  </div>
</form>

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { contentManagementPermissions, globalPermissions, MenuItem } from "@vp/models";
import { AuthenticationGuard } from "@vp/shared/guards";
import { NgxPermissionsGuard } from "ngx-permissions";
import { ContentManagementComponent } from "./content-management.component";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { NewContentPageComponent } from "@vp/content-management/ui/new-content-page";

let routes: Routes = [];
routes = [
  {
    path: "",
    canActivate: [AuthenticationGuard, NgxPermissionsGuard],
    canActivateChild: [NgxPermissionsGuard],
    data: {
      breadcrumb: "contentManagementNav",
      permissions: {
        only: [globalPermissions.ContentManagementPage.View],
        redirectTo: "/loggedin"
      }
    },
    children: [
      { path: "", redirectTo: "content", pathMatch: "full" },
      {
        path: "",
        component: ContentManagementComponent,
        data: {
          permissions: {
            only: globalPermissions.ContentManagementPage.View,
            redirectTo: "/loggedin"
          },
          quickSearchTag: "contentSearch",
          pageMenuItems: [
            {
              label: "contentManagerBreadcrumbButton",
              routerLink: "/content/new-content",
              permissions: [contentManagementPermissions.Write]
            } as MenuItem
          ]
        }
      },
      {
        path: "new-content",
        component: NewContentPageComponent,
        data: {
          permissions: {
            only: contentManagementPermissions.Write,
            redirectTo: "/content/new-content"
          },
          breadcrumb: "contentManagerBreadcrumbButton"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContentManagementRoutingModule {}

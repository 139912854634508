<ng-container
[ngTemplateOutlet]="template"
[ngTemplateOutletContext]="{
  menuMode: (menuMode$ | async) ?? 'side',
  opened: opened$ | async,
  pageMode: pageMode$ | async,
  showToggle: showToggle$ | async
}"
>
<ng-template
  #template
  let-menuMode="menuMode"
  let-opened="opened"
  let-pageMode="pageMode"
  let-showToggle="showToggle"
>

  <mat-drawer-container
    autosize
    class="drawer-container"
    fxFlexFill
    (backdropClick)="toggleSideNav()"
  >
    <mat-drawer #drawer [mode]="menuMode" [opened]="opened">
      <vp-content-management-left-menu
      [opened]="opened || false"
      (sidenavToggle)="toggleSideNav()"
      ></vp-content-management-left-menu>
    </mat-drawer>
    <mat-drawer-content>
      <vp-content-management-dashboard>
      </vp-content-management-dashboard>
    </mat-drawer-content>
  </mat-drawer-container>
</ng-template>
</ng-container>

import { UserFilter } from "../models/user-filter";

export class SetCurrentUser {
  public static readonly type = "[Users] Set Current User";
  constructor(public userId: string) {}
}

export class SetCurrentUserRole {
  public static readonly type = "[Users] Set Current UserRole";
  constructor(public roleId: string) {}
}

export class SetFilter {
  public static readonly type = "[Users] Set Filter";
  constructor(public filter: Partial<UserFilter>) {}
}

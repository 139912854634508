import { Inject, Injectable, InjectionToken } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { Select } from "@ngxs/store";
import { CaseTypesState } from "@vp/data-access/case-types";
import { OrganizationState } from "@vp/data-access/organization";
import { CaseType, Organization, User } from "@vp/models";
import { AuthenticationService } from "@vp/shared/authentication";
import { CaseContextService } from "@vp/shared/case-context";
import { filterNullMap } from "@vp/shared/operators";
import { AppStoreService } from "@vp/shared/store/app";
import { NgxPermissionsService } from "ngx-permissions";
import { combineLatest, Observable, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";

export const IS_IVY_API = new InjectionToken<boolean>("IS_IVY_API");

@Injectable({ providedIn: "root" })
export class AuthenticationGuard implements CanActivate {
  @Select(CaseTypesState.allCaseTypes) caseTypes$!: Observable<CaseType[]>;
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;

  constructor(
    @Inject(IS_IVY_API) private readonly isIvyApi: boolean,
    private readonly appStoreService: AppStoreService,
    private readonly authenticationService: AuthenticationService,
    private readonly caseContextService: CaseContextService,
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    const theRoute = this.router.url;
    return this.authenticationService.isLoggedIn$().pipe(
      switchMap((isAuthenticated: boolean) => {
        if (isAuthenticated) {
          return combineLatest([
            this.appStoreService.stateChanged.pipe(map(state => state.user)),
            this.organization$.pipe(filterNullMap()),
            this.caseContextService.caseData$,
            this.caseTypes$.pipe(filterNullMap())
          ]).pipe(
            tap(([user, organization, caseData, caseTypes]) => {
              this.ngxPermissionsService.flushPermissions();
              const currentPermissions: string[] = [];
              if (user && organization) {
                addGlobalPermissions(organization, user, currentPermissions);
                if (caseData) {
                  const found = caseTypes.find(
                    caseType => caseType.caseTypeId === caseData.caseType.caseTypeId
                  );
                  if (found) {
                    getCaseTypePermissions(found, user, currentPermissions);
                  }
                }
              }
              // Load permissions into NgxPermissionsService (duplicates removed)
              this.ngxPermissionsService.loadPermissions(currentPermissions);
            }),
            map(() => {
              return true;
            })
          );
        }
        return of(isAuthenticated);
      }),
      map((isAuthenticated: boolean) => {
        if (this.isIvyApi) {
          if (theRoute === "/wizard" || theRoute === "/") {
            return true;
          } else {
            return this.router.createUrlTree(["/wizard"]);
          }
        } else if (!isAuthenticated) {
          return this.router.createUrlTree(["/home"]);
        }
        return true;
      })
    );
  }

  canLoad(): Observable<boolean | UrlTree> {
    const theRoute = this.router.url;
    return this.authenticationService.isLoggedIn$().pipe(
      map((isAuthenticated: boolean) => {
        if (this.isIvyApi) {
          if (theRoute === "/wizard" || theRoute === "/") {
            return true;
          } else {
            return this.router.createUrlTree(["/wizard"]);
          }
        } else if (!isAuthenticated) {
          return this.router.createUrlTree(["/home"]);
        }

        return true;
      })
    );
  }
}

/**
 * Get user's global (non-case type) permissions
 * @param organization The current organization object
 * @param user The current user object
 * @param currentPermissions Updated permissions by ref
 */
const addGlobalPermissions = (
  organization: Organization,
  user: User,
  currentPermissions: string[]
) => {
  const selectedOrganizationRole = organization.roles.find(
    role => role.roleId === user.selectedRoleId
  );
  if (selectedOrganizationRole) {
    currentPermissions.push(...selectedOrganizationRole.permissions);
  }
};

/**
 * Get user's non-case type permissions
 * @param caseType The current case type object
 * @param user The current user object
 * @param currentPermissions Updated permissions by ref
 */
const getCaseTypePermissions = (caseType: CaseType, user: User, currentPermissions: string[]) => {
  caseType.rolePermissions.forEach(rolePermissionGroup => {
    const hasRole = rolePermissionGroup.roles.find(role => role.roleId === user.selectedRoleId);
    if (hasRole) {
      currentPermissions.push(...rolePermissionGroup.permissions);
    }
  });
};

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { GenericDialogModule } from "@vp/shared/components/generic-dialog";
import { MaterialModule } from "@vp/shared/material";
import { ContextDisplayModule } from "@vp/shared/pipes/context-display";
import { UtilitiesModule } from "@vp/shared/utilities";
import { NgxPermissionsModule } from "ngx-permissions";
import { UserAssignTagsModule } from "../user-assign-tags/user-assign-tags.module";
import { UserAssignedTagsComponent } from "./user-assigned-tags.component";

@NgModule({
  imports: [
    CommonModule,
    ContextDisplayModule,
    FlexLayoutModule,
    GenericDialogModule,
    MaterialModule,
    MatExpansionModule,
    MatSlideToggleModule,
    NgxPermissionsModule,
    UserAssignTagsModule,
    UtilitiesModule
  ],
  declarations: [UserAssignedTagsComponent],
  exports: [UserAssignedTagsComponent]
})
export class UserAssignedTagsModule {}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ViewContentPageComponent } from "./view-content-page.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "@vp/shared/material";

@NgModule({
  imports: [CommonModule, AngularEditorModule, FormsModule, ReactiveFormsModule, MaterialModule],
  declarations: [ViewContentPageComponent]
})
export class ViewContentPageModule {}

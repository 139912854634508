<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    assignedTags: (assignedTags$ | async) || [],
    columns: (displayedColumns$ | async) || []
  }"
>
  <ng-template #template let-assigned="assignedTags" let-columns="columns">
    <div class="border-wrapper">
      <mat-expansion-panel>
        <mat-expansion-panel-header class="panel-header"
          ><mat-panel-title>Assigned Tags</mat-panel-title>
          <mat-panel-description
            ><span>Assigned: {{ assigned.length }}</span></mat-panel-description
          ></mat-expansion-panel-header
        >
        <div *ngIf="assigned.length > 0">
          <mat-table #assignedTable [dataSource]="assigned">
            <ng-container *ngFor="let column of columns; let i = index">
              <ng-container
                *ngIf="column.field !== 'actions'; else actions"
                [matColumnDef]="column.field"
              >
                <mat-header-cell *matHeaderCellDef>{{ column.header }}</mat-header-cell>
                <mat-cell *matCellDef="let item">
                  <span *ngIf="!column.pipe; else piped">{{ item[column.field] }}</span>
                  <ng-template #piped>
                    <span *ngIf="column.pipe">{{
                      item[column.field] | dynamicLookup: column.pipe:item[column.field] | async
                    }}</span>
                  </ng-template>
                </mat-cell>
              </ng-container>
              <ng-template #actions>
                <ng-container matColumnDef="actions" stickyEnd="true">
                  <mat-header-cell *matHeaderCellDef fxLayoutAlign="end center"
                    >Delete</mat-header-cell
                  >
                  <mat-cell *matCellDef="let tag" fxLayoutAlign="end center">
                    <button
                      type="button"
                      mat-icon-button
                      aria-hidden="false"
                      aria-label="Delete forever icon"
                      (click)="unassignHandler(tag)"
                    >
                      <mat-icon> delete_forever </mat-icon>
                    </button>
                  </mat-cell>
                </ng-container>
              </ng-template>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columns | mapToField: 'field'"></mat-header-row>
            <mat-row *matRowDef="let tag; columns: columns | mapToField: 'field'"></mat-row>
          </mat-table>
        </div>
        <mat-action-row>
          <button
            *ngxPermissionsOnly="[permConst.Admin.User.RoleAssignment.Write]"
            type="button"
            mat-flat-button
            color="primary"
            (click)="assignHandler()"
          >
            Assign Tags(s)
          </button>
        </mat-action-row>
      </mat-expansion-panel>
    </div>
  </ng-template>

  <ng-template #modalTemplate>
    <vp-user-assign-tags></vp-user-assign-tags>
    <!--
      <vp-dialog-button>Close</vp-dialog-button>
      <vp-dialog-button color="primary">Save </vp-dialog-button>
    -->
  </ng-template>
</ng-container>

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { Select } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { TagsState } from "@vp/data-access/tags";
import { Organization, Tag, TagType } from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import { compare } from "@vp/shared/utilities";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import { map, takeUntil, withLatestFrom } from "rxjs/operators";

export interface TagSelection {
  tagTypeFriendlyId: string | null;
  tagId?: string | null;
}

@Component({
  selector: "vp-tag-selector",
  templateUrl: "./tag-selector.component.html",
  styleUrls: ["./tag-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagSelectorComponent implements OnInit, OnDestroy, OnChanges {
  formControl = new FormControl();

  @Select(OrganizationState.organization) organization$!: Observable<Organization>;
  @Select(TagsState.tags) tags$!: Observable<Tag[]>;

  @Input() selectedTag: string | null = null;
  @Input() tagTypeFriendlyId: string | null = null;
  @Input() filterByTagPathId: string | null = null;
  @Input() noneSelectedText = "All";
  @Output() changedEvent = new EventEmitter<TagSelection>();

  private readonly _selectedTagType$ = new BehaviorSubject<string | null>(null);
  private readonly _availableTags$ = new BehaviorSubject<Tag[]>([]);
  private readonly destroyed$ = new Subject<void>();
  private readonly filterByTagTypeIdSubject = new BehaviorSubject<string | null>(null);
  private readonly selectedTagSubject = new BehaviorSubject<TagSelection>({
    tagTypeFriendlyId: null,
    tagId: null
  });

  availableTags$: Observable<Tag[]> = combineLatest([
    this._availableTags$,
    this.filterByTagTypeIdSubject
  ]).pipe(
    map(([tags, filter]: [Tag[], string | null]) => {
      return filter
        ? tags.filter(t => {
            const tagParts = t.tagPath.split(".");
            if (tagParts.length > 1) {
              return filter === tagParts[tagParts.length - 1];
            }
            return filter === t.tagPath;
          })
        : tags;
    })
  );

  hasTags$ = this.availableTags$.pipe(map(tags => tags.length > 0));

  tagType$: Observable<TagType | undefined> = this._selectedTagType$.pipe(
    filterNullMap(),
    withLatestFrom(this.organization$),
    map(([selectedTagType, org]: [string, Organization]) =>
      org.tagTypes.find(t => t.friendlyId === selectedTagType)
    ),
    filterNullMap()
  );

  ngOnInit(): void {
    this._selectedTagType$
      .pipe(
        filterNullMap(),
        withLatestFrom(this.tags$),
        map(([selectedTagType, tags]: [string, Tag[]]) => {
          const filteredTags = tags.filter(tag => tag.tagTypeFriendlyId === selectedTagType);
          return filteredTags.sort((a, b) => compare(a.displayName, b.displayName, true));
        })
      )
      .subscribe((tags: Tag[]) => this._availableTags$.next(tags));

    this.selectedTagSubject.pipe(takeUntil(this.destroyed$)).subscribe(selected => {
      this.changedEvent.emit(selected);
    });

    // if a filter has been set in the input then apply that to the filter subject
    if (this.filterByTagPathId) {
      this.filterByTagTypeIdSubject.next(this.filterByTagPathId);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.tagTypeFriendlyId?.currentValue) {
      this._selectedTagType$.next(changes.tagTypeFriendlyId.currentValue);
    }

    if (changes?.filterByTagPath?.currentValue) {
      this.filterByTagTypeIdSubject.next(changes.filterByTagPath.currentValue);
    }
  }

  selectionChanged($event: MatSelectChange) {
    this.selectedTagSubject.next({
      tagTypeFriendlyId: this.tagTypeFriendlyId,
      tagId: $event.value ?? null
    });
  }
}

<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    user: (user$ | async),
    hasPending: (hasPending$ | async)
  }"
>
</ng-container>

<ng-template #template let-user="user" let-userId="userId" let-hasPending="hasPending">
  <mat-card fxFlexFill fxLayout="column" fxLayoutAlign="space-between stretch" *ngIf="form">
    <div fxFlex fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="16px">
      <div fxLayout="column" fxFlex="55" fxFlex.lt-md="100" fxLayoutGap="16px">
        <form fxFill [formGroup]="form">
          <div [ngSwitch]="user.userType.friendlyId" fxLayout="column" fxLayoutGap="16px">
            <ng-container *ngSwitchCase="'login-user'">
              <div fxLayout="row" fxLayoutAlign="space-between center" class="status-row">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                  <span>User State:</span>
                  <mat-slide-toggle
                    *ngxPermissionsOnly="[permConst.Admin.User.Write]"
                    formControlName="active"
                    [matTooltip]="user.active ? 'User is active' : 'User is inactive'"
                  ></mat-slide-toggle>
                  <span>{{ user.active ? "Active" : "Inactive" }}</span>
                </div>
                <div>
                  <mat-label fxLayout="row wrap" *ngIf="user.lastLoginDateTime !== null"
                    >Last Login</mat-label
                  >
                  <mat-label>{{ user.lastLoginDateTime | date: "short" }}</mat-label>
                </div>
                <div>
                  <button
                    type="button"
                    *ngIf="user.invitationEmail !== null"
                    mat-flat-button
                    color="primary"
                    (click)="resendInvite()"
                  >
                    Resend Invitation Email
                  </button>
                </div>
              </div>
              <mat-form-field fxFlex="100">
                <mat-label>Email Address</mat-label>
                <input matInput placeholder="Email Address" formControlName="email" />
              </mat-form-field>
            </ng-container>
            <ng-container *ngSwitchCase="'device'">
              <h1 mat-card-title>
                <span>{{ user.email }}</span>
              </h1>
            </ng-container>
          </div>
          <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
        </form>
      </div>
      <div
        class="vertical-gap"
        fxLayout="column"
        fxFlex="45"
        fxFlex.lt-md="100"
        fxLayoutAlign="start stretch"
      >
        <ng-container *ngxPermissionsOnly="[permConst.Admin.User.RoleAssignment.Read]">
          <vp-user-assigned-roles></vp-user-assigned-roles>
        </ng-container>
        <ng-container *ngxPermissionsOnly="[permConst.Admin.User.GroupAssignment.Read]">
          <vp-user-assigned-groups></vp-user-assigned-groups>
        </ng-container>
        <ng-container *ngxPermissionsOnly="[permConst.Admin.User.TagsAssignment.Read]">
          <vp-user-assigned-tags></vp-user-assigned-tags>
        </ng-container>
        <ng-container *ngxPermissionsOnly="[permConst.Admin.User.AccessTagsAssignment.Read]">
          <vp-user-assigned-access-tags
            [userType]="user.userType.friendlyId"
          ></vp-user-assigned-access-tags>
        </ng-container>
        <ng-container *ngxPermissionsOnly="[permConst.Admin.User.Snippet.Write]">
          <vp-user-snippets></vp-user-snippets>
        </ng-container>
      </div>
    </div>
    <div class="action-row" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span *ngIf="hasPending === true">Form has pending changes.</span>
        <span *ngIf="form.invalid === true">Form Has Errors</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center">
        <button type="button" mat-flat-button [routerLink]="['../']">Cancel</button>
        <button
          *ngxPermissionsOnly="[
            permConst.Admin.User.Write,
            permConst.Admin.User.Profile.Write,
            permConst.Admin.User.RoleAssignment.Write,
            permConst.Admin.User.GroupAssignment.Write
          ]"
          type="button"
          mat-flat-button
          color="primary"
          [disabled]="form.invalid || !hasPending"
          (click)="save()"
        >
          Save
        </button>
      </div>
    </div>
  </mat-card>
</ng-template>

<ng-template> </ng-template>

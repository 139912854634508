import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { CaseStatusChangedEvent, CaseStatusCount, OrganizationFeatures } from "@vp/models";
import { EventAggregator, IEvent } from "@vp/shared/event-aggregator";
import { FeatureService } from "@vp/shared/features";
import { Subject } from "rxjs";
import { filter, takeUntil, withLatestFrom } from "rxjs/operators";

@Component({
  selector: "vp-case-status-nav-detail",
  templateUrl: "./case-status-nav-detail.component.html",
  styleUrls: ["./case-status-nav-detail.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaseStatusNavDetailComponent implements OnInit, OnDestroy {
  @Input() caseStatusCount: CaseStatusCount | null = null;

  broadcastCaseStatusChangeEnabled$ = this.featureService.featureFlagEnabled(
    OrganizationFeatures.signalR,
    "broadcastCaseStatusChanged"
  );

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly eventAggregator: EventAggregator,
    private readonly featureService: FeatureService
  ) {}

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  ngOnInit(): void {
    this.eventAggregator
      .on<CaseStatusChangedEvent>(CaseStatusChangedEvent)
      .pipe(
        withLatestFrom(
          this.broadcastCaseStatusChangeEnabled$.pipe(
            filter((enabled: boolean) => enabled === true)
          )
        ),
        takeUntil(this._destroyed$)
      )
      .subscribe({
        next: ([event, _enabled]: [IEvent, boolean]) => {
          if (this.caseStatusCount) {
            if (this.caseStatusCount.status === event.args.displayName) {
              this.caseStatusCount.numberCases++;
            } else if (this.caseStatusCount.status === event.args.previousDisplayName) {
              this.caseStatusCount.numberCases--;
            }
          }
        }
      });
  }
}

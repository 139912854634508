import * as moment from "moment";
import { IPredicate } from "./utilities";

/**
 * Scoped property retrieval on an object. Gets the property/object at the location of props specificed by
 * an array of properties representing the "path."
 *
 * For Example:
 *  ["parent","child", "grandchild", "greatgrandchild"] = parent.child.grandchild.greatgrandchild
 *
 * @param obj Object - The target object to insert said properties into. By reference.
 * @param props Array - Array of properties representing the path at which to insert. See above for example.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getValueAtPath = (obj: any, props: string[]): any => {
  const _props = props.slice();
  if (_props.length === 1 && _props[0] === "#") {
    return obj;
  } else if (_props.length > 1 && _props[0] === "#") {
    _props.shift();
  }
  const reduced = _props.reduce((result, prop) => {
    if (prop === "*") {
      return result;
    }

    if (Array.isArray(result)) {
      // array path with index e.g. "[0]"
      const regex = /\[(?<index>\d*)\]/;
      const match = prop.match(regex);
      if (match) {
        const index = match.groups?.index;
        return index ? result[+index] : undefined;
      }
    }

    return result ? result[prop] : undefined;
  }, obj);
  return obj && reduced;
};

/**
 * Scoped property creation on target object. Inserts the objToInsert object at the location of props specificed by
 * an array of properties representing the "path" of the object to insert:
 *
 * For Example, if the following array of properties is passed:
 *  ["parent","child", "grandchild", "greatgrandchild"]
 *
 * Then objToInsert is created at parent.child.grandchild.greatgrandchild in targetObject. This also creates any
 * missing nested objects along the way. So if grandchild is missing in the above example, then it is created,
 * and the greatgrandchild is then created using the objToInsert object.
 *
 * This will deep merge (includes full typed objects) nodes that exist. Applying the objToInsert over the top of
 * existing properties and values.
 *
 * Function takes single parameter containing the below properties to ensure the entire object is passed by reference.
 *
 * @param params Object - A single parameter containing the following parameters.
 *
 *  targetObject Object - The target object to insert said properties into. By reference.
 *  props Array - Array of properties representing the path at which to insert. See above for example.
 *  objecToInsert Object - the object to be inserted at the above path as the last property in the props array
 */
export const insert = (
  params: { targetObject: any; props: string[]; objToInsert: any },
  arrayAction: ArrayAction = "concat"
): void => {
  const _props = params.props.slice();
  // if the path is beyond root (#), then remove the root prop
  if (_props.length > 1 && _props[0] === "#") {
    _props.shift();
  }
  // Otherwise if we only have one prop and that prop is root then just insert the object and return
  else if (_props.length === 1 && _props[0] === "#") {
    params.targetObject = mergeDeep(params.targetObject, params.objToInsert, arrayAction);
    return;
  }
  // otherwise contine processing
  _props.reduce((result, prop, index, array) => {
    if (!result[prop]) {
      if (Array.isArray(result)) {
        // array path with index e.g. "[0]"
        const regex = /\[(?<index>\d*)\]/;
        const match = prop.match(regex);
        if (match) {
          const arrayIndex = match.groups?.index;
          if (arrayIndex) {
            const element = result[+arrayIndex];
            if (index === array.length - 1) {
              result[+arrayIndex] = mergeDeep(element, params.objToInsert, arrayAction);
              return result[+arrayIndex];
            }
            return element;
          }
        }
      }
      result[prop] = {};
    }
    if (index === array.length - 1) {
      result[prop] = mergeDeep(result[prop], params.objToInsert, arrayAction);
    }
    return result[prop];
  }, params.targetObject);
};

export const objectKeyValueFilter = (
  obj: any,
  keyFilter: string,
  valueFilters: string[],
  results: IKeyValueFilterResult[] = [],
  path = ""
) => {
  const accumulator = results;
  const addDelimiter = (a: string, b: string) => (a ? `${a}.${b}` : b);

  if (obj) {
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      const fullPath = addDelimiter(path, key);
      if (key === keyFilter && typeof value !== "object" && valueFilters.includes(value)) {
        const parts = fullPath.split(".");
        parts.pop();
        accumulator.push({
          field: parts[parts.length - 1],
          path: parts.join("."),
          value: value
        } as IKeyValueFilterResult);
      } else if (typeof value === "object") {
        objectKeyValueFilter(value, keyFilter, valueFilters, accumulator, fullPath);
      }
    });
  }
  return accumulator;
};

export interface IKeyValueFilterResult {
  field: string;
  path: string;
  value: string;
}

export const deepCopy = (objectToCopy: any): any => {
  return JSON.parse(JSON.stringify(objectToCopy));
};

export const deeperCopy = (objectToCopy: any): any => {
  let copy;
  if (null === objectToCopy || "object" !== typeof objectToCopy) return objectToCopy;
  if (objectToCopy instanceof moment) {
    return moment(objectToCopy);
  }
  if (objectToCopy instanceof Date) {
    copy = new Date();
    copy.setTime(objectToCopy.getTime());
    return copy;
  }

  if (objectToCopy instanceof Array) {
    copy = [];
    for (let i = 0, len = objectToCopy.length; i < len; i++) {
      copy[i] = deeperCopy(objectToCopy[i]);
    }
    return copy;
  }
  if (objectToCopy instanceof Object) {
    const object: { [key: string]: any } = {};
    for (const attr in objectToCopy) {
      if (Object.prototype.hasOwnProperty.call(objectToCopy, attr)) {
        object[attr] = deeperCopy(objectToCopy[attr]);
      }
    }
    return object;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
};

export const getRecord = <T>(recordData: Record<string, unknown>, property: string) => {
  if (Object.prototype.hasOwnProperty.call(recordData, property)) {
    return recordData[property] as T;
  }
  return;
};

export type ArrayAction = "merge" | "concat" | "replace";

export const mergeDeep = (
  target: any,
  source: any,
  arrayAction: ArrayAction = "concat",
  ignoreMissing = true
) => {
  target = (obj => {
    let cloneObj;
    try {
      cloneObj = JSON.parse(JSON.stringify(obj));
    } catch (err) {
      // If the stringify fails due to circular reference, the merge defaults
      // to a less-safe assignment that may still mutate elements in the target.
      // You can change this part to throw an error for a truly safe deep merge.
      cloneObj = Object.assign({}, obj);
    }
    return cloneObj;
  })(target);

  const isObject = (obj: any) => obj && typeof obj === "object";

  if (!isObject(target) || !isObject(source)) return source;

  Object.keys(source).forEach(key => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue))
      if (arrayAction === "merge") {
        target[key] = targetValue.map((x, i) =>
          sourceValue.length <= i ? x : mergeDeep(x, sourceValue[i], arrayAction, ignoreMissing)
        );
        if (sourceValue.length > targetValue.length)
          target[key] = target[key].concat(sourceValue.slice(targetValue.length));
      } else if (arrayAction === "concat") {
        target[key] = targetValue.concat(sourceValue);
      } else {
        target[key] = sourceValue;
      }
    else if (isObject(targetValue) && isObject(sourceValue))
      target[key] = mergeDeep(
        Object.assign({}, targetValue),
        sourceValue,
        arrayAction,
        ignoreMissing
      );
    else target[key] = sourceValue;
  });

  return target;
};

export const compare = (
  a: number | string | undefined,
  b: number | string | undefined,
  isAsc: boolean
) => {
  if (a && b) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  } else {
    return 0;
  }
};

/**
 * Creates an object map with the key/values reversed for easy reverse lookup
 */
export class TwoWayMap {
  private map: Record<string, string>;
  private reverseMap: Record<string, string>;
  constructor(map: Record<string, string>) {
    this.map = map;
    this.reverseMap = {};
    for (const key in map) {
      const value = map[key];
      this.reverseMap[value] = key;
    }
  }
  get = (key: string) => this.map[key];
  getKey = (key: string) => this.reverseMap[key];
}

/**
 * Check unknown types for a property to used in type guards
 */

export const hasOwnProperty = <X extends Record<never, unknown>, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> => {
  return Object.prototype.hasOwnProperty.call(obj, prop);
};

export const isNullOrUndefined = <T>(obj: T | null | undefined): obj is null | undefined => {
  return typeof obj === "undefined" || obj === null;
};

export const isEmptyObject = (obj: any) =>
  obj && Object.keys(obj).length === 0 && obj.constructor === Object;

export const uniqueArray = (a: any) =>
  [...new Set(a.map((o: any) => JSON.stringify(o)))].map((s: any) => JSON.parse(s));

export const getUtcNow = () => {
  const now = new Date();
  return new Date(now.getTime() + now.getTimezoneOffset() * 60000);
};

export function intersectOn<T>(a: T[], b: T[], predicate: IPredicate): T[] {
  return a.reduce((items: T[], available: T) => {
    const found = b.find((item: T) => predicate(item, available));
    if (found) {
      items.push(found);
    }
    return items;
  }, []);
}

/**
 * Recursively checks that object has at least one value that is not
 * null, empty, or undefined.
 * @param obj {any}
 * @returns {boolean}
 */
export const isNullish = (obj: any): boolean => {
  const nullish = Object.values(obj).every(value => {
    if (value === null || value === "" || value === undefined) {
      return true;
    } else if (typeof value === "object") {
      return isNullish(value);
    }
    return false;
  });
  return nullish;
};

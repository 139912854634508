import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { PhoneInputModule } from "@vp/shared/components/phone-input";
import { UserFormProfileComponent } from "./user-form-profile.component";

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    PhoneInputModule,
    ReactiveFormsModule
  ],
  exports: [UserFormProfileComponent],
  declarations: [UserFormProfileComponent]
})
export class UserFormProfileModule {}

import { Component, ChangeDetectionStrategy, Inject } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { ANGULAR_EDITOR_CONFIG } from "@vp/shared/tokens";

@Component({
  selector: "vp-view-content-page",
  templateUrl: "./view-content-page.component.html",
  styleUrls: ["./view-content-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewContentPageComponent {
  public formGroup: FormGroup;
  public config: AngularEditorConfig;

  constructor(
    fb: FormBuilder,
    @Inject(ANGULAR_EDITOR_CONFIG) public angularEditorConfig: AngularEditorConfig,
    private dialogRef: MatDialogRef<ViewContentPageComponent>
  ) {
    this.formGroup = fb.group({
      htmlContent: new FormControl("", [Validators.required])
    });
    this.config = angularEditorConfig;
  }
  onCancel = () => {
    this.dialogRef.close();
  };
}

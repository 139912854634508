export interface DeviceAdminFilter {
  take: number;
  skip: number;
  sort?: string | undefined;
  sortDirection?: string | null | undefined;
  search?: string | null | undefined;
  filters?: string[] | null | undefined;
  tagIds?: string[] | null | undefined;
  deptId?: string | null | undefined;
}

export const defaultDeviceAdminFilter = (): DeviceAdminFilter => {
  return {
    take: 10,
    skip: 0,
    sort: "profile.firstName",
    sortDirection: "asc",
    filters: ["userType.friendlyId=device"]
  };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { throwError } from "rxjs";

export function formatError(error: any) {
  return throwError(error.message);
}

export class UrlBuilder {
  constructor(public baseUrl: string, public keys: string[] = []) {}

  build(routeParameters: { [key: string]: any }, queryParameters: { [key: string]: any }): string {
    return this.cleanUnused(this.buildFromParams(routeParameters, queryParameters));
  }

  buildUrlWithQueryParameters(queryParams: { [key: string]: any }) {
    return this.build({}, queryParams);
  }

  buildFromParams(
    routeParams: { [key: string]: any },
    queryParams: { [key: string]: any }
  ): string {
    const builtUrl = Object.keys(routeParams).reduce(
      (url, key) =>
        url.replace(new RegExp(`/{${key}}`), routeParams[key] ? `/${routeParams[key]}` : ""),
      this.baseUrl
    );
    const queryString = this.buildQueryString(queryParams);
    if (queryString.length > 0) {
      return `${builtUrl}?${queryString}`;
    }
    return builtUrl;
  }

  buildQueryString(query: { [key: string]: any }) {
    return Object.keys(query)
      .filter(key => query[key] !== undefined && query[key] !== null)
      .map(key => `${key}=${query[key]}`)
      .join("&");
  }

  cleanUnused(s: string): string {
    return this.keys.reduce((url, key) => url.replace(new RegExp(`/{${key}}`), ""), s);
  }
}

export function createUrlBuilder(url: string, keys?: string[]): UrlBuilder {
  return new UrlBuilder(url, keys);
}

import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatDrawer, MatDrawerMode } from "@angular/material/sidenav";
import { ContentFilterStateActionsService } from "@vp/content-management/data-access/content";
import { CommunicationChangedEvent, StatusChangedEvent } from "@vp/models";
import { EventAggregator } from "@vp/shared/event-aggregator";
import { UiDisplayTagService } from "@vp/shared/store/ui";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

export type PageMode = "desktop" | "mobile";

@Component({
  selector: "vp-content-management",
  templateUrl: "./content-management.component.html",
  styleUrls: ["./content-management.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ContentFilterStateActionsService]
})
export class ContentManagementComponent implements OnInit, OnDestroy {
  @ViewChild("drawer") public drawer!: MatDrawer;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  menuMode$: Observable<MatDrawerMode>;
  opened$: Observable<boolean>;
  pageMode$: Observable<PageMode>;
  showToggle$: Observable<boolean>;

  private mobile = [
    Breakpoints.XSmall,
    Breakpoints.Small,
    Breakpoints.TabletPortrait,
    Breakpoints.WebPortrait
  ];

  private desktop = [
    Breakpoints.Medium,
    Breakpoints.Large,
    Breakpoints.XLarge,
    Breakpoints.WebLandscape,
    Breakpoints.TabletLandscape
  ];

  private readonly _destroyed$ = new Subject();
  private readonly _menuMode$ = new BehaviorSubject<MatDrawerMode>("side");
  private readonly _opened$ = new BehaviorSubject<boolean>(true);
  private readonly _pageMode$ = new BehaviorSubject<PageMode>("desktop");
  private readonly _showToggle$ = new BehaviorSubject<boolean>(true);

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly eventAggregator: EventAggregator,
    public readonly uiDisplayTagService: UiDisplayTagService,
    public readonly contentFilterStateActionsService: ContentFilterStateActionsService
  ) {
    this.menuMode$ = this._menuMode$.asObservable();
    this.opened$ = this._opened$.asObservable();
    this.pageMode$ = this._pageMode$.asObservable();
    this.showToggle$ = this._showToggle$.asObservable();
  }
  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  ngOnInit(): void {
    this.subscribeToBreakpoints();
    this.susbcribeToEvents();
    this.contentFilterStateActionsService.listen();
  }

  private subscribeToBreakpoints() {
    this.breakpointObserver
      .observe(this.mobile)
      .pipe(
        filter((state: BreakpointState) => state.matches),
        takeUntil(this._destroyed$)
      )
      .subscribe(_ => {
        this._opened$.next(false);
        this._showToggle$.next(true);
        this._menuMode$.next("over");
        this._pageMode$.next("mobile");
        this._pageMode$.next("mobile");
      });

    this.breakpointObserver
      .observe(this.desktop)
      .pipe(
        filter((state: BreakpointState) => state.matches),
        takeUntil(this._destroyed$)
      )
      .subscribe(_ => {
        this._opened$.next(true);
        this._showToggle$.next(false);
        this._menuMode$.next("side");
        this._pageMode$.next("desktop");
        this._pageMode$.next("desktop");
      });
  }

  private susbcribeToEvents() {
    this.eventAggregator
      .on<CommunicationChangedEvent>(CommunicationChangedEvent)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(_ => {
        if (this.breakpointObserver.isMatched(this.mobile)) {
          this._opened$.next(false);
        }
      });

    this.eventAggregator
      .on<StatusChangedEvent>(StatusChangedEvent)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(_ => {
        if (this.breakpointObserver.isMatched(this.mobile)) {
          this._opened$.next(false);
        }
      });
  }

  toggleSideNav() {
    this._opened$.next(!this._opened$.getValue());
    this.drawer.toggle(this._opened$.getValue());
  }
}

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CaseSummaryService } from "@vp/case-search/data-access/case-summary";
import { CommunicationChangedEvent } from "@vp/models";
import { EventAggregator } from "@vp/shared/event-aggregator";
import { filterNullMap } from "@vp/shared/operators";
import { AppStoreService } from "@vp/shared/store/app";
import { SlideInOutAnimation } from "@vp/shared/utilities";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map, switchMap, takeUntil } from "rxjs/operators";

@Component({
  selector: "vp-communication-counts-nav",
  templateUrl: "./communication-counts-nav.component.html",
  styleUrls: ["./communication-counts-nav.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [SlideInOutAnimation]
})
export class CommunicationCountsNavComponent implements OnInit, OnDestroy {
  private readonly destroyed$ = new Subject<void>();
  private readonly selectedItem$ = new BehaviorSubject<string | null>(null);
  private readonly unreadCommunications$ = new BehaviorSubject<number>(0);
  private readonly unresolvedCommuncations$ = new BehaviorSubject<number>(0);

  public readonly unresolvedCommuncations = 0;

  constructor(
    private readonly caseSummaryService: CaseSummaryService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly appStoreService: AppStoreService,
    private readonly eventAggregator: EventAggregator
  ) {}

  get vmSelectedItem$(): Observable<string> {
    return this.selectedItem$.pipe(filterNullMap());
  }

  get vmUnreadCommunications$(): Observable<number> {
    return this.unreadCommunications$.pipe(filterNullMap());
  }

  get vmUnresolvedCommuncations$(): Observable<number> {
    return this.unresolvedCommuncations$.pipe(filterNullMap());
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.appStoreService.stateChanged
      .pipe(
        switchMap(_ => this.caseSummaryService.getCommunicationSummary()),
        takeUntil(this.destroyed$)
      )
      .subscribe(result => {
        this.unreadCommunications$.next(result.unreadMessageCount);
        this.unresolvedCommuncations$.next(result.unResolvedMessageCount);
      });

    this.activatedRoute.queryParams
      .pipe(
        map(params => {
          return params.communication ?? "not-selected";
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((value: string) => {
        this.selectedItem$.next(value);
      });
  }

  onUnreadClicked() {
    this.eventAggregator.emit(
      new CommunicationChangedEvent("unread"),
      "CommunicationSummaryComponent.onUnreadClicked"
    );
  }

  onUnresolvedClicked() {
    this.eventAggregator.emit(
      new CommunicationChangedEvent("unresolved"),
      "CommunicationSummaryComponent.onUnresolvedClicked"
    );
  }
}

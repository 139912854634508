import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AuthenticationService } from "@vp/shared/authentication";
import { UiDisplayTagService } from "@vp/shared/store/ui";

@Component({
  selector: "vp-content-management-left-menu",
  templateUrl: "./content-management-left-menu.component.html",
  styleUrls: ["./content-management-left-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentManagementLeftMenuComponent {
  @Input() opened = true;
  @Output() sidenavToggle = new EventEmitter<void>();
  constructor(
    public readonly uiDisplayTagService: UiDisplayTagService,
    public readonly authenticationService: AuthenticationService
  ) {}
  onSidenavToggle() {
    this.sidenavToggle.emit();
  }
}

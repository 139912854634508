import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  TrackByFunction
} from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import * as UserStateActions from "@vp/data-access/users";
import { OrganizationFeatures, Role } from "@vp/models";
import { AuthenticationService } from "@vp/shared/authentication";
import { FeatureService } from "@vp/shared/features";
import { IS_IVY_API } from "@vp/shared/guards";
import { NotificationService } from "@vp/shared/notification";
import { filterNullMap } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { SignalRService } from "@vp/shared/signal-r-service";
import { AppStoreService } from "@vp/shared/store/app";
import { UiDisplayTagService } from "@vp/shared/store/ui";
import { RouterUtilities } from "@vp/shared/utilities";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { switchMap, take, takeUntil, tap } from "rxjs/operators";

@Component({
  selector: "vp-top-nav-user",
  templateUrl: "./top-nav-user.component.html",
  styleUrls: ["./top-nav-user.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopNavUserComponent implements OnInit {
  logoRedirectLink: string | undefined;

  signalREnabled$ = this.featureService.featureEnabled$(OrganizationFeatures.signalR);
  @Output() menuSetup: EventEmitter<any> = new EventEmitter();

  public OrganizationFeatures = OrganizationFeatures;

  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);
  private readonly _userDisplayName$ = new BehaviorSubject<string>("loading...");
  private readonly _selectedRoleDisplayName$ = new BehaviorSubject<string>("loading...");

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    @Inject(IS_IVY_API) public readonly isIvyApi: boolean,
    public readonly authenticationService: AuthenticationService,
    public readonly appStoreService: AppStoreService,
    public readonly _PermissionsConstService: PermissionsConstService,
    public readonly permConst: PermissionsConstService,
    public readonly uiDisplayTagService: UiDisplayTagService,
    public readonly signalRService: SignalRService,
    private readonly featureService: FeatureService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly routerUtilitiesService: RouterUtilities,
    private readonly store: Store
  ) {}

  ngOnInit(): void {
    this.appStoreService.userFullName$
      .pipe(filterNullMap(), takeUntil(this._destroyed$))
      .subscribe((userFullName: string): void => {
        if (!userFullName.includes("undefined")) {
          this._userDisplayName$.next(userFullName);
        }
      });

    this.appStoreService.selectedRoleDisplayName$
      .pipe(filterNullMap(), takeUntil(this._destroyed$))
      .subscribe((selectedRoleDisplayName: string): void => {
        this._selectedRoleDisplayName$.next(selectedRoleDisplayName);
      });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  get isLoading$(): Observable<boolean> {
    return this._isLoading$.asObservable();
  }

  get userDisplayName$(): Observable<string> {
    return this._userDisplayName$.asObservable();
  }

  get selectedRoleDisplayName$(): Observable<string> {
    return this._selectedRoleDisplayName$.asObservable();
  }

  onLogout = () => {
    this.authenticationService.logout();
  };

  onLogin = () => {
    this._isLoading$.next(true);
    this.authenticationService.login();
  };

  switchUserRole(roleId: string) {
    this.appStoreService
      .updateUserRole(roleId)
      .pipe(
        switchMap(() => this.appStoreService.selectedRole),
        tap((role: Role) => {
          const { path, queryParams } = this.routerUtilitiesService.getRouteDefaultFromRole(role);
          this.router.navigate([path], { queryParams });
          this.store.dispatch(new UserStateActions.SetCurrentUserRole(role.roleId));
          this.menuSetup.emit();
        }),
        take(1)
      )
      .subscribe({
        next: () => this.notificationService.successMessage("Switched role and app updated"),
        error: () => this.notificationService.errorMessage("Cannot switch role")
      });
  }

  // Satisfies template-use-track-by-function
  trackByFn: TrackByFunction<Role> = (_index: number, item: Role) => item.roleId;
}

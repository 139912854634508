import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Select } from "@ngxs/store";
import { TagsState } from "@vp/data-access/tags";
import { Tag } from "@vp/models";
import { Observable } from "rxjs";
import { filter, map, withLatestFrom } from "rxjs/operators";

@Component({
  selector: "vp-tags-search",
  templateUrl: "./tags-search.component.html",
  styleUrls: ["./tags-search.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagsSearchComponent {
  @Select(TagsState.tags) tags$!: Observable<Tag[]>;

  @Output() tagSelected: EventEmitter<Tag> = new EventEmitter<Tag>();

  errorMsg!: string;
  searchTagsControl = new FormControl();

  get filteredTags$() {
    return this.searchTagsControl.valueChanges.pipe(
      filter(value => typeof value === "string"),
      withLatestFrom(this.tags$),
      map(([value, tags]: [string, Tag[]]) => this._filter(value, tags))
    );
  }

  onSelected() {
    this.tagSelected.emit(this.searchTagsControl.value);
    this.searchTagsControl.reset();
  }

  clearSelection() {
    this.searchTagsControl.reset();
  }

  displayTag(tag: Tag): string {
    return tag && tag.displayName ? tag.displayName : "";
  }

  private _filter(value: string, tags: Tag[]): Tag[] {
    if (value !== undefined) {
      const filterValue = value.toLowerCase();
      return tags.filter(tag => tag.displayName.toLowerCase().includes(filterValue));
    }
    return tags;
  }
}

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "@vp/shared/authentication";
import { AlertItem } from "@vp/shared/components/alert";
import { Logger, LogLevel } from "@vp/shared/logging-service";
import { ToastrService } from "ngx-toastr";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly logger: Logger,
    private readonly toastrService: ToastrService,
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse): Observable<never> => {
        if (err.status === 401 && !this.authenticationService.isLoggingOut) {
          // Response 401: token has expired, Okta failed auth
          this.logger.logHttpException(err, LogLevel.Error, req.url);
          this.router.navigate(["/home"]);
          return throwError(err.message);
        } else if (err.status === 404 || err.status > 499) {
          // Response 404: error is a missing endpoint and treating as fatal
          // Response 500+: log exceptions and show user/developer error
          const message =
            err.error.message != null || err.error.message != undefined
              ? err.error.message
              : "Restart to recover or try again later. The error has been reported.";
          this.logger.logHttpException(err, LogLevel.Error, req.url);
          this.handleUserNotification(message, MessageType.Error);
          this.handleDevloperNotification(err, MessageType.Error);
          return throwError(message);
        } else if (err.status === 403) {
          // 403 access forbidden error
          const message = "That action is not permitted. Please see an administrator for access.";
          this.handleUserNotification(message, MessageType.Error);
          return throwError(err.message);
        } else if (err.status === 409) {
          // 409 Conflict
          const message =
            err.error.message != null || err.error.message != undefined
              ? err.error.message
              : "Conflict. The error has been reported.";
          this.logger.logHttpException(err, LogLevel.Error, req.url);
          this.handleUserNotification(message, MessageType.warningMessage);
          this.handleDevloperNotification(err, MessageType.warningMessage);
          return throwError(message);
        } else if (
          err.status > 399 &&
          err.status < 500 &&
          Object.prototype.hasOwnProperty.call(err.error, "validationMessages")
        ) {
          // Map 400-499 with validation errors to alert object to show to user (in components)
          const validationErrors = this.parseValidationErrors(err.error.validationMessages);
          return throwError(validationErrors);
        } else {
          // other codes like 400-499 without validation errors array
          this.logger.logHttpException(err, LogLevel.Error, req.url);
          this.handleDevloperNotification(err, MessageType.Error);
          return throwError(err.message);
        }
      })
    );
  }

  private parseValidationErrors(validationMessages: any[]): AlertItem[] {
    return validationMessages?.map((validationMessage: any): any => {
      if (typeof validationMessage === "object") {
        return {
          title: validationMessage?.field,
          message: validationMessage?.message,
          type: "toast-warning"
        } as AlertItem;
      }
    });
  }

  private handleUserNotification(message: string, messageType: MessageType): void {
    if (messageType == MessageType.Error) {
      this.toastrService.error(message, "Application error", {
        disableTimeOut: true,
        closeButton: false,
        tapToDismiss: true
      });
    } else if (messageType == MessageType.warningMessage) {
      this.toastrService.warning(message, "Application warning", {
        disableTimeOut: true,
        closeButton: false,
        tapToDismiss: true
      });
    } else if (messageType == MessageType.infoMessage) {
      this.toastrService.info(message, "Application Info", {
        disableTimeOut: true,
        closeButton: false,
        tapToDismiss: true
      });
    }
  }

  private handleDevloperNotification(
    httpErrorResponse: HttpErrorResponse,
    messageType: MessageType
  ): void {
    if (window.location.hostname.search(/(localhost|dev)/) === -1) {
      return;
    }
    const serverError = httpErrorResponse.error;
    let message = `<p>${httpErrorResponse.error.message}</p>`;
    message += `<p><small><b>DEVELOPER DEBUG INFO</b></small></p>`;
    if (serverError && typeof serverError === "object") {
      message += `<p><small>${serverError.ClassName}: ${serverError.Message} ${serverError.StackTraceString}</small></p>`;
    } else {
      message += serverError ?? `No debug info available`;
    }

    if (messageType == MessageType.Error) {
      this.toastrService.error(
        message,
        `Response ${httpErrorResponse.status}: ${httpErrorResponse.statusText}`,
        {
          positionClass: "toast-bottom-full-width",
          enableHtml: true,
          disableTimeOut: true,
          closeButton: true,
          tapToDismiss: false
        }
      );
    } else if (messageType == MessageType.warningMessage) {
      this.toastrService.warning(
        message,
        `Response ${httpErrorResponse.status}: ${httpErrorResponse.statusText}`,
        {
          positionClass: "toast-bottom-full-width",
          enableHtml: true,
          disableTimeOut: true,
          closeButton: true,
          tapToDismiss: false
        }
      );
    } else if (messageType == MessageType.infoMessage) {
      this.toastrService.info(
        message,
        `Response ${httpErrorResponse.status}: ${httpErrorResponse.statusText}`,
        {
          positionClass: "toast-bottom-full-width",
          enableHtml: true,
          disableTimeOut: true,
          closeButton: true,
          tapToDismiss: false
        }
      );
    }
  }
}
enum MessageType {
  Error,
  infoMessage,
  warningMessage
}

import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Tag } from "@vp/models";
import { Observable } from "rxjs";
import { TAG_API_BASE_URL } from "../state+/tags-state";

export type TagApiModel = Omit<
  Tag,
  "lastUpdatedBy" | "lastUpdatedDateTime" | "createdBy" | "createdDateTime"
>;

@Injectable({
  providedIn: "root"
})
export class TagsApiService {
  constructor(@Inject(TAG_API_BASE_URL) private _apiBaseUrl: string, private http: HttpClient) {}

  getTags = (): Observable<Tag[]> => {
    return this.http.get<Tag[]>(`${this._apiBaseUrl}`);
  };

  /*
  /* THERE IS NO API ENDPOINT THAT MATCHES THIS ROUTE
  /*
  getTagById = (tagId: string): Observable<Tag> => {
    return this.http.get<Tag>(`${this._apiBaseUrl}/${tagId}`);
  };
  */

  addTag = (tag: TagApiModel): Observable<Tag> => {
    return this.http.post<Tag>(`${this._apiBaseUrl}/${tag.tagId}`, tag);
  };

  updateTag = (tag: TagApiModel): Observable<Tag> => {
    return this.http.put<Tag>(`${this._apiBaseUrl}/${tag.tagId}`, tag);
  };

  deleteTag = (tagId: string): Observable<Tag> => {
    return this.http.delete<Tag>(`${this._apiBaseUrl}/${tagId}`);
  };
}

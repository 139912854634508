import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { ContentDataFilter, ContentFilterState } from "@vp/content-management/data-access/content";
import { ContentManagementService } from "@vp/content-management/feature";
import { OrganizationState } from "@vp/data-access/organization";
import { Organization, OrganizationContentType } from "@vp/models";
import { SlideInOutAnimation } from "@vp/shared/utilities";
import * as ContentFilterStateActions from "@vp/content-management/data-access/content";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { filterNullMap } from "@vp/shared/operators";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "vp-content-nav-list",
  templateUrl: "./content-nav-list.component.html",
  styleUrls: ["./content-nav-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [SlideInOutAnimation]
})
export class ContentNavListComponent implements OnInit, OnDestroy {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;
  @Select(ContentFilterState.currentFilter) currentFilter$!: Observable<Partial<ContentDataFilter>>;

  selectedContentType$?: Observable<string | null | undefined>;
  private readonly _selectedContentType$ = new BehaviorSubject<string>("");

  contentTypes$ = this.contentManagementService.contentTypes$;

  private readonly _destroyed$ = new Subject();
  constructor(
    private readonly contentManagementService: ContentManagementService,
    private readonly store: Store
  ) {
    this.selectedContentType$ = this._selectedContentType$.asObservable();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
  ngOnInit(): void {
    const currentFilter = this.store.selectSnapshot(ContentFilterState.currentFilter);
    this.store.dispatch(
      new ContentFilterStateActions.SetFilter({
        ...currentFilter,
        contentTypeId: "all"
      })
    );

    this.currentFilter$
      .pipe(filterNullMap(), takeUntil(this._destroyed$))
      .subscribe(
        (result: Partial<ContentDataFilter>) =>
          (this.selectedContentType$ = of(result.contentTypeId))
      );
  }

  isStatusSelected(selectedStatus: string, friendlyId: string) {
    return selectedStatus === friendlyId;
  }

  onContentTypeClicked = (contentType: OrganizationContentType) => {
    const currentFilter = this.store.selectSnapshot(ContentFilterState.currentFilter);
    this.store.dispatch(
      new ContentFilterStateActions.SetFilter({
        ...currentFilter,
        contentTypeId: contentType.friendlyId
      })
    );
    this._selectedContentType$.next(contentType.friendlyId);
  };
}

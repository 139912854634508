import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ContentManagementDashboardComponent } from "./content-management-dashboard.component";
import { BreadcrumbModule } from "@vp/shared/components/breadcrumb";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSelectModule } from "@angular/material/select";
import { PageMenuModule } from "@vp/shared/components/page-menu";
import { TagsSearchModule } from "@vp/shared/components/tags-search";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatIconModule } from "@angular/material/icon";
import { SharedModule } from "@remove-circular-dep/shared/shared.module";
import { MatExpansionModule } from "@angular/material/expansion";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ContentManagementRoutingModule } from "../../../../feature/src/lib/content-management-routing.module";
import { FilterToolbarModule } from "@vp/shared/components/filter-toolbar";
import { MemoizePipeModule } from "@vp/shared/pipes/memoize";

@NgModule({
  imports: [
    CommonModule,
    BreadcrumbModule,
    MatSidenavModule,
    MatSelectModule,
    FilterToolbarModule,
    PageMenuModule,
    TagsSearchModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatIconModule,
    SharedModule,
    MemoizePipeModule,
    MatExpansionModule,
    FlexLayoutModule,
    ContentManagementRoutingModule
  ],
  declarations: [ContentManagementDashboardComponent],
  exports: [ContentManagementDashboardComponent]
})
export class ContentManagementDashboardModule {}

import { UserLite } from "./user";
import { GroupLite } from "./user-group";

export interface CaseData {
  caseId: string; // KEY
  active: boolean;
  assignedDateTime?: Date;
  caseType: CaseTypeCase;
  communicationCounts: CaseCommunicationCount[];
  communications: CommunicationData[];
  createdBy: string;
  createdDateTime: Date;
  createdByUser: CaseUser;
  department: CaseDepartment;
  displayName: string;
  dicomImagesUploads: DicomImagesUpload[];
  dicomStudies: DicomStudy[];
  documents: CaseDocuments;
  groups: GroupLite[];
  images: CaseImages;
  lastUpdatedDateTime: Date;
  lastUpdatedBy: string;
  lastUpdatedByUser: CaseUser;
  lastSubmittedBy: string;
  lastSubmittedDateTime: Date;
  lastSubmittedByUser: CaseUser;
  management: CaseManagementData;
  metaData: Record<string, unknown>;
  paid: boolean;
  payments: CasePayment[];
  recordData: Record<string, unknown>;
  relatedCases: RelatedCase[];
  result: CaseResultDetail;
  responses: CaseResponse[];
  serviceFees?: CaseServiceFee[];
  status: CaseStatus;
  statusHistory: StatusHistory[];
  subjectUserId: string;
  summary: string;
  summaryViewNotes: string;
  tags: string[];
  totalMessages: number;
  unResolvedMessageCount: number;
  unreadMessageCount: number;
  users: CaseUser[];
  virtualCareCaseData: VirtualCareCaseData;
}

export interface VirtualCareCaseData {
  patientMessage: PatientMessageData;
}

export interface PatientMessageData {
  message: string;
  messageSentBy: string;
  messageDateTime: string;
}

export interface CaseTypeCase {
  caseTypeId: string;
  friendlyId: string;
  displayName: string;
  description: string;
}

export interface CaseDepartment {
  departmentId: string;
  friendlyId: string;
}

export interface CaseStatus {
  statusId: string;
  friendlyId: string;
}

export interface CaseUser {
  userId: string;
  userType: string;
  userName: string;
  roleId: string;
  //The following are not stored in the db, but may be returned by the API
  role: string;
  salutation: string;
  firstName: string;
  lastName: string;
  suffix: string;
  email: string;
  primaryPhone: string;
  secondaryPhone: string;
}

export interface CaseRole {
  prettyId: string;
  roleId: string;
  displayName: string;
}

export interface CaseResponse {
  responseId: string;
  resultId: string;
  content: string;
  createdBy: string;
  createdOn: Date;
  lastUpdatedDateTime: Date;
  lastUpdatedBy: string;
  createdByUser: CaseUser;
  lastUpdatedByUser: CaseUser;
  document: string;
}

export interface CommunicationData {
  communicationTypeId: string;
  communicationId: string;
  communicationType: CommunicationType;
  subject: string;
  body: string;
  parentId: string;
  isResolved: boolean;
  attachmentUrl?: string;
  attachmentType?: string;
  viewedBy: string[];
  createdBy: string;
  createdOn: string;
  responses: CommunicationData[];
  createdByUser: CaseUser;
}

export interface CreateCommunication {
  body: string;
  subject: string;
  communicationType: {
    communicationTypeId: string;
    displayName?: string;
  };
  parentId?: string;
  attachmentUrl?: string;
  attachmentType?: string;
}

export interface CommunicationUpdate {
  communicationId: string;
  isResolved: boolean;
  body: string;
  communicationType: { communicationTypeId: string };
}

export interface CaseAudience {
  roles: CaseRole[];
}

export interface CommunicationType {
  audience: CaseAudience;
  communicationTypeId: string;
  displayName: string;
  resolvable: boolean;
  uploadImage: boolean;
  uploadDocument: boolean;
}

export interface CommunicationSummary {
  type: number;
  totalMessages: number;
  unreadMessageCount: number;
  unResolvedMessageCount: number;
}

export interface CaseDocuments {
  documentData: Record<string, unknown>;
  documentList: CaseFile[];
}

export interface CaseImages {
  imageData: Record<string, unknown>;
  imageList: CaseFile[];
}

export interface CaseFile {
  fileName: string;
  displayName: string;
  fileDescription: string;
  url: string;
  isVideo?: boolean;
  uploadedDateTime: string;
  uploadedByUser: string;
}

export interface ICaseDocument {
  fileName: string;
  displayName: string;
  fileDescription: string;
  url: string;
  extension: string;
  icon: string;
  uploadedDateTime: string;
  uploadedByUser: string;
}

export interface CaseFilePdfExtract {
  fileName: string;
  extractFromUrl: string;
  fileDescription: string;
  extractPages: number[];
}

export interface CaseCommunicationCount {
  roleId: string;
  unresolvedCount: number;
  unreadCount: number;
}

export interface StatusHistory {
  previousStatusId: string;
  newStatusId: string;
  updatedBy: string;
  updatedDateTime: string;
  approvals: CaseApprovals[];
}

export interface CaseApprovals {
  roles: string[];
  approved: CaseApprovers[];
  rejected: CaseApprovers[];
}

export interface CaseApprovers {
  userId: string;
  dateTime: string;
  caseUser: CaseUser;
}

export interface CaseManagementData {
  managementData: Record<string, unknown>;
  createdBy: string;
  createdByUser: UserLite;
  createdOn: string;
  lastUpdatedBy: string;
  lastUpdatedByUser: UserLite;
  lastUpdatedDateTime: string;
}

export interface CaseResultDetail {
  results: CaseResultData[];
}

export interface CaseResultData {
  resultId: string;
  resultData: Record<string, unknown>;
  createdBy: string;
  createdOn: string;
  lastUpdatedDateTime: string;
  lastUpdatedBy: string;
  createdByUser: UserLite;
  lastUpdatedByUser: UserLite;
}

export interface RelatedCase {
  caseId: string;
  displayName: string;
  statusName: string;
  createdDateTime: Date;
}

export interface DicomImagesUpload {
  uploadId: string;
  uploadStatus: DicomImagesUploadStatus;
  createdDateTime: string;
  createdBy: string;
  lastUpdatedDateTime: string;
  lastUpdatedBy: string;
}

export enum DicomImagesUploadStatus {
  Failure = -1,
  Created = 0,
  Pending = 1,
  Queued = 2,
  Sent = 3,
  Complete = 4
}

export interface DicomStudy {
  studyId: string;
  studyInstanceUid: string;
  studyDescription: string;
  studyDate: string;
  patientId: string;
  patientName: string;
  name: string;
  commonName: string;
  secureLink: string;
  createdDateTime: string;
  createdBy: string;
  lastUpdatedDateTime: string;
  lastUpdatedBy: string;
}

export interface CasePayment {
  transactionId: string;
  paymentDate: string;
  amount: string;
  notes?: string;
  refunds: CasePaymentRefund[];
}

export interface CasePaymentRefund {
  transactionId: string;
  refundDate: string;
  amount: string;
  notes?: string;
}

export interface CaseServiceFee {
  serviceFeeId: string;
  friendlyId: string;
  fee: number;
  notes?: string;
}

/** @deprecated */
export interface AnswerGroup {
  answerGroupId: string;
  questionGroupId: string;
  friendlyId: string;
  answers: Answer[];
}

/** @deprecated */
export interface Answer {
  questionID: string;
  value: string;
  title: string;
}

import { Group, GroupType, Tag, TagType, User } from "@vp/models";
import { JSONSchema7 } from "json-schema";
import { UserOperations } from "./user-operations.model";

export interface UserAdmininstrationStateModel {
  user: User | null;
  workingCopy: User | null;
  layoutSchema: JSONSchema7 | null;
  pendingOperations: UserOperations | null;
  assignableGroupTypes: GroupType[];
  assignableGroups: Group[];
  assignableTagTypes: TagType[];
  assignableTags: Tag[];
}

export const defaultUserAdministrationState = (): UserAdmininstrationStateModel => {
  return {
    user: null,
    workingCopy: null,
    layoutSchema: null,
    pendingOperations: null,
    assignableGroupTypes: [],
    assignableGroups: [],
    assignableTagTypes: [],
    assignableTags: []
  };
};

import { Injectable, OnDestroy } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import * as CaseTypesActions from "@vp/data-access/case-types";
import { CaseTypesState } from "@vp/data-access/case-types";
import { CaseType, OrganizationFeatures } from "@vp/models";
import { CaseContextService } from "@vp/shared/case-context";
import { FeatureService } from "@vp/shared/features";
import { filterNullMap } from "@vp/shared/operators";
import { Observable, Subject } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";

@Injectable()
export class FeeService implements OnDestroy {
  @Select(CaseTypesState.currentCaseType)
  caseType$!: Observable<CaseType>;

  caseTypeFee$ = this.caseType$.pipe(
    filterNullMap(),
    switchMap(caseType =>
      this.store.dispatch(new CaseTypesActions.GetCaseTypeFee(caseType.caseTypeId))
    ),
    map(() => this.store.selectSnapshot(CaseTypesState.caseTypeFee) ?? 0),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  totalFees$ = this.featureService.featureEnabled$(OrganizationFeatures.paymentsInSubmit).pipe(
    switchMap((featureEnabled: boolean) => {
      if (featureEnabled === true) {
        return this.caseContextService.serviceFeeTotal.pipe(map(total => total.balanceDue));
      }
      return this.caseTypeFee$;
    })
  );

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly caseContextService: CaseContextService,
    private readonly featureService: FeatureService,
    private readonly store: Store
  ) {}

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}

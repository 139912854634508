import { PageState, User } from "@vp/models";
import { DeviceAdminFilter } from "../models/device-admin-filter";
import { DeviceAdminStateModel } from "../models/device-admin-state.model";

export class SetInitialState {
  public static readonly type = "[Device Admin] Set Initial State";
  constructor(public state: DeviceAdminStateModel) {}
}

export class SetFilter {
  public static readonly type = "[Device Admin] Set Filter";
  constructor(public filter: Partial<DeviceAdminFilter>) {}
}

export class GetFiltered {
  public static readonly type = "[Device Admin] Get Filtered";
}

export class SetPageState {
  public static readonly type = "[Device Admin] Set Page State";
  constructor(public pageState: Partial<PageState>) {}
}

export class SetSelectedDevice {
  public static readonly type = "[Device Admin] Set Selected Device";
  constructor(public id: string) {}
}

/*
  Devices may only have one user role
*/
export class RemoveRole {
  public static readonly type = "[Device Admin] Remove Role";
  constructor(public device: User) {}
}

export class UpdateDevice {
  public static readonly type = "[Device Admin] Update Device";
  constructor(public device: User) {}
}

export class UpdateState {
  public static readonly type = "[Device Admin] Update State";
  constructor(public state: DeviceAdminStateModel) {}
}

import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { DynamicLookupPipe, MapFieldPipe, SafePipe } from "./pipes";
import { RouterUtilities, RouterUtilityOptions } from "./router-utilities.service";
import { UTILITY_OPTIONS } from "./tokens";

const defaultConfig = (): RouterUtilityOptions => {
  return {
    routeDefault: "/"
  } as RouterUtilityOptions;
};

@NgModule({
  imports: [CommonModule],
  exports: [MapFieldPipe, DynamicLookupPipe, SafePipe],
  declarations: [MapFieldPipe, DynamicLookupPipe, SafePipe]
})
export class UtilitiesModule {
  static forRoot(
    options: RouterUtilityOptions | Record<string, never> = {}
  ): ModuleWithProviders<UtilitiesModule> {
    return {
      ngModule: UtilitiesModule,
      providers: [
        {
          provide: UTILITY_OPTIONS,
          multi: false,
          useFactory: defaultConfig,
          deps: [RouterUtilities]
        },
        { provide: UTILITY_OPTIONS, multi: false, useValue: options },
        RouterUtilities
      ]
    };
  }

  static forChild(
    options: RouterUtilityOptions | Record<string, never> = {}
  ): ModuleWithProviders<UtilitiesModule> {
    return {
      ngModule: UtilitiesModule,
      providers: [
        {
          provide: UTILITY_OPTIONS,
          multi: false,
          useFactory: defaultConfig,
          deps: [RouterUtilities]
        },
        { provide: UTILITY_OPTIONS, multi: false, useValue: options }
      ]
    };
  }
}

import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "vp-status-indicator",
  templateUrl: "./status-indicator.component.html",
  styleUrls: ["./status-indicator.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusIndicatorComponent {
  @Input() isOnline: boolean | null | undefined = false;
}

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { Sort } from "@angular/material/sort";
import { Select } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import {
  AssignedRolePerDepartment,
  AssignmentModalOptions,
  Column,
  Department,
  Organization,
  Role,
  UserRole
} from "@vp/models";
import { ASSIGNMENT_MODAL_OPTIONS } from "@vp/shared/assignments/models";
import {
  DialogData,
  DialogFactoryService,
  DialogService
} from "@vp/shared/components/generic-dialog";
import { filterNullMap } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { sortBy } from "lodash";
import { NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, combineLatest, Observable, of, Subject } from "rxjs";
import { concatMap, map, takeUntil, withLatestFrom } from "rxjs/operators";
import { UserAdministrationService } from "../user-administration-state/services/user-administration.service";
import { UserAssignRolesComponent } from "../user-assign-roles/user-assign-roles.component";

export interface AssignedRoleViewModel {
  departmentId: string;
  department: string;
  roleId: string;
  role: string;
}

@Component({
  selector: "vp-user-assigned-roles",
  templateUrl: "./user-assigned-roles.component.html",
  styleUrls: ["./user-assigned-roles.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ASSIGNMENT_MODAL_OPTIONS,
      useValue: {
        columns: [
          {
            field: "department",
            header: "Department"
          },
          {
            field: "role",
            header: "Role"
          }
        ],
        title: "Assign Roles",
        config: {
          disableClose: true,
          autoFocus: false,
          closeOnNavigation: true,
          width: "60vw"
        }
      }
    }
  ]
})
export class UserAssignedRolesComponent implements OnInit, OnDestroy {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;

  @ViewChild("modalTemplate", { static: false })
  modalTemplate!: TemplateRef<UserAssignRolesComponent>;

  dialog!: DialogService;

  assignedRoles$!: Observable<AssignedRoleViewModel[]>;
  displayedColumns$!: Observable<Column[]>;

  private readonly _destroyed$ = new Subject();
  private readonly _sort$ = new BehaviorSubject<Sort>({
    direction: "asc",
    active: "department"
  });

  constructor(
    @Inject(ASSIGNMENT_MODAL_OPTIONS) public options: AssignmentModalOptions,
    private readonly dialogFactoryService: DialogFactoryService,
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly userAdministrationService: UserAdministrationService,
    public permConst: PermissionsConstService
  ) {}
  ngOnInit(): void {
    this.assignedRoles$ = this.userAdministrationService.workingCopy$.pipe(
      filterNullMap(),
      map(user => user.roles),
      withLatestFrom(this.organization$),
      map(([userRoles, org]: [UserRole[], Organization]) => {
        const assignedRolesPerDepartment: AssignedRolePerDepartment[] = userRoles.reduce(
          (a: AssignedRolePerDepartment[], role: UserRole) => {
            const w = role.departments.map(d => {
              return {
                departmentId: d.departmentId,
                roleId: role.roleId
              } as AssignedRolePerDepartment;
            });
            a = a.concat(w);
            return a;
          },
          []
        );
        return mapToViewModels(assignedRolesPerDepartment, org);
      }),
      takeUntil(this._destroyed$)
    );

    this.displayedColumns$ = combineLatest([
      this.assignedRoles$,
      this.ngxPermissionsService.hasPermission([this.permConst.Admin.User.RoleAssignment.Delete])
    ]).pipe(
      concatMap(([assignedRoles, hasWritePermissions]: [AssignedRoleViewModel[], boolean]) => {
        const columns: Column[] = [...this.options.columns];
        if (assignedRoles.length > 1 && hasWritePermissions) {
          columns.push({
            field: "actions",
            header: "Delete"
          } as Column);
        }
        return of(columns);
      })
    );
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  assignHandler = () => {
    this.openDialog({
      title: "Assign Roles",
      template: this.modalTemplate
    });
  };

  unassignHandler = (e: AssignedRoleViewModel) => {
    this.userAdministrationService.deleteDepartmentRole$(e.roleId, e.departmentId).subscribe();
  };

  sortHandler = (sort: Sort): void => {
    this._sort$.next(sort);
  };

  private openDialog(dialogData: DialogData): void {
    this.dialog = this.dialogFactoryService.open(dialogData, {
      width: "70vw",
      disableClose: false
    });
    this.dialog.closed$.subscribe();
  }
}

//SORT BY DEPARTMENT DISPLAY NAME AND THEN ROLE DISPLAY NAME
const mapToViewModels = (
  assignedRoles: AssignedRolePerDepartment[],
  org: Organization
): AssignedRoleViewModel[] => {
  const viewModels: AssignedRoleViewModel[] = [];
  assignedRoles.forEach((assignedRole: AssignedRolePerDepartment) => {
    const department: Department | undefined = org.departments.find(
      d => d.departmentId == assignedRole.departmentId
    );
    const role: Role | undefined = org.roles.find(d => d.roleId == assignedRole.roleId);
    viewModels.push({
      departmentId: assignedRole.departmentId,
      department: department?.displayName ?? "[Deparment not found]",
      roleId: assignedRole.roleId,
      role: role?.displayName ?? "[Role not found]"
    });
  });
  return sortBy(viewModels, ["department", "role"]);
};

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { TagType } from "@vp/models";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "vp-tag-type-selector",
  templateUrl: "./tag-type-selector.component.html",
  styleUrls: ["./tag-type-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagTypeSelectorComponent implements OnInit {
  @Input() multiple = false;
  @Input() availableTagTypes!: Observable<TagType[]>;

  @Output() changedEvent = new EventEmitter<TagType[]>();

  formControl = new FormControl();

  private readonly destroyed$ = new Subject<void>();
  private readonly selectedTagTypes$ = new BehaviorSubject<TagType[]>([]);

  ngOnInit(): void {
    this.selectedTagTypes$.pipe(takeUntil(this.destroyed$)).subscribe(selected => {
      this.changedEvent.emit(selected);
    });
  }

  selectionChanged(event: MatSelectChange) {
    if (this.multiple) {
      this.selectedTagTypes$.next([...event.value]);
    } else {
      this.selectedTagTypes$.next([event.value]);
    }
  }
}

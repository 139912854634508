import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { PhoneInputModule } from "@vp/shared/components/phone-input";
import { FeaturesModule } from "@vp/shared/features";
import { NgxMaskModule } from "ngx-mask";
import { NgxPermissionsModule } from "ngx-permissions";
import { EmailVerificationModule } from "../email-verification/email-verification.module";
import { LegalNoticeComponent } from "../legal-notice/legal-notice.component";
import { LegalNoticeModule } from "../legal-notice/legal-notice.module";
import { UserAdmininstrationState } from "../user-administration-state/state+/user-adminstrations.state";
import { UserAssignRolesModule } from "../user-assign-roles/user-assign-roles.module";
import { UserAssignedGroupsModule } from "../user-assigned-groups/user-assigned-groups.module";
import { UserAssignedRolesModule } from "../user-assigned-roles/user-assigned-roles.module";
import { UserSnippetsModule } from "../user-snippets/user-snippets.module";
import { ProfileComponent } from "./profile.component";

@NgModule({
  imports: [
    CommonModule,
    EmailVerificationModule,
    FeaturesModule,
    FlexLayoutModule,
    FormsModule,
    LegalNoticeModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    NgxMaskModule.forRoot({ validation: true }),
    NgxsModule.forFeature([UserAdmininstrationState]),
    NgxPermissionsModule.forChild(),
    PhoneInputModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      { path: "", pathMatch: "full", component: ProfileComponent },
      { path: "legalnotice", pathMatch: "full", component: LegalNoticeComponent }
    ]),
    UserAssignedGroupsModule,
    UserAssignedRolesModule,
    UserAssignRolesModule,
    UserSnippetsModule
  ],
  declarations: [ProfileComponent],
  exports: [ProfileComponent]
})
export class ProfileFeatureModule {}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatChipsModule } from "@angular/material/chips";
import { CaseStatusNavDetailComponent } from "./case-status-nav-detail-component";

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MatChipsModule],
  declarations: [CaseStatusNavDetailComponent],
  exports: [CaseStatusNavDetailComponent]
})
export class CaseStatusNavDetailModule {}

<ng-container
[ngTemplateOutlet]="template"
[ngTemplateOutletContext]="{
contentTypes: (contentTypes$ | async) || [],
selectedContentType: selectedContentType$ | async
}"
>

<ng-template
  #template
  let-contentTypes="contentTypes"
  let-selectedContentType="selectedContentType"
>
  <div class="menu-section">
    <div *ngIf="contentTypes" fxLayout="column" fxLayoutAlign="start stretch">
      <div
      *ngFor="let ct of contentTypes"
      fxFlex
      fxLayout="row"
      fxLayoutAlign="start center"
      class="menu-item notification-badge"
      [class]="ct.friendlyId ? ct.contentColor : null"
      [class.active]="isStatusSelected | memoize: selectedContentType:ct.friendlyId"
      (click)="onContentTypeClicked(ct)">
        <vp-content-nav-list-detail
        [countentTypeCount]="ct">
        </vp-content-nav-list-detail>
      </div>
    </div>
  </div>
</ng-template>
  </ng-container>

import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { Sort } from "@angular/material/sort";
import { Column, DialogueNames, RoleAccessTagsItem, TagsArray, UserRole } from "@vp/models";
import {
  DialogData,
  DialogFactoryService,
  DialogService
} from "@vp/shared/components/generic-dialog";
import { SharedConfirmationService } from "@vp/shared/confirmation";
import { FeatureService } from "@vp/shared/features";
import { NotificationService } from "@vp/shared/notification";
import { filterNullMap } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { RolePipe, TagsArrayPipe } from "@vp/shared/pipes/context-display";
import _ from "lodash";
import { NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, combineLatest, EMPTY, of, Subject } from "rxjs";
import { map, switchMap, take, takeUntil } from "rxjs/operators";
import { UserAdministrationService } from "../user-administration-state/services/user-administration.service";

@Component({
  selector: "vp-user-assigned-access-tags",
  templateUrl: "./user-assigned-access-tags.component.html",
  styleUrls: ["./user-assigned-access-tags.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RolePipe, TagsArrayPipe]
})
export class UserAssignedAccessTagsComponent implements AfterViewInit {
  @Input() userType = "login-user"; //Default userType

  @ViewChild("modalTemplate", { static: false })
  modalTemplate!: TemplateRef<UserAssignedAccessTagsComponent>;

  private readonly destroyed$ = new Subject();
  private readonly _displayedColumns$ = new BehaviorSubject<Column[]>([]);
  private readonly sortSubject$ = new BehaviorSubject<Sort>({
    direction: "asc",
    active: "displayName"
  });

  private mobileUserColumns: Column[] = [
    {
      field: "roleId",
      header: "Role",
      pipe: RolePipe
    },
    {
      field: "tagIds",
      header: "Access Tags",
      pipe: TagsArrayPipe
    }
  ];

  private desktopUserColumns: Column[] = [
    {
      field: "roleId",
      header: "Role",
      pipe: RolePipe
    },
    {
      field: "tagIds",
      header: "Access Tags",
      pipe: TagsArrayPipe
    }
  ];
  dialog!: DialogService;

  show$ = this.featureService
    .featureFlagEnabled("userAdministration", "deviceAccessTagsEnabled")
    .pipe(
      map((featureFlag: boolean) => {
        return this.userType !== "device" || featureFlag;
      })
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private confirmationDialog: SharedConfirmationService,
    private dialogFactoryService: DialogFactoryService,
    private featureService: FeatureService,
    private ngxPermissionsService: NgxPermissionsService,
    private notificationService: NotificationService,
    private userAdministrationService: UserAdministrationService,
    public permConst: PermissionsConstService
  ) {}

  assignedAccessTags$ = this.userAdministrationService.workingCopy$.pipe(
    filterNullMap(),
    map(user => user.roles),
    map((userRoles: UserRole[]) => {
      const items: RoleAccessTagsItem[] = [];
      const sortedRoles: UserRole[] = _.sortBy(userRoles, "friendlyId");
      sortedRoles.forEach((userRole: UserRole) => {
        userRole.accessTags?.forEach((tagsArray: TagsArray) =>
          items.push({ roleId: userRole.roleId, tagIds: tagsArray.tags } as RoleAccessTagsItem)
        );
      });
      return items;
    }),
    takeUntil(this.destroyed$)
  );

  displayedColumns$ = this._displayedColumns$.pipe(
    switchMap(c => {
      return combineLatest([
        of([...c]),
        this.ngxPermissionsService.hasPermission([
          this.permConst.Admin.User.AccessTagsAssignment.Delete
        ])
      ]);
    }),
    map(([columns, hasWritePermissions]: [Column[], boolean]) => {
      if (hasWritePermissions) {
        columns.push({
          field: "actions",
          header: "Delete",
          width: 10
        } as Column);
      }
      return columns;
    })
  );

  ngAfterViewInit(): void {
    this.breakpointObserver
      .observe(["(max-width: 1050px)"])
      .pipe(takeUntil(this.destroyed$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this._displayedColumns$.next(this.mobileUserColumns);
        } else {
          this._displayedColumns$.next(this.desktopUserColumns);
        }
      });
  }

  private confirmRemove(dialogueName: string) {
    return this.confirmationDialog
      .open("You are about to remove access tags", "Remove", "Are you sure?", dialogueName)
      .afterConfirmedOrSkipped();
  }

  assignHandler = () => {
    this.openDialog({
      title: "Assign Access Tags",
      template: this.modalTemplate
    });
  };

  unassignHandler = (item: RoleAccessTagsItem) => {
    this.confirmRemove(DialogueNames.RemoveTagConfirmation)
      .pipe(
        switchMap((confirmed: boolean) => {
          if (confirmed) {
            return this.userAdministrationService.deleteAccessTags(item);
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe({
        error: () => {
          this.notificationService.errorMessage(
            `Failed to remove access tags [${item.tagIds.join(", ")}]`
          );
        }
      });
  };

  tagsSortHandler = (sort: Sort): void => {
    this.sortSubject$.next(sort);
  };

  private openDialog(dialogData: DialogData): void {
    this.dialog = this.dialogFactoryService.open(dialogData, {
      width: "70vw",
      disableClose: false
    });
    this.dialog.closed$.pipe(take(1)).subscribe();
  }
}

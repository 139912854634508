import { UserLite } from "../application";
import { GroupViewModel } from "../ui";

export interface CaseDataChanged {
  caseId: string;
  hub: string;
  hubGroup: GroupViewModel;
  room: string;
  roomGroup: GroupViewModel;
  caseDisplayName: string;
  assignedDevice: UserLite;
  zoomCallStatus: ZoomCallStatus;
  callLightStatus: CallLightStatus;
}

export enum CallLightStatus {
  On = 1,
  Off = 2,
  Unassigned = 3,
  Unknown = 99
}

export enum ZoomCallStatus {
  Available = 1,
  InCall = 2,
  Unassigned = 3,
  Unknown = 99
}

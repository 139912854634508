/**
 * These are the Case permissions.
 */
/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
export const casePermissions = {
  Read: "caseCanRead",
  Write: "caseCanWrite",
  Delete: "caseCanDelete",
  Content: {
    Read: "adminUserContentCanRead",
    Write: "adminUserContentCanWrite",
    Delete: "adminUserContentCanDelete"
  },
  Review: {
    Read: "caseReviewCanRead",
    Write: "caseReviewCanWrite",
    Delete: "caseReviewCanDelete"
  },
  Notes: {
    Read: "caseNotesCanRead",
    Write: "caseNotesCanWrite"
  },
  Answers: {
    Read: "caseAnswersCanRead",
    Write: "caseAnswersCanWrite",
    Delete: "caseAnswersCanDelete"
  },
  Image: {
    Read: "caseImageCanRead",
    Write: "caseImageCanWrite",
    Delete: "caseImageCanDelete"
  },
  Document: {
    Read: "caseDocumentCanRead",
    Write: "caseDocumentCanWrite",
    Delete: "caseDocumentCanDelete"
  },
  Download: {
    Read: "caseDownloadCanRead"
  },
  Extract: {
    Read: "caseExtractCanRead"
  },
  Submit: {
    Read: "caseSubmitCanRead",
    Write: "caseSubmitCanWrite",
    Delete: "caseSubmitCanDelete"
  },
  Assign: {
    User: {
      Read: "caseAssignUserCanRead",
      Write: "caseAssignUserCanWrite",
      Delete: "caseAssignUserCanDelete"
    },
    Device: {
      Read: "caseAssignDeviceCanRead",
      Write: "caseAssignDeviceCanWrite",
      Delete: "caseAssignDeviceCanDelete"
    },
    Group: {
      Read: "caseAssignGroupCanRead",
      Write: "caseAssignGroupCanWrite",
      Delete: "caseAssignGroupCanDelete"
    },
    Tag: {
      Read: "caseAssignTagCanRead",
      Write: "caseAssignTagCanWrite",
      Delete: "caseAssignTagCanDelete"
    }
  },
  Communication: {
    Read: "caseCommunicationCanRead",
    Write: "caseCommunicationCanWrite",
    Delete: "caseCommunicationCanDelete"
  },
  Result: {
    Read: "caseResultCanRead",
    Write: "caseResultCanWrite",
    Delete: "caseResultCanDelete"
  },
  MyResult: {
    Read: "caseResultMineCanRead",
    Write: "caseResultMineCanWrite",
    Delete: "caseResultMineCanDelete"
  },
  Response: {
    Read: "caseResponseCanRead",
    Write: "caseResponseCanWrite",
    Delete: "caseResponseCanDelete"
  },
  ResponseEdit: {
    Write: "caseResponseEditCanWrite"
  },
  Related: {
    Read: "caseRelatedCanRead",
    Write: "caseRelatedCanWrite",
    Delete: "caseRelatedCanDelete"
  },
  Status: {
    Read: "caseStatusCanRead",
    Write: "caseStatusCanWrite",
    Delete: "caseStatusCanDelete"
  },
  Approve: {
    Read: "caseApproveCanRead",
    Write: "caseApproveCanWrite",
    Delete: "caseApproveCanDelete"
  },
  Conference: {
    Read: "caseConferenceCanRead",
    Write: "caseConferenceCanWrite",
    Delete: "caseConferenceCanDelete"
  },
  Chat: {
    Read: "caseChatCanRead",
    Write: "caseChatCanWrite",
    Delete: "caseChatCanDelete"
  },
  Push: {
    Read: "casePushCanRead",
    Write: "casePushCanWrite",
    Delete: "casePushCanDelete"
  },
  Servicefee: {
    Read: "caseServicefeeCanRead",
    Write: "caseServicefeeCanWrite",
    Delete: "caseServicefeeCanDelete"
  },
  Refund: {
    Write: "caseRefundCanWrite"
  },
  Payment: {
    Write: "casePaymentCanWrite"
  }
};

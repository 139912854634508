import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { ContentData, ContentSearch, PageResult, PageState } from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import { deeperCopy, parseError } from "@vp/shared/utilities";
import { createPatch, Operation } from "rfc6902";
import { combineLatest, Observable, of, throwError } from "rxjs";
import { catchError, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { ContentApiService } from "../api/content-api-service";
import { ContentDataFilter } from "../models/content-data-filter";
import { ContentOperations } from "../models/content-operations.model";
import * as ContentFilterStateActions from "./content-filter-state.actions";

export type ContentFilterStateModel = {
  contentData: ContentData | null;
  workingCopy: ContentData | null;
  filter: Partial<ContentDataFilter>;
  pendingOperations: ContentOperations | null;
  pageState: PageState;
  results: ContentSearch[];
  errors: string[];
};

export const defaultContentState = (): ContentFilterStateModel => {
  return {
    filter: {
      take: 10,
      skip: 0,
      contentTypeId: "all",
      contentId: null
    },
    pageState: {
      totalRecords: 0,
      pageIndex: 0,
      pageCount: 0,
      pageSize: 10,
      lastPage: 1
    },
    results: [],
    errors: [],
    workingCopy: null,
    contentData: null,
    pendingOperations: null
  };
};

@State<ContentFilterStateModel>({
  name: "contentFilter",
  defaults: defaultContentState()
})
@Injectable()
export class ContentFilterState {
  constructor(private contentApiService: ContentApiService) {}

  @Selector()
  static workingCopy(state: ContentFilterStateModel): ContentData | null {
    return state.workingCopy;
  }

  @Selector()
  static contentData(state: ContentFilterStateModel): ContentData | null {
    return state.contentData;
  }

  @Selector()
  static pendingOperations(state: ContentFilterStateModel): ContentOperations | null {
    return state.pendingOperations;
  }

  @Selector()
  public static results(state: ContentFilterStateModel): ContentSearch[] {
    return state.results;
  }

  @Selector()
  static currentFilter(state: ContentFilterStateModel): Partial<ContentDataFilter> {
    return state.filter;
  }

  @Selector()
  static pageState(state: ContentFilterStateModel): PageState {
    return state.pageState;
  }

  @Action(ContentFilterStateActions.SetFilter)
  setFilter(
    ctx: StateContext<ContentFilterStateModel>,
    { filter: contentDataFilter }: ContentFilterStateActions.SetFilter
  ) {
    const currentState = ctx.getState();
    const updatedState = {
      ...currentState,
      filter: contentDataFilter
    };
    return this.contentApiService.filteredContents(updatedState.filter).pipe(
      tap((pageResults: PageResult<ContentSearch>) => {
        ctx.patchState({
          ...updatedState,
          results: pageResults.results,
          pageState: {
            pageIndex: currentState.pageState.pageIndex,
            pageSize: pageResults.pageSize,
            totalRecords: pageResults.totalRecords,
            pageCount: pageResults.pageCount,
            lastPage: pageResults.lastPage
          }
        });
      })
    );
  }

  @Action(ContentFilterStateActions.UpdateState)
  updateState(
    ctx: StateContext<ContentFilterStateModel>,
    { state: state }: ContentFilterStateActions.UpdateState
  ) {
    return this.contentApiService.filteredContents(state.filter).pipe(
      tap((pageResults: PageResult<ContentSearch>) => {
        ctx.setState({
          ...state,
          results: pageResults.results,
          pageState: {
            pageIndex: state.pageState.pageIndex,
            pageSize: pageResults.pageSize,
            totalRecords: pageResults.totalRecords,
            pageCount: pageResults.pageCount,
            lastPage: pageResults.lastPage
          }
        });
      })
    );
  }

  @Action(ContentFilterStateActions.LoadContentData)
  loadContent(
    ctx: StateContext<ContentFilterStateModel>,
    action: ContentFilterStateActions.LoadContentData
  ) {
    return this.updateState$(action.contentData, ctx);
  }

  private updateState$ = (
    partialContentData: Partial<ContentData>,
    ctx: StateContext<ContentFilterStateModel>
  ) => {
    const state = ctx.getState();
    const contentData: ContentData = deeperCopy(partialContentData);
    const updatedState: ContentFilterStateModel = {
      ...state,
      workingCopy: contentData,
      contentData: contentData
    };
    return ctx.patchState(updatedState);
  };

  @Action(ContentFilterStateActions.SetWorkingCopy)
  setWorkingCopy(
    ctx: StateContext<ContentFilterStateModel>,
    workingCopy: ContentFilterStateActions.SetWorkingCopy
  ) {
    const state = ctx.getState();
    const updatedState: ContentFilterStateModel = {
      ...state,
      workingCopy: workingCopy.contentData
    };
    return ctx.patchState(updatedState);
  }

  @Action(ContentFilterStateActions.SetContentData)
  setContentData(
    ctx: StateContext<ContentFilterStateModel>,
    contentData: ContentFilterStateActions.SetContentData
  ) {
    const state = ctx.getState();
    const updatedState: ContentFilterStateModel = {
      ...state,
      contentData: contentData.contentData
    };
    return ctx.patchState(updatedState);
  }

  @Action(ContentFilterStateActions.SetPageState)
  setPageState(
    ctx: StateContext<ContentFilterStateModel>,
    { pageState: pageState }: ContentFilterStateActions.SetPageState
  ) {
    const currentState = ctx.getState();
    const pageSize = pageState?.pageSize ?? 0;
    const pageIndex = pageState?.pageIndex ?? 0;
    const updatedState = {
      ...currentState,
      pageState: pageState,
      filter: {
        ...currentState.filter,
        take: pageSize,
        skip: pageSize * pageIndex
      }
    };
    return this.contentApiService.filteredContents(updatedState.filter).pipe(
      tap((pageResults: PageResult<ContentSearch>) => {
        ctx.patchState({
          ...updatedState,
          results: pageResults.results,
          pageState: {
            pageIndex: pageIndex,
            pageSize: pageSize,
            totalRecords: pageResults.totalRecords,
            pageCount: pageResults.pageCount,
            lastPage: pageResults.lastPage
          }
        });
      })
    );
  }

  @Action(ContentFilterStateActions.GetFiltered)
  getFiltered(ctx: StateContext<ContentFilterStateModel>): Observable<PageResult<ContentSearch>> {
    const state: ContentFilterStateModel = ctx.getState();
    return this.contentApiService.filteredContents(state.filter).pipe(
      tap((pageResults: PageResult<ContentSearch>) => {
        ctx.patchState({
          results: pageResults.results,
          pageState: {
            pageIndex: state.pageState.pageIndex,
            totalRecords: pageResults.totalRecords,
            pageCount: pageResults.pageCount,
            lastPage: pageResults.lastPage,
            pageSize: pageResults.pageSize
          }
        });
      })
    );
  }

  /**
   * Patch passed state to server, and update state with response.
   * @param ctx
   * @param { contentData }
   * @returns {Observable<contentData>}
   */
  @Action(ContentFilterStateActions.Patch)
  patch(
    ctx: StateContext<ContentFilterStateModel>,
    { contentData }: ContentFilterStateActions.Patch
  ) {
    return of(contentData).pipe(
      filterNullMap(),
      switchMap((changed: ContentData) =>
        combineLatest([of(ctx.getState().contentData), of(changed)])
      ),
      map(([original, changed]: [ContentData | null, ContentData]) => {
        return {
          contentId: changed.contentId,
          operations: createPatch(original, changed)
        };
      }),
      switchMap((contentOperations: { contentId: string; operations: Operation[] }) =>
        this.contentApiService
          .patch(contentOperations.contentId, contentOperations.operations)
          .pipe(map(() => contentOperations.contentId))
      ),
      mergeMap(contentId => this.contentApiService.getContent(contentId)),
      tap((contentData: ContentData) => {
        ctx.patchState({ contentData });
      }),
      catchError(error => {
        ctx.patchState({ errors: parseError(error) });
        return throwError(error);
      })
    );
  }

  @Action(ContentFilterStateActions.SetPendingOperations)
  setPendingOperations(
    ctx: StateContext<ContentFilterStateModel>,
    action: ContentFilterStateActions.SetPendingOperations
  ) {
    const state = ctx.getState();
    state.pendingOperations = action.contentOperations;
    ctx.patchState(state);
  }
}

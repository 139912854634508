import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from "@angular/router";
import { User } from "@vp/models";
import { FeatureService } from "@vp/shared/features";
import { filterNullMap } from "@vp/shared/operators";
import { AppStoreService } from "@vp/shared/store/app";
import { Observable, of, Subject } from "rxjs";
import { map, mergeMap, take } from "rxjs/operators";

@Injectable()
export class ProfileGuard implements CanActivate, CanActivateChild {
  private delay = new Subject();

  constructor(
    private appStoreService: AppStoreService,
    private _router: Router,
    private featureService: FeatureService
  ) {
    appStoreService.userStream.pipe(filterNullMap()).subscribe({
      next: () => this.delay.next()
    });
  }

  canActivateChild(
    _childRoute: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.resolveProfileState();
  }

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.resolveProfileState();
  }

  canLoad(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.resolveProfileState();
  }

  private resolveProfileState() {
    return this.appStoreService.userStream.pipe(
      filterNullMap(),
      mergeMap((user: User) => {
        let redirectPath = "";

        if (
          /**
           * The legal notice will only show if the feature is enabled, and if a user has the
           * legal notice requested flag set to true on their profile
           */
          !Object.prototype.hasOwnProperty.call(
            user.userData?.flags ?? {},
            "legalNoticeRequested"
          ) ||
          user.userData?.flags.legalNoticeRequested === true
        ) {
          this.featureService
            .featureFlagEnabled("common", "profileLegalNoticeEnabled")
            .pipe(
              map((enabled: boolean) => {
                if (enabled) {
                  redirectPath = "/profile/legalnotice";
                }
              })
            )
            .subscribe();
        }

        if (!user.tags?.includes("profile.complete")) {
          this.featureService
            .featureFlagEnabled("common", "disableProfileCompleteDialog")
            .pipe(
              map((enabled: boolean) => {
                if (!enabled) {
                  redirectPath = "/profile";
                }
              })
            )
            .subscribe();
        }

        if (redirectPath) return of(this._router.createUrlTree([redirectPath]));

        return of(true);
      }),
      take(1)
    );
  }
}

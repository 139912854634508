import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { TagsState } from "@vp/data-access/tags";
import { FormStateProvider } from "@vp/formly/ui-schema-config";

@Injectable()
export class TagsFormStateProviderService implements FormStateProvider {
  constructor(private store: Store) {}

  provide(state: Record<string, unknown>): Record<string, unknown> {
    if (Object.keys(state).length === 0) return {};

    const stateTags = state.tags as Record<string, unknown>;
    const allTags = this.store.selectSnapshot(TagsState.tags);

    const formState = Object.entries(stateTags).reduce(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (accumulator: Record<string, unknown>, [key, value]: [string, any]) => {
        const tags = allTags.filter(tag => tag.tagTypeFriendlyId === value?.tagTypeFriendlyId);
        accumulator[key] = [...tags];
        return accumulator;
      },
      { ...stateTags }
    );

    return formState;
  }
}

<h1 mat-dialog-title>
  <span>{{ action + " Snippet" }}</span>
</h1>
<mat-dialog-content>
  <form #form="ngForm" fxLayout="column" fxLayoutAlign="space-around" fxLayoutGap="10px">
    <mat-form-field>
      <mat-label>Title</mat-label>
      <input title="Title" matInput [(ngModel)]="snippet.title" required name="title" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Text</mat-label>
      <textarea
        title="Snipper Text"
        cdkTextareaAutosize
        cdkAutosizeMinRows="4"
        matInput
        [(ngModel)]="snippet.text"
        required
        name="text"
      ></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button type="button" mat-flat-button mat-dialog-close>Cancel</button>
  <button
    type="button"
    mat-raised-button
    color="primary"
    [mat-dialog-close]="snippet"
    [disabled]="!form.valid"
  >
    Save
  </button>
</mat-dialog-actions>

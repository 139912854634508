import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { ContentFilterState } from "./content-search-state/state+/content-filter.state";

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([ContentFilterState]), RouterModule]
})
export class ContentModule {}

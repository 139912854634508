import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { Select, Store } from "@ngxs/store";
import * as ContentFilterStateActions from "@vp/content-management/data-access/content";
import { ContentManagementService } from "@vp/content-management/feature";
import { ViewContentPageComponent } from "@vp/content-management/ui/view-content-page";
import * as ContentsActions from "@vp/data-access/contents";
import { ContentDataFilter, ContentFilterState } from "@vp/data-access/contents";
import { ContentSearch, OrganizationContentType, PageState } from "@vp/models";
import { ConfirmationDialogComponent } from "@vp/shared/components/confirmation-dialog";
import { FilterToolbarItem } from "@vp/shared/components/filter-toolbar";
import { NotificationService } from "@vp/shared/notification";
import { UiDisplayTagService } from "@vp/shared/store/ui";
import { Observable, of, Subject } from "rxjs";
import { takeUntil, map } from "rxjs/operators";

@Component({
  selector: "vp-content-management-dashboard",
  templateUrl: "./content-management-dashboard.component.html",
  styleUrls: ["./content-management-dashboard.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentManagementDashboardComponent implements OnDestroy, OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns = ["contentName", "tags", "contentType", "actions"];
  @Select(ContentFilterState.results) results$!: Observable<ContentSearch[]>;
  @Select(ContentFilterState.currentFilter) currentFilter$!: Observable<Partial<ContentDataFilter>>;
  @Select(ContentFilterState.pageState) pageState$!: Observable<PageState>;

  contentTypes$ = this.contentManagementService.contentTypes$;
  pageParams$? = this.contentManagementService.pageParams;
  private readonly _destroyed$ = new Subject<void>();
  hasResult$ = this.results$.pipe(
    map(results => {
      return results.length > 0;
    })
  );
  constructor(
    public readonly uiDisplayTagService: UiDisplayTagService,
    private readonly notificationService: NotificationService,
    private dialog: MatDialog,
    private readonly contentManagementService: ContentManagementService,
    private readonly store: Store
  ) {}

  ngOnInit(): void {
    this.filterClearedHandler();
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  onDeleteClick(content: ContentSearch): void {
    const warnMessage = `Are you sure you want to delete "${content.displayName}" and un-associate it from any existing requests?`;
    this.confirmDelete(() => {
      this.store.dispatch(new ContentsActions.DeleteContent(content.contentId)).subscribe(data => {
        if (data) {
          this.notificationService.successMessage("Content deleted successfully");
        } else {
          this.notificationService.errorMessage("Content failed to delete");
        }
      });
    }, warnMessage);
  }

  private confirmDelete(success: () => void, warnMessage: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: warnMessage
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        success();
      }
    });
  }

  public viewResponse = (contentId: string) => {
    this.openEditResponseDialog(contentId, false).subscribe();
  };

  private openEditResponseDialog = (
    contentId: string,
    editMode: boolean = false
  ): Observable<ContentSearch | null> => {
    if (contentId) {
      const dialogRef = this.dialog.open(ViewContentPageComponent, {
        width: "80vw",
        data: {
          contentId: contentId,
          editMode
        },
        panelClass: "mobile-responsive"
      });
      return dialogRef.afterClosed().pipe(takeUntil(this._destroyed$));
    }
    return of(null);
  };

  handlePageEvent(pageEvent: PageEvent): void {
    const takeSize = pageEvent.pageSize;
    const skipSize = pageEvent.pageIndex * pageEvent.pageSize;
    const currentFilter = this.store.selectSnapshot(ContentFilterState.currentFilter);
    this.store.dispatch(
      new ContentFilterStateActions.SetFilter({
        ...currentFilter,
        take: takeSize,
        skip: skipSize
      })
    );
  }

  filterClearedHandler(): void {
    this.resetPager();
    const currentFilter = this.store.selectSnapshot(ContentFilterState.currentFilter);
    this.store.dispatch(
      new ContentFilterStateActions.SetFilter({
        ...currentFilter,
        contentTypeId: "all",
        search: ""
      })
    );
  }

  private resetPager(): void {
    if (this.paginator) {
      this.paginator.firstPage();
    }
  }

  filterItemRemovedHandler(item: FilterToolbarItem): void {
    const currentFilter = this.store.selectSnapshot(ContentFilterState.currentFilter);
    switch (item.value) {
      case FILTER_TYPE_VALUE.search:
        this.store.dispatch(
          new ContentFilterStateActions.SetFilter({
            ...currentFilter,
            search: null
          })
        );
        break;
      case FILTER_TYPE_VALUE.contentType:
        this.store.dispatch(
          new ContentFilterStateActions.SetFilter({
            ...currentFilter,
            contentTypeId: "all"
          })
        );
        this.resetPager();
        break;
    }
  }

  getFilteredToolbarItems = (
    search: string,
    contentTypeId: string,
    contentType: OrganizationContentType[]
  ): FilterToolbarItem[] => {
    const list: FilterToolbarItem[] = [];
    if (search) {
      list.push({
        value: FILTER_TYPE_VALUE.search,
        label: `${FILTER_TYPE_LABEL.search}: "${search}"`
      });
    }
    const contentTypeName =
      contentType.find(ct => ct.friendlyId === contentTypeId)?.displayName ??
      "[contentType not found]";
    if (contentTypeId !== "all") {
      list.push({
        value: FILTER_TYPE_VALUE.contentType,
        label: `${this.uiDisplayTagService.displayTags.opinionTypeFilter}: ${contentTypeName}`
      });
    }
    return list;
  };
}
export const FILTER_TYPE_VALUE = {
  search: "search",
  contentType: "contentType"
};
export const FILTER_TYPE_LABEL = {
  search: "Search",
  contentType: "Content Type"
};

import { CommonModule } from "@angular/common";
import { NgModule, TemplateRef } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { ButtonComponent } from "./dialog-button.component";
import { DialogComponent } from "./generic-dialog.component";

@NgModule({
  imports: [CommonModule, MatButtonModule, MatDialogModule],
  exports: [DialogComponent, ButtonComponent],
  declarations: [DialogComponent, ButtonComponent],
  entryComponents: [DialogComponent]
})
export class GenericDialogModule {}

export interface DialogData<T = undefined> {
  title: string;
  template: TemplateRef<unknown>;
  context?: T;
}

export interface DialogOptions {
  width: string;
  disableClose: boolean;
}

import { Injectable, InjectionToken } from "@angular/core";
import { Action, NgxsAfterBootstrap, Selector, State, StateContext } from "@ngxs/store";
import { Tag } from "@vp/models";
import { AuthenticationService } from "@vp/shared/authentication";
import { take, tap } from "rxjs/operators";
import { TagsApiService } from "../api/tags-api.service";
import * as TagsActions from "./tags-actions";

export const TAG_API_BASE_URL = new InjectionToken<string>("API_BASE_URL");

export interface TagsStateModel {
  tags: Tag[];
  selectedId: string | null;
}

@State<TagsStateModel>({
  name: "tags",
  defaults: { tags: [], selectedId: null }
})
@Injectable()
export class TagsState implements NgxsAfterBootstrap {
  constructor(
    private readonly _tagsApiService: TagsApiService,
    private authenticationService: AuthenticationService
  ) {}

  @Selector()
  public static tags(state: TagsStateModel) {
    return state.tags;
  }

  @Selector()
  public static selectedTagId(state: TagsStateModel) {
    return state.selectedId;
  }

  ngxsAfterBootstrap(ctx: StateContext<TagsStateModel>) {
    this.authenticationService
      .isLoggedIn$()
      .pipe(take(1))
      .subscribe(isAuthenticated => {
        if (isAuthenticated) {
          ctx.dispatch(TagsActions.LoadTags);
        }
      });
  }

  @Action(TagsActions.CreateTag)
  create(ctx: StateContext<TagsStateModel>, { tag }: TagsActions.CreateTag) {
    return this._tagsApiService.addTag(tag).pipe(
      tap((tag: Tag) => {
        ctx.patchState({ tags: [...ctx.getState().tags, tag] });
      })
    );
  }

  @Action(TagsActions.LoadTags)
  load(ctx: StateContext<TagsStateModel>) {
    return this._tagsApiService.getTags().pipe(
      tap((tags: Tag[]) => {
        ctx.patchState({ tags: tags });
      })
    );
  }

  @Action(TagsActions.SetSelectedTagId)
  setSelectedId(ctx: StateContext<TagsStateModel>, { tagId }: TagsActions.SetSelectedTagId) {
    ctx.patchState({ selectedId: tagId });
  }

  @Action(TagsActions.UpdateTag)
  update(ctx: StateContext<TagsStateModel>, { tag }: TagsActions.UpdateTag) {
    return this._tagsApiService.updateTag(tag).pipe(
      tap(() => {
        ctx.patchState({
          tags: [...ctx.getState().tags.filter(t => t.tagId !== tag.tagId), tag]
        });
      })
    );
  }

  @Action(TagsActions.DeleteTag)
  delete(ctx: StateContext<TagsStateModel>, { tagId }: TagsActions.DeleteTag) {
    return this._tagsApiService.deleteTag(tagId).pipe(
      tap(() => {
        ctx.patchState({ tags: [...ctx.getState().tags.filter(t => t.tagId !== tagId)] });
      })
    );
  }
}

export enum OrganizationFeatures {
  accessTags = "accessTags",
  allRolesAssignable = "allRolesAssignable",
  analytics = "analytics",
  autoConfirmDialogues = "autoConfirmDialogues",
  cannotUnassignSelf = "cannotUnassignSelf",
  caseDashboardAsSinglePage = "caseDashboardAsSinglePage",
  common = "common",
  directConnect = "directConnect",
  feeBasedServices = "feeBasedServices",
  multiOpinionResponse = "multiOpinionResponse",
  paymentsInSubmit = "paymentsInSubmit",
  signalR = "signalR",
  smsOptIn = "smsOptIn",
  toastrPositionClass = "toastrPositionClass",
  virtualCare = "virtualCare"
}

import { Pipe, PipeTransform } from "@angular/core";
import { UserApiService } from "@vp/data-access/users";
import { User } from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Pipe({
  name: "userName"
})
export class UserNamePipe implements PipeTransform {
  constructor(private userApiService: UserApiService) {}

  transform(userId: string): Observable<string> {
    return this.userApiService.getUser(userId).pipe(
      filterNullMap(),
      map((user: User) => {
        if (!user.profile.firstName && !user.profile.lastName) {
          return user.email;
        }
        return `${user.profile.firstName} ${user.profile.lastName}`;
      })
    );
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TrackByFunction
} from "@angular/core";
import { Select } from "@ngxs/store";
import { AssignmentModalOptions, Column, Tag, TagType, User } from "@vp/models";
import { ASSIGNMENT_MODAL_OPTIONS } from "@vp/shared/assignments/models";
import { filterNullMap } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { TagTypePathPipe, TagWithParentsPipe } from "@vp/shared/pipes/context-display";
import { deeperCopy } from "@vp/shared/utilities";
import { sortBy } from "lodash";
import { NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, combineLatest, Observable, of, Subject } from "rxjs";
import { concatMap, map, switchMap, take, takeUntil, withLatestFrom } from "rxjs/operators";
import { getTagsToRemove, handleSelectedTagEvent } from "../shared-functions";
import { UserAdministrationService } from "../user-administration-state/services/user-administration.service";
import { UserAdmininstrationState } from "../user-administration-state/state+/user-adminstrations.state";

@Component({
  selector: "vp-user-assign-tags",
  templateUrl: "./user-assign-tags.component.html",
  styleUrls: ["./user-assign-tags.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ASSIGNMENT_MODAL_OPTIONS,
      useValue: {
        columns: [
          {
            field: "tagTypeFriendlyId",
            header: "Tag Type",
            pipe: TagTypePathPipe
          },
          {
            field: "tagId",
            header: "Display Name",
            pipe: TagWithParentsPipe
          },
          {
            field: "description",
            header: "Description"
          }
        ],
        title: "Assign Tags",
        config: {
          disableClose: true,
          autoFocus: false,
          closeOnNavigation: true,
          width: "60vw"
        }
      }
    }
  ]
})
export class UserAssignTagsComponent implements OnInit, OnDestroy {
  @Select(UserAdmininstrationState.assignableTagTypes)
  assignableTagTypes$!: Observable<TagType[]>;
  @Select(UserAdmininstrationState.assignableTags)
  assignableTags$!: Observable<Tag[]>;

  displayedColumns$: Observable<Column[]>;
  excludeProperties: string[] = [];
  tagTypesFilter$: Observable<string[] | null>;
  hasSelected$: Observable<boolean>;
  searchTerm$: Observable<string | null>;
  selectedCount$: Observable<number>;
  selectedTags$: Observable<Tag[]>;
  tags$: Observable<Tag[]>;
  title = "Assign Tags";

  private readonly _displayedColumns$ = new BehaviorSubject<Column[]>([]);
  private readonly _searchTerm$ = new BehaviorSubject<string | null>(null);
  private readonly _selectedTags$ = new BehaviorSubject<Tag[]>([]);
  private readonly _tagTypesFilter$ = new BehaviorSubject<string[]>([]);

  private readonly _destroyed$ = new Subject();

  constructor(
    @Inject(ASSIGNMENT_MODAL_OPTIONS) public options: AssignmentModalOptions,
    private ngxPermissionsService: NgxPermissionsService,
    private userAdministrationService: UserAdministrationService,
    public permConst: PermissionsConstService
  ) {
    this.searchTerm$ = this._searchTerm$.asObservable();
    this.selectedTags$ = this._selectedTags$.asObservable();
    this.tagTypesFilter$ = this._tagTypesFilter$.asObservable();

    this.selectedCount$ = this.selectedTags$.pipe(map(tags => tags.length));
    this.hasSelected$ = this.selectedTags$.pipe(map(tags => tags.length > 0));

    this.tags$ = combineLatest([
      this.assignableTags$,
      this.userAdministrationService.workingCopy$.pipe(filterNullMap()),
      this._tagTypesFilter$
    ]).pipe(
      map(([assignable, workingCopy, tagTypesFilter]: [Tag[], User, string[]]) => {
        assignable = assignable.filter(
          tag => workingCopy.assignedTags.includes(tag.tagId) === false
        );
        if (tagTypesFilter.length > 0) {
          return assignable.filter(tag => tagTypesFilter.includes(tag.tagTypeFriendlyId));
        }
        return sortBy(assignable, ["tagTypeFriendlyId", "displayName"]);
      }),
      takeUntil(this._destroyed$)
    );

    this.displayedColumns$ = this._displayedColumns$.pipe(
      switchMap(columns => {
        return combineLatest([
          of(columns),
          this.ngxPermissionsService.hasPermission([
            this.permConst.Admin.User.TagsAssignment.Delete
          ])
        ]);
      }),
      map(([columns, hasWritePermissions]: [Column[], boolean]) => {
        if (hasWritePermissions) {
          const cols = [...columns];
          cols.push({
            field: "actions",
            header: "Actions"
          } as Column);
          return cols;
        }
        return columns;
      })
    );
  }

  ngOnInit(): void {
    this._displayedColumns$.next(this.options.columns);
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  get selected() {
    return this._selectedTags$.getValue();
  }

  searched(event: string) {
    this._searchTerm$.next(event);
  }

  tagTypeFilterChanged($event: TagType[]) {
    this._tagTypesFilter$.next($event.map((tagType: TagType) => tagType.friendlyId));
  }

  assignSelected() {
    this._selectedTags$
      .pipe(
        withLatestFrom(
          this.assignableTagTypes$,
          this.assignableTags$,
          this.userAdministrationService.workingCopy$.pipe(filterNullMap())
        ),
        concatMap(([selected, tagTypes, tags, workingCopy]: [Tag[], TagType[], Tag[], User]) => {
          const tagsToRemove: string[] = getTagsToRemove(selected, tagTypes, tags, workingCopy);
          let copy: User = deeperCopy(workingCopy);
          copy = {
            ...workingCopy,
            assignedTags: workingCopy.assignedTags.filter(tagId => !tagsToRemove.includes(tagId))
          };
          const selectedTagIds: string[] = selected.map(tag => tag.tagId);
          const assignedTags: string[] =
            copy.assignedTags?.concat(selectedTagIds) ?? selectedTagIds;
          copy = {
            ...copy,
            assignedTags: assignedTags
          };
          return this.userAdministrationService.setWorkingCopy(copy);
        }),
        take(1)
      )
      .subscribe();
  }

  selectionChanged(tag: Tag) {
    this._selectedTags$
      .pipe(
        withLatestFrom(this.assignableTagTypes$),
        concatMap(([selected, tagTypes]: [Tag[], TagType[]]) => {
          handleSelectedTagEvent(tag, tagTypes, selected);
          return of(selected);
        }),
        take(1)
      )
      .subscribe(selected => {
        this._selectedTags$.next(selected);
      });
  }

  isSelected(tag: Tag): Observable<boolean> {
    return this.selectedTags$.pipe(
      map((selected: Tag[]) => selected?.findIndex(i => i.tagId === tag.tagId) > -1 ?? false)
    );
  }

  trackById: TrackByFunction<Tag> = (_: number, tag: Tag) => tag.tagId;
}

export enum DialogueNames {
  StatusUpdateConfirmation = "statusUpdateConfirmation",
  StartZoomCallConfirmation = "startZoomCallConfirmation",
  JoinZoomCallConfirmation = "joinZoomCallConfirmation",
  RemoveGroupConfirmation = "removeGroupConfirmation",
  RemoveUserConfirmation = "removeUserConfirmation",
  RemoveTagConfirmation = "removeTagConfirmation",
  RemoveRoleConfirmation = "removeRoleConfirmation",
  StartDirectDeviceZoomCall = "startDirectDeviceZoomCall",
  LaunchEkoConfirmation = "launchEkoConfirmation"
}

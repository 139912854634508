import { Injectable } from "@angular/core";
import { FormlyJsonschema } from "@ngx-formly/core/json-schema";
import { FormlyJsonschemaOptions } from "@ngx-formly/core/json-schema/formly-json-schema.service";
import { UiSchema, UiSchemaConfigService, UiSchemaDefinition } from "@vp/formly/ui-schema-config";
import { deeperCopy, getValueAtPath, insert, objectKeyValueFilter } from "@vp/shared/utilities";
import { JSONSchema7 } from "json-schema";
import { BehaviorSubject, Observable, of } from "rxjs";
import { filter, map } from "rxjs/operators";

interface InsertParams {
  targetObject: Record<string, unknown>;
  props: string[];
  objToInsert: any;
}

/*
  This entire class is temporary this functionality will be moved into a provider to live close to the app possibly
  with the logic provided as a factory or class provider.
*/
@Injectable()
export class UiSchemaLayoutProvider {
  private _schema$ = new BehaviorSubject<Record<string, unknown> | null>(null);
  get Schema(): Observable<Record<string, unknown>> {
    return this._schema$.pipe(
      filter(value => value !== undefined && value !== null),
      map(value => value as Record<string, unknown>)
    );
  }

  constructor(
    private configService: UiSchemaConfigService,
    private formlyJsonschema: FormlyJsonschema
  ) {}

  getSchemaProperties(key: string, valueFilter: string[]) {
    return objectKeyValueFilter(this._schema$.getValue(), key, valueFilter);
  }

  getFieldConfig(schema: any, options: FormlyJsonschemaOptions | undefined = undefined) {
    return [this.formlyJsonschema.toFieldConfig(schema, options)];
  }

  applyScopes(
    type: string,
    schema: any | null | undefined = null,
    scope: string | null = null
  ): Observable<JSONSchema7> {
    let clone = {};
    if (Object.keys(schema ?? {}).length === 0) {
      clone = {
        schema: "http://json-schema.org/draft-06/schema#",
        type: "object",
        title: "data",
        additionalProperties: false,
        properties: []
      };
    } else {
      clone = deeperCopy(schema);
    }

    const elements = this.configService.getLayoutElements(type, scope);

    if (elements && elements.length > 0) {
      elements.forEach((element: UiSchema) => {
        // TODO: clone = this.applySchemas(clone, element);
        clone = this.applyWidgets(clone, element);
        clone = this.applyDefinition(clone, element);
        // TODO: clone = this.applySorting(clone, element);
      });
    }
    this._schema$.next(clone);
    return of(clone);
  }

  // TODO: This function needs to be worked out to resolve issues with applying form config scopes to
  // schemas that already have properties, so they are properly rendered by formly. Currentltly
  // they are overwritten by the formly config.
  // private applySchemas(schema: any, element: FormlyUIConfigElement, source: any) {
  //   const scopeParts = element.scope.replace("#/", "").split("/");
  //   const scoped = get(schema, scopeParts);
  //   switch (element.action) {
  //     case "insert":
  //       const schemaParts = element.schema.replace("#/", "").split("/");
  //       if (!scoped) {
  //         const elementOnSource = get(source, schemaParts);
  //         schema.properties = {
  //           ...schema.properties,
  //           ...elementOnSource.properties
  //         };
  //       }
  //       break;
  //     case "update":
  //       break;
  //   }
  // }

  private applyDefinition(
    schema: Record<string, unknown>,
    element: UiSchema
  ): Record<string, unknown> {
    if (!element.definition) return schema;
    const definitions = this.configService.getDefinitions(element.definition);
    if (definitions.length > 0) {
      const scopeParts = element.scope.split("/").splice(1);
      definitions.forEach((definition: UiSchemaDefinition) => {
        const params = {
          targetObject: schema,
          props: scopeParts,
          objToInsert: {
            widget: {
              formlyConfig: definition.formlyConfig
            }
          }
        };
        insert(params, "merge");
        return params.targetObject;
      });
    }
    return schema;
  }

  private insertWidget(
    scope: Record<string, unknown>,
    scopeParts: string[],
    element: UiSchema
  ): Record<string, unknown> {
    if (scopeParts[scopeParts.length - 1] === "*") {
      const _parts = scopeParts.slice(1, -1);
      const params = {
        targetObject: scope,
        props: [],
        objToInsert: null
      } as InsertParams;
      const scoped = getValueAtPath(scope, _parts);
      Object.keys(scoped).forEach((key: string) => {
        params.props = [..._parts, key];
        params.objToInsert = {
          widget: {
            formlyConfig: deeperCopy(element.formlyConfig),
            order: element.order
          }
        };
        insert(params);
      });
      return params.targetObject;
    } else {
      const params = {
        targetObject: scope,
        props: scopeParts,
        objToInsert: {
          widget: {
            order: element.order
          }
        }
      } as InsertParams;
      if (element.formlyConfig) {
        params.objToInsert.widget["formlyConfig"] = element.formlyConfig;
      }
      insert(params);
      return params.targetObject;
    }
  }

  private applyWidgets(
    schema: Record<string, unknown>,
    element: UiSchema
  ): Record<string, unknown> {
    const scopeParts = element.scope.split("/");
    const scoped: Record<string, unknown> = this.insertWidget(schema, scopeParts, element);
    if (getValueAtPath(scoped, scopeParts)) {
      return scoped;
    } else {
      throw Error("failed to apply widget.");
    }
  }
}

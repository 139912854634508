import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatOptionModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { Store } from "@ngxs/store";
import * as GroupsActions from "@vp/data-access/groups";
import { GroupsDataAccessModule } from "@vp/data-access/groups";
import * as OrganizationActions from "@vp/data-access/organization";
import { PageMenuModule } from "@vp/shared/components/page-menu";
import { NgxPermissionsModule } from "ngx-permissions";
import { TemplateSelectorModule } from "../template-selector/template-selector.module";
import { UserAdministrationPageModule } from "../user-admin-page/user-adminstration-page.module";
import { UserAssignAccessTagsModule } from "../user-assign-access-tags/user-assign-access-tags.module";
import { UserAssignedAccessTagsModule } from "../user-assigned-access-tags/user-assigned-access-tags.module";
import { UserAssignedGroupsModule } from "../user-assigned-groups/user-assigned-groups.module";
import { UserAssignedRolesModule } from "../user-assigned-roles/user-assigned-roles.module";
import { UserAssignedTagsModule } from "../user-assigned-tags/user-assigned-tags.module";
import { InviteUserComponent } from "./invite-user.component";

@NgModule({
  imports: [
    AngularEditorModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    GroupsDataAccessModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    NgxPermissionsModule,
    PageMenuModule,
    ReactiveFormsModule,
    RouterModule,
    TemplateSelectorModule,
    UserAdministrationPageModule,
    UserAssignAccessTagsModule,
    UserAssignedAccessTagsModule,
    UserAssignedGroupsModule,
    UserAssignedRolesModule,
    UserAssignedTagsModule
  ],
  exports: [InviteUserComponent],
  declarations: [InviteUserComponent]
})
export class InviteUserFeatureModule {
  constructor(store: Store) {
    store.dispatch(new OrganizationActions.LoadTemplates());
    store.dispatch(new GroupsActions.LoadGroups());
  }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatChipsModule } from "@angular/material/chips";
import { CommunicationCountsNavComponent } from "./communication-counts-nav.component";

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MatChipsModule],
  declarations: [CommunicationCountsNavComponent],
  exports: [CommunicationCountsNavComponent]
})
export class CommunicationCountsNavModule {}

import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { UserApiService } from "@vp/data-access/users";
import { PageResult, PageState, User } from "@vp/models";
import { parseError } from "@vp/shared/utilities";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { DeviceAdminFilter } from "../models/device-admin-filter";
import { defaultDeviceAdminState, DeviceAdminStateModel } from "../models/device-admin-state.model";
import * as DeviceAdminActions from "./device-admin.actions";

@State<DeviceAdminStateModel>({
  name: "deviceAdmin",
  defaults: defaultDeviceAdminState()
})
@Injectable()
export class DeviceAdminState {
  constructor(private readonly userApiService: UserApiService) {}

  @Selector()
  public static currentState(state: DeviceAdminStateModel) {
    return state;
  }

  @Selector()
  static filteredDevices(state: DeviceAdminStateModel): User[] {
    return [...state.devices];
  }

  @Selector()
  static currentFilter(state: DeviceAdminStateModel): DeviceAdminFilter {
    return state.filter;
  }

  @Selector()
  static pageState(state: DeviceAdminStateModel): PageState {
    return state.pageState;
  }

  @Selector()
  static selectedDevice(state: DeviceAdminStateModel): User | null {
    return state.selectedDevice;
  }

  @Action(DeviceAdminActions.SetInitialState)
  setInitialState(ctx: StateContext<DeviceAdminStateModel>, state: DeviceAdminStateModel) {
    ctx.setState(state);
  }

  @Action(DeviceAdminActions.SetFilter)
  setFilter(
    ctx: StateContext<DeviceAdminStateModel>,
    { filter: directConnectFilter }: DeviceAdminStateModel
  ) {
    const currentState = ctx.getState();
    const updatedState = {
      ...currentState,
      filter: directConnectFilter
    };
    return this.getFilteredUsers(updatedState).pipe(
      tap((pageResult: PageResult<User>) => {
        pageResult.results.forEach(
          r => (r.isOnline = r.patientConsoleDeviceData?.signalRConnection?.isOnline ?? false)
        ),
          ctx.patchState({
            ...updatedState,
            devices: pageResult.results,
            pageState: {
              pageIndex: currentState.pageState.pageIndex,
              pageSize: pageResult.pageSize,
              totalRecords: pageResult.totalRecords,
              pageCount: pageResult.pageCount,
              lastPage: pageResult.lastPage
            }
          });
      })
    );
  }

  @Action(DeviceAdminActions.GetFiltered)
  getFiltered(ctx: StateContext<DeviceAdminStateModel>) {
    const currentState: DeviceAdminStateModel = ctx.getState();
    return this.getFilteredUsers(currentState).pipe(
      tap((pageResult: PageResult<User>) => {
        ctx.patchState({
          devices: pageResult.results,
          pageState: {
            pageIndex: currentState.pageState.pageIndex,
            totalRecords: pageResult.totalRecords,
            pageCount: pageResult.pageCount,
            lastPage: pageResult.lastPage,
            pageSize: pageResult.pageSize
          }
        });
      })
    );
  }

  private getFilteredUsers(state: DeviceAdminStateModel): Observable<PageResult<User>> {
    return this.userApiService.getUsersPageResult(
      state.filter.take,
      state.filter.skip,
      state.filter.sort,
      state.filter.sortDirection,
      state.filter.search,
      state.filter.filters,
      state.filter.tagIds,
      state.filter.deptId
    );
  }

  @Action(DeviceAdminActions.SetPageState)
  setPageState(
    ctx: StateContext<DeviceAdminStateModel>,
    { pageState: pageState }: DeviceAdminStateModel
  ) {
    const currentState = ctx.getState();
    const updatedState = {
      ...currentState,
      pageState: pageState,
      filter: {
        ...currentState.filter,
        take: pageState.pageSize,
        skip: pageState.pageSize * pageState.pageIndex
      }
    };
    return this.getFilteredUsers(updatedState).pipe(
      tap((pageResult: PageResult<User>) => {
        ctx.patchState({
          ...updatedState,
          devices: pageResult.results,
          pageState: {
            pageIndex: pageState.pageIndex,
            pageSize: pageState.pageSize,
            totalRecords: pageResult.totalRecords,
            pageCount: pageResult.pageCount,
            lastPage: pageResult.lastPage
          }
        });
      })
    );
  }

  @Action(DeviceAdminActions.SetSelectedDevice)
  setSelectedDevice(
    ctx: StateContext<DeviceAdminStateModel>,
    action: DeviceAdminActions.SetSelectedDevice
  ) {
    return this.userApiService.getUser(action.id).pipe(
      tap(user => {
        ctx.patchState({ selectedDevice: user });
      }),
      catchError(error => {
        ctx.patchState({
          selectedDevice: null,
          errors: parseError(error)
        });
        return throwError(error);
      })
    );
  }

  @Action(DeviceAdminActions.RemoveRole)
  removeRole(ctx: StateContext<DeviceAdminStateModel>, action: DeviceAdminActions.RemoveRole) {
    const updatedDevice: User = { ...action.device, roles: [] };
    return this.userApiService.updateUser(updatedDevice).pipe(
      tap(user => {
        ctx.patchState({ selectedDevice: user });
        const index: number = ctx
          .getState()
          .devices.findIndex(i => i.userId === action.device.userId);
        if (index !== -1) {
          ctx.patchState({
            devices: Object.assign([], ctx.getState().devices, { [index]: user })
          });
        }
      }),
      catchError(error => {
        ctx.patchState({
          errors: parseError(error)
        });
        return throwError(error);
      })
    );
  }

  @Action(DeviceAdminActions.UpdateDevice)
  updateItem(ctx: StateContext<DeviceAdminStateModel>, action: DeviceAdminActions.UpdateDevice) {
    const currentState: DeviceAdminStateModel = ctx.getState();
    const index: number = currentState.devices.findIndex(i => i.userId === action.device.userId);
    if (index !== -1) {
      ctx.patchState({
        devices: Object.assign([], currentState.devices, { [index]: action.device })
      });
    }
  }

  @Action(DeviceAdminActions.UpdateState)
  updateState(ctx: StateContext<DeviceAdminStateModel>, { state }: DeviceAdminActions.UpdateState) {
    ctx.patchState(state);
  }
}

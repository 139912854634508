import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { PageResult, Profile, User } from "@vp/models";
import { Operation } from "rfc6902";
import { Observable, throwError } from "rxjs";

export const USER_API_BASE_URL = new InjectionToken<string>("API_BASE_URL");

@Injectable({
  providedIn: "root"
})
export class UserApiService {
  constructor(@Inject(USER_API_BASE_URL) private _apiBaseUrl: string, private _http: HttpClient) {}

  public getUser = (userId: string): Observable<User> => {
    if (userId.length === 0) {
      return throwError("User Id is required");
    }
    const apiURL = `${this._apiBaseUrl}/user/${userId}`;
    return this._http.get<User>(apiURL);
  };

  public getUserLogin = (): Observable<User> => {
    const apiURL = `${this._apiBaseUrl}/user/get/login`;
    return this._http.get<User>(apiURL);
  };

  public getUsers = (take: number = 10, skip: number = 0): Observable<PageResult<User>> => {
    const apiURL = `${this._apiBaseUrl}/user/?take=${take}&skip=${skip}`;
    return this._http.get<PageResult<User>>(apiURL);
  };

  public getUsersPageResult = (
    take: number = 10,
    skip: number = 0,
    sort: string | null | undefined,
    sortDirection: string | null | undefined,
    search: string | null | undefined,
    filters?: string[] | null | undefined,
    tagIds?: string[] | null | undefined,
    deptId?: string | null | undefined,
    connected?: boolean | null | undefined
  ): Observable<PageResult<User>> => {
    let apiURL = `${this._apiBaseUrl}/user/?take=${take}&skip=${skip}`;
    if (sort) {
      apiURL = apiURL + `&sort=${sort}`;
    }
    if (sort && sortDirection !== null) {
      apiURL = apiURL + `&sort_direction=${sortDirection}`;
    }
    if (search) {
      apiURL = apiURL + `&search=${search}`;
    }
    if (filters) {
      apiURL = apiURL + filters.map(filter => `&filter=${filter}`);
    }
    if (tagIds && tagIds?.length > 0) {
      apiURL = apiURL + tagIds.map(tagId => `&tag=${tagId}`);
    }
    if (deptId) {
      apiURL = apiURL + `&deptId=${deptId}`;
    }
    if (connected !== null && connected !== undefined) {
      apiURL = apiURL + `&connected=${connected}`;
    }

    return this._http.get<PageResult<User>>(apiURL);
  };

  public createUser = (userId: string, email: string, profile: Profile): Observable<User> => {
    const apiURL = `${this._apiBaseUrl}/user/`;
    return this._http.post<User>(apiURL, {
      userId,
      email,
      profile
    });
  };

  public inviteUser = (user: User): Observable<User> => {
    const apiURL = `${this._apiBaseUrl}/invite`;
    return this._http.post<User>(apiURL, user);
  };

  public updateUser = (user: User, resendVerificationCode?: boolean): Observable<User> => {
    let apiURL = `${this._apiBaseUrl}/user/${user.userId}`;
    if (resendVerificationCode) {
      apiURL += "?resend-code=true";
    }
    return this._http.put<User>(apiURL, user);
  };

  public updateDeviceUser = (device: User): Observable<User> => {
    const apiURL = `${this._apiBaseUrl}/virtual-care/device-user/${device.userId}`;
    return this._http.put<User>(apiURL, device);
  };

  public getAssignableUsers = (caseTypeId: string) => {
    const apiURL = `${this._apiBaseUrl}/users?caseTypeId=${caseTypeId}`;
    return this._http.get<User[]>(apiURL);
  };

  public getAssignableUsersByCaseId = (caseId: string, queryString: string): Observable<User[]> => {
    const apiURL = `${this._apiBaseUrl}/case/${caseId}/assignableUsers/${queryString}`;
    return this._http.get<User[]>(apiURL);
  };

  public getAssignableDevices = (caseId: string, queryString: string): Observable<User[]> => {
    const apiURL = `${this._apiBaseUrl}/case/${caseId}/assignableDevices${queryString}`;
    return this._http.get<User[]>(apiURL);
  };

  public getGroupsAssignedToUsers = (groupIds: string[]): Observable<string[]> => {
    const apiURL = `${this._apiBaseUrl}/groups-assigned-to-users`;
    return this._http.post<string[]>(apiURL, groupIds);
  };

  public getTagsAssignedToUsers = (tagIds: string[]): Observable<string[]> => {
    const apiURL = `${this._apiBaseUrl}/tags-assigned-to-users`;
    return this._http.post<string[]>(apiURL, tagIds);
  };

  public patch = (userId: string, operations: Operation[]) => {
    const apiURL = `${this._apiBaseUrl}/user/${userId}`;
    return this._http.patch(apiURL, operations);
  };
}

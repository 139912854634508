import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Select, Store } from "@ngxs/store";
import { DeviceConnectionChangedEvent, PageState, User } from "@vp/models";
import { ConfirmationDialogComponent } from "@vp/shared/components/confirmation-dialog";
import { EventAggregator, IEvent } from "@vp/shared/event-aggregator";
import { filterNullMap } from "@vp/shared/operators";
import { AdvancedCommandsComponent } from "@vp/virtual-care/feature-advanced-commands";
import { Observable, Subject } from "rxjs";
import { first, map, take, takeUntil, tap, withLatestFrom } from "rxjs/operators";
import { DeviceAdminFilter } from "../device-admin-state/models/device-admin-filter";
import { DeviceAdminService } from "../device-admin-state/services/device-admin.service";
import {
  SetFilter,
  SetPageState,
  UpdateDevice
} from "../device-admin-state/state+/device-admin.actions";
import { DeviceAdminState } from "../device-admin-state/state+/device.admin.state";

@Component({
  selector: "vp-device-list-page",
  templateUrl: "./device-list-page.component.html",
  styleUrls: ["./device-list-page.component.scss"],
  providers: [DeviceAdminService]
})
export class DeviceListPageComponent implements OnInit, OnDestroy {
  @Select(DeviceAdminState.currentFilter) currentFilter$!: Observable<DeviceAdminFilter>;
  @Select(DeviceAdminState.pageState) pageState$!: Observable<PageState>;
  @Select(DeviceAdminState.filteredDevices) filteredDevices$!: Observable<User[]>;

  @ViewChild(MatSort) sort!: MatSort;

  dataSource$: Observable<MatTableDataSource<User>> = this.filteredDevices$.pipe(
    map(devices => {
      const dataSource = new MatTableDataSource<User>();
      dataSource.data = devices;
      return dataSource;
    })
  );

  displayedColumns = [
    "isOnline",
    "email",
    "name",
    "description",
    "groups",
    "location",
    "version",
    "actions"
  ];

  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  selectedDeptId = "all";

  private readonly _destroyed$ = new Subject();

  constructor(
    private readonly deviceAdminService: DeviceAdminService,
    private readonly dialog: MatDialog,
    private readonly eventAggregator: EventAggregator,
    private readonly store: Store
  ) {
    this.deviceAdminService.listenForSearchParameters();
  }
  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  ngOnInit(): void {
    this.currentFilter$.pipe(filterNullMap(), take(1)).subscribe(filter => {
      if (filter.deptId) this.selectedDeptId = filter.deptId;
    });

    this.eventAggregator
      .on<DeviceConnectionChangedEvent>(DeviceConnectionChangedEvent)
      .pipe(
        withLatestFrom(this.filteredDevices$.pipe(filterNullMap())),
        takeUntil(this._destroyed$)
      )
      .subscribe(([event, devices]: [IEvent, User[]]) => {
        const device: User | undefined = devices.find(d => d.userId === event.args.deviceUserId);
        if (device) {
          device.isOnline = event.args.isOnline;
          this.store.dispatch(new UpdateDevice(device));
        }
      });
  }

  departmentSelectionChanged(departmentId: string) {
    const filter: Partial<DeviceAdminFilter> | null = this.store.selectSnapshot(
      DeviceAdminState.currentFilter
    );
    this.store.dispatch(
      new SetFilter({
        ...filter,
        deptId: departmentId === "all" ? null : departmentId
      })
    );
  }

  handlePageEvent(event: PageEvent) {
    this.pageState$
      .pipe(
        first(),
        tap(pageState => {
          this.store.dispatch(
            new SetPageState({
              totalRecords: event.length,
              pageIndex: event.pageIndex,
              pageCount: pageState.pageCount,
              pageSize: event.pageSize,
              lastPage: pageState.lastPage
            })
          );
        })
      )
      .subscribe();
  }

  sortChange($event: Sort) {
    const filter: Partial<DeviceAdminFilter> | null = this.store.selectSnapshot(
      DeviceAdminState.currentFilter
    );
    this.store.dispatch(
      new SetFilter({
        ...filter,
        sort: $event.active,
        sortDirection: $event.direction
      })
    );
  }

  showDeleteDialog(device: User): void {
    let confirmationMessage = "";
    if (device.roomNumber) {
      confirmationMessage = `Device is assigned to ${device.roomNumber}. `;
    }
    confirmationMessage += "Are you sure you want to delete this device?";

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: "Are you sure?",
        message: confirmationMessage
      }
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(dialogResult => {
        if (dialogResult) {
          //TODO: Create action to delete device
          // this.deviceStoreService.deleteDeviceAction(device.userId).subscribe({
          //   next: () => {
          //     this.notificationService.successMessage("Deleted successfully");
          //     this.router.navigate(["/", "administration", "device"]);
          //   },
          //   error: () => this.notificationService.errorMessage("Delete failed.")
          // });
        }
      });
  }

  showAdvancedCommands(deviceId: string): void {
    this.dialog.open(AdvancedCommandsComponent, {
      data: {
        deviceId: deviceId
      }
    });
  }
}

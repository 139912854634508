import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ContentNavListDetailComponent } from "./content-nav-list-detail.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatChipsModule } from "@angular/material/chips";

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MatChipsModule],
  declarations: [ContentNavListDetailComponent],
  exports: [ContentNavListDetailComponent]
})
export class ContentNavListDetailModule {}

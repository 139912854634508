<div mat-dialog-title>
  <mat-icon color="accent" [inline]="true">error_outline</mat-icon>
  {{ data.title }}
</div>
<mat-dialog-content>
  <p>{{ data.message }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="true">
    {{ data.action ? data.action : "Confirm" }}
  </button>
</mat-dialog-actions>

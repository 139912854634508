import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { ContentManagementLeftMenuModule } from "@vp/content-management/ui/content-management-left-menu";
import { BreadcrumbModule } from "@vp/shared/components/breadcrumb";
import { PageMenuModule } from "@vp/shared/components/page-menu";
import { MaterialModule } from "@vp/shared/material";
import { NgxPermissionsModule } from "ngx-permissions";
import { NewContentPageComponent } from "./new-content-page.component";

@NgModule({
  imports: [
    AngularEditorModule,
    BreadcrumbModule,
    CommonModule,
    ContentManagementLeftMenuModule,
    FlexLayoutModule,
    MaterialModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSidenavModule,
    NgxPermissionsModule,
    FormsModule,
    MatFormFieldModule,
    PageMenuModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule
  ],
  declarations: [NewContentPageComponent],
  exports: [NewContentPageComponent]
})
export class NewContentPageModule {}

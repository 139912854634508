import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { Select } from "@ngxs/store";
import { ContentFilterState } from "@vp/content-management/data-access/content";

import { ContentSearch, OrganizationContentType } from "@vp/models";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "vp-content-nav-list-detail",
  templateUrl: "./content-nav-list-detail.component.html",
  styleUrls: ["./content-nav-list-detail.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentNavListDetailComponent implements OnInit {
  @Input() countentTypeCount: OrganizationContentType | undefined;
  @Select(ContentFilterState.results) results$!: Observable<ContentSearch[]>;
  counter$: Observable<number>;
  private readonly _counter$ = new BehaviorSubject<number>(0);

  constructor() {
    this.counter$ = this._counter$.asObservable();
  }

  ngOnInit(): void {
    this.results$
      .pipe(
        map(item => {
          if (this.countentTypeCount?.friendlyId != "all") {
            return item.filter(x => x.contentTypeId === this.countentTypeCount?.friendlyId).length;
          } else {
            return item.length;
          }
        })
      )
      .subscribe(item => {
        this._counter$.next(item);
      });
  }
}

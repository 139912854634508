import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { FormlyModule } from "@ngx-formly/core";
import { NgxsModule, Store } from "@ngxs/store";
import * as GroupsActions from "@vp/data-access/groups";
import { FormlyFlexLayoutTypeComponent } from "@vp/formly/json-schema";
import { UserFormProfileModule } from "@vp/shared/components/user-form-profile";
import { FeaturesModule } from "@vp/shared/features";
import { NgxPermissionsModule } from "ngx-permissions";
import { DeviceAdminState } from "../device-admin-state/state+/device.admin.state";
import { UserAdmininstrationState } from "../user-administration-state/state+/user-adminstrations.state";
import { UserAssignRolesModule } from "../user-assign-roles/user-assign-roles.module";
import { UserAssignTagsModule } from "../user-assign-tags/user-assign-tags.module";
import { UserAssignedAccessTagsModule } from "../user-assigned-access-tags/user-assigned-access-tags.module";
import { UserAssignedGroupsModule } from "../user-assigned-groups/user-assigned-groups.module";
import { UserAssignedRolesModule } from "../user-assigned-roles/user-assigned-roles.module";
import { UserAssignedTagsModule } from "../user-assigned-tags/user-assigned-tags.module";
import { UserSnippetsModule } from "../user-snippets/user-snippets.module";
import { UserAdministrationPageComponent } from "./user-administration-page.component";

export interface GroupSearchable {
  groupId: string;
  groupTypeId: string;
  search?: string;
}

@NgModule({
  imports: [
    CommonModule,
    FeaturesModule,
    FlexLayoutModule,
    FormlyModule.forChild({
      types: [{ name: "flex-layout", component: FormlyFlexLayoutTypeComponent }]
    }),
    FormsModule,
    MatButtonModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatTooltipModule,
    NgxPermissionsModule.forChild(),
    NgxsModule.forFeature([DeviceAdminState, UserAdmininstrationState]),
    ReactiveFormsModule,
    RouterModule,
    UserAssignedAccessTagsModule,
    UserAssignedGroupsModule,
    UserAssignedRolesModule,
    UserAssignedTagsModule,
    UserAssignRolesModule,
    UserAssignTagsModule,
    UserSnippetsModule,
    UserFormProfileModule
  ],
  exports: [UserAdministrationPageComponent],
  declarations: [UserAdministrationPageComponent]
})
export class UserAdministrationPageModule {
  constructor(store: Store) {
    store.dispatch(new GroupsActions.LoadGroups());
  }
}

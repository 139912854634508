<mat-form-field fxFlex appearance="fill">
  <mat-label>Language</mat-label>
  <input
    type="text"
    placeholder="Choose Language"
    matInput
    [matAutocomplete]="auto"
    [formControl]="formControl"
    [required]="field.templateOptions?.required ?? false"
  />
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option matInput *ngFor="let tag of languageTags$ | async" [value]="tag.displayName">
      {{ tag.displayName }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    showErrors: (showErrors$ | async) || false,
    errors: (errors$ | async) || [],
    isLoading: (isLoading$ | async) || [],
    menuMode: (menuMode$ | async) || [],
    pageMode: (pageMode$ | async) || [],
    showSpinner: (showSpinner$ | async) || false,
    viewMode: (viewMode$ | async) || false,
    isActive: (isActive$ | async) || false
  }"
>
</ng-container>

  <ng-template
  #template
  let-showErrors="showErrors"
  let-errors="errors"
  let-isLoading="isLoading"
  let-menuMode="menuMode"
  let-pageMode="pageMode"
  let-showSpinner="showSpinner"
  let-viewMode="viewMode"
  let-isActive="isActive"
  >
    <mat-drawer-container autosize fxFlexFill>
      <mat-drawer
        [mode]="menuMode!"
        fxLayout="column"
        fxLayoutGap="16px"
        class="options"
        [opened]="true"
      >
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Template Parts </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-option value="report">Report</mat-option>
            <mat-option value="email">Email</mat-option>
            <mat-option value="message">Message</mat-option>
          </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-label>Help Information Parts</mat-label>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-option value="howLogin">How to login</mat-option>
            <mat-option value="howCreateCase">How to create a case</mat-option>
            <mat-option value="howLogOut">How to log out</mat-option>
          </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Images </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-option value="emailHeader">Email Header Image</mat-option>
            <mat-option value="emailFooter">Email Footer Image</mat-option>
            <mat-option value="reportHeader">Report Header Image</mat-option>
          </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Entities </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-option value="case.recorddata.subject.txtFirstName">Case txtFirstName</mat-option>
            <mat-option value="template.patient-info">Template Patient Info</mat-option>
            <mat-option value="case.recorddata.homeDoctor.txtFirstName"
              >Case Home Doctor First Name</mat-option
            >
          </mat-expansion-panel>
        </mat-accordion>
      </mat-drawer>

      <mat-drawer-content #content>
        <!---TOP ROW  -->
        <div fxLayout="column" class="horizontal-padding vertical-padding">
          <vp-breadcrumb></vp-breadcrumb>
          <mat-spinner *ngIf="showSpinner === true" class="center"></mat-spinner>
          <form fxFill [formGroup]="form">
          <mat-card fxLayout="column" fxLayoutGap="25px">
            <div fxLayout="row wrap" fxLayoutAlign="space-between center">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                <span>Content Mode:</span>
                <mat-slide-toggle (click)="toggleviewMode()"
                  *ngxPermissionsOnly="[permConst.Admin.User.Write]"
                  [matTooltip]="viewMode ? 'ContentMode is HTML' : 'ContentMode is codeView'"
                ></mat-slide-toggle>
                <span>{{ viewMode ? "CodeView": "HTML"  }}</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                <span>Content State:</span>
                <mat-slide-toggle (click)="toggleIsActiveMode()"
                  *ngxPermissionsOnly="[permConst.Admin.User.Write]"
                  formControlName="active"
                  [matTooltip]="isActive ? 'Content is active' : 'Content is inactive'"
                ></mat-slide-toggle>
                <span>{{ isActive ? "Active" : "Inactive" }}</span>
              </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between center">
              <mat-form-field fxFlex="15"  >
                <mat-label>Content Type</mat-label>
                <mat-select formControlName="contentTypeId" required>
                  <mat-option value="template">Template</mat-option>
                  <mat-option value="helpInformation">Help Information</mat-option>
                  <mat-option value="images">Images</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field fxFlex="30">
                <mat-label>Content Name</mat-label>
                <input matInput placeholder="Content Name" formControlName="displayName" required />
                <mat-error>Please enter a content name.</mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="30">
                <mat-label>Content Description</mat-label>
                <input
                  matInput
                  placeholder="Content Description"
                  formControlName="description"
                  required
                />
                <mat-error>Please enter a content description.</mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between center">
              <angular-editor
              fxFlex="100"
              id="templateEditor"
              formControlName="content"
              [config]="config"
              [placeholder]="'Enter text here...'"
            >
            </angular-editor>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="center" class="tagsDiv">
              <h2>Tags Component will be here</h2>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="action-row">
            <div fxLayout="row" fxLayoutAlign="end center" >
              <button
              *ngxPermissionsOnly="[permConst.Case.Content.Write]"
              type="button"
              mat-flat-button
              color="primary"
              (click)="viewPreview()"
            >
              Preview
              </button>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" >
              <button mat-button (click)="onCancelClick()" type="button">Cancel</button>
              <button
                *ngxPermissionsOnly="[permConst.Case.Content.Write]"
                type="button"
                mat-flat-button
                color="primary"
                [disabled]="form.invalid || isClicked"
                (click)="save()"
              >
                Save
              </button>
            </div>
            </div>

          </mat-card>
        </form>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </ng-template>


import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { defaultJsonschemaOptions } from "@vp/formly/json-schema";
import { UiSchemaLayoutProvider } from "@vp/formly/ui-schema-config";
import { Organization, User, UserTypeConfig } from "@vp/models";
import { NotificationService } from "@vp/shared/notification";
import { filterNullMap } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { JSONSchema7 } from "json-schema";
import { NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, combineLatest, EMPTY, from, Observable, of, Subject } from "rxjs";
import { concatMap, map, mergeMap, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { UserOperations } from "../user-administration-state/models/user-operations.model";
import { RolesAssignmentService } from "../user-administration-state/services/roles-assignment.service";
import { UserAdministrationService } from "../user-administration-state/services/user-administration.service";

const LOGIN_USER_USER_TYPE_FRIENDLY_ID = "login-user";

interface PageParams {
  userId: string;
}

@Component({
  selector: "vp-user-administration-page",
  templateUrl: "./user-administration-page.component.html",
  styleUrls: ["./user-administration-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RolesAssignmentService, UserAdministrationService]
})
export class UserAdministrationPageComponent implements OnInit, OnDestroy {
  organization$: Observable<Organization> = this.userAdministrationService.organization$;
  user$: Observable<User> = this.userAdministrationService.user$.pipe(filterNullMap());

  layoutSchema$: Observable<JSONSchema7> = this.userAdministrationService.layoutSchema$.pipe(
    filterNullMap()
  );

  hasPending$: Observable<boolean> = this.userAdministrationService.pendingOperations$.pipe(
    map((userOperations: UserOperations | null) => {
      if (userOperations) return userOperations.operations.length > 0;
      return false;
    })
  );

  fields: FormlyFieldConfig[] = [];
  form!: FormGroup;
  model = {};

  bypassAssignable!: Observable<boolean>;
  maxAllDepartmentChildren: Observable<any>;

  private _bypassAssignable$ = new BehaviorSubject(false);
  private _destroyed$ = new Subject();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
    private readonly notificationService: NotificationService,
    private readonly permissionsService: NgxPermissionsService,
    private readonly router: Router,
    private readonly uiSchemaLayoutProvider: UiSchemaLayoutProvider,
    private readonly userAdministrationService: UserAdministrationService,
    public permConst: PermissionsConstService
  ) {
    this.bypassAssignable = this._bypassAssignable$.pipe(takeUntil(this._destroyed$));

    this.maxAllDepartmentChildren = this.organization$.pipe(
      map((organization: Organization) => {
        const deviceUserTypeConfig = organization.userTypeConfig.find((u: UserTypeConfig) => {
          return u.type === LOGIN_USER_USER_TYPE_FRIENDLY_ID;
        });
        return (
          deviceUserTypeConfig?.singleDepartmentAssignment &&
          deviceUserTypeConfig?.singleRoleAssignment
        );
      }),
      takeUntil(this._destroyed$)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap
      .pipe(
        map((paramMap: ParamMap) => {
          return {
            userId: paramMap.get("id") || null
          } as PageParams;
        }),
        filterNullMap(),
        concatMap((params: PageParams) => this.userAdministrationService.setUser(params.userId)),
        switchMap(() =>
          combineLatest([
            this.userAdministrationService.user$.pipe(filterNullMap()),
            this.userAdministrationService.layoutSchema$.pipe(filterNullMap()),
            from(this.permissionsService.hasPermission(this.permConst.Admin.User.Profile.Write))
          ])
        ),
        tap(([user, layoutSchema, hasPermission]: [User, JSONSchema7, boolean]) => {
          this.buildForm(user);
          const profile = this.form.get("profile");
          if (profile && hasPermission === false) {
            profile.disable();
          }
          this.fields = this.uiSchemaLayoutProvider.getFieldConfig(
            layoutSchema,
            defaultJsonschemaOptions
          );
          this.changeDetectorRef.detectChanges();
          this.form.patchValue(user, { emitEvent: false });
        }),
        take(1),
        mergeMap(() => this.form.valueChanges),
        concatMap((formData: Record<string, unknown>) => {
          return this.userAdministrationService.updateWorkingCopy(formData);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  save = () => {
    combineLatest([
      of(this.form),
      this.userAdministrationService.workingCopy$.pipe(filterNullMap())
    ])
      .pipe(
        take(1),
        concatMap(([form, workingCopy]: [FormGroup, User]) => {
          if (form.invalid) {
            this.notificationService.warningMessage("The form is invalid");
            return EMPTY;
          }
          const userType: string = workingCopy.userType.friendlyId;
          if (userType === "login-user") return this.userAdministrationService.updateUser();
          else if (userType === "device") return this.userAdministrationService.updateDevice();
          this.notificationService.warningMessage(`Unable to save user of type ${userType}`);
          return EMPTY;
        }),
        switchMap(() => of(this.activatedRoute))
      )
      .subscribe({
        next: (route: ActivatedRoute) => {
          this.notificationService.successMessage("Changes saved");
          this.router.navigate(["../"], { relativeTo: route });
        },
        error: () => {
          this.notificationService.errorMessage("There was an error saving the form.");
        }
      });
  };

  private buildForm(user: User) {
    const profile = new FormGroup({});
    this.form = this.formBuilder.group({
      email: [{ value: user.email, disabled: true }],
      profile,
      active: [{ value: user.active }]
    });
  }

  resendInvite() {
    this.userAdministrationService.invite().subscribe({
      next: () => {
        this.notificationService.successMessage("User invited successfully");
      },
      error: () => this.notificationService.errorMessage("Failed to resend invitation")
    });
  }
}

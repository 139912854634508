import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Snippet } from "@vp/models";

@Component({
  selector: "vp-add-or-edit-snippet-dialog",
  templateUrl: "./add-or-edit-snippet-dialog.component.html",
  styleUrls: ["./add-or-edit-snippet-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddOrEditSnippetDialogComponent {
  action: "Add" | "Edit";
  snippet: Snippet;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.snippet = data.snippet ?? ({} as Snippet);
    this.action = data.action;
  }
}

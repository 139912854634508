/**
 * Database representation of custom UI
 */
export interface DisplayTag {
  tag: string;
  display: string;
}

/*
  A map of all default display tag keys and values used by the ui-store.service.ts
  ngx-translate library would be a better method of custom UI
*/
export const defaultDisplayTags: Record<string, string> = {
  caseNav: "Case",
  reviewNav: "Review",
  responseNav: "Response",
  answersNav: "Answers",
  imagesNav: "Images",
  documentsNav: "Documents",
  submitNav: "Submit",
  featureBillingNav: "Fees & Payments",
  approveNav: "Approve",
  assignmentsNav: "Assignments",
  communicationsNav: "Communications",
  resultsNav: "Results",
  resultName: "result",
  responseName: "response",
  conferenceNav: "Video Conference",
  chatNav: "Chat",
  pushNav: "Push",
  qaConsoleNav: "QA Console",

  caseSearch: "Case Search",
  caseTypeSearch: "Case Type Search",
  groupSearch: "Group Search",
  userSearch: "User Search",

  homeNav: "Home",

  dashboardNav: "Dashboard",
  dashboardSectionsNav: "Global",
  dashboardOverviewNav: "Overview",

  caseManagerNav: "Request Manager",
  caseManagerStatusNav: "Request Status",
  caseManagerDetailsNav: "Request Details",
  queueManagerNav: "Consult Management",
  patientManagerNav: "Patients",
  updateDialogFinalReport: "Approve Opinion and Send Notification",

  departmentFilter: "Department",
  opinionTypeFilter: "Opinion Type",

  profileNav: "Profile",
  detailsNav: "Details",
  accountNav: "Account",

  administrationNav: "Administration",
  caseTypePageNav: "Case Type Details",
  casetypePageNav: "Case Types",
  deviceListPageNav: "Devices",
  groupListNav: "Groups",
  groupPageNav: "Group Details",
  inviteUserPageNav: "Invite New User",
  organizationPageNav: "Organization",
  patientDashboardNav: "Patient Manager",
  qaConsolePageNav: "QA Console",
  questionEditorListNav: "Question List",
  questionEditorPageNav: "Question Editor",
  scheduleMaintenanceNav: "Schedule Maintenance",
  accountingPageNav: "Accounting",

  tagsListPageNav: "Tags",
  userListPageNav: "Users",
  userPageNav: "User Details",

  aboutNav: "About",

  caseQueueBreadcrumbButton: "Create New Consult",
  caseManagerBreadcrumbButton: "Create New Case",
  caseTypeBreadcrumbButton: "Create New Case Type",
  groupListBreadcrumbButton: "Create New Group",
  questionGroupBreadcrumbButton: "Create New Question Group",

  directConnectNav: "Direct Connect",
  directConnectStatusNav: "Direct Connect Status",

  subjectSearchNav: "Subjects",
  subjectSearchStatusNav: "Subjects Status",
  subjectSearchBreadcrumbButton: "Create New Subject",
  subjectTypeFilter: "Subject Type",

  caseWizardSelectPrompt: "Select Opinion Type",
  caseWizardRequestLabel: "request",

  assignGroupLabel: "Assign Group(s)",
  assignUserLabel: "Assign User",

  submitTitle: "Case Submitted",
  submitDetail: "",

  approveAndSendButton: "Approve and Send",

  contentManagementNav: "Content Manager",
  allContentNav: "All",
  templatesContentNav: "Templates",
  helpInformationContentNav: "Help Information",
  imagesContentNav: "Images",
  contentTypeFilter: "Content Type",
  contentManagerBreadcrumbButton: "Create New Content",
  contentSearch: "Content Search"
};

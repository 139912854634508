<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    tags: (tags$ | async) ?? [],
    tagTypesFilter: (tagTypesFilter$ | async) ?? [],
    selected: (selectedTags$ | async) || [],
    columns: (displayedColumns$ | async) || [],
    search: (searchTerm$ | async) || null
  }"
>
</ng-container>

<ng-template
  #template
  let-tags="tags"
  let-tagTypesFilter="tagTypesFilter"
  let-columns="columns"
  let-search="search"
  let-selected="selected"
>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1em">
    <div fxFlex="noshrink" mat-dialog-title>{{ title }}</div>
    <vp-tag-type-selector
      [multiple]="true"
      [availableTagTypes]="assignableTagTypes$"
      (changedEvent)="tagTypeFilterChanged($event)"
    >
    </vp-tag-type-selector>
    <vp-quick-search
      fxFlex="50"
      (changedEvent)="searched($event)"
      aria-placeholder="Filter"
    ></vp-quick-search>
  </div>
  <mat-dialog-content fxLayout="column" class="selection">
    <ng-container
      *ngIf="tags | filterTerm: search:excludeProperties:tagTypesFilter as filteredTags"
    >
      <mat-table #assignedTable [dataSource]="filteredTags">
        <ng-container *ngFor="let column of columns; let i = index">
          <ng-container
            *ngIf="column.field !== 'actions'; else actions"
            [matColumnDef]="column.field"
          >
            <mat-header-cell *matHeaderCellDef>{{ column.header }}</mat-header-cell>
            <mat-cell *matCellDef="let item">
              <span *ngIf="!column.pipe; else piped">{{ item[column.field] }}</span>
              <ng-template #piped>
                <span *ngIf="column.pipe">{{
                  item[column.field] | dynamicLookup: column.pipe:item[column.field] | async
                }}</span>
              </ng-template>
            </mat-cell>
          </ng-container>
          <ng-template #actions>
            <ng-container matColumnDef="actions" stickyEnd="true">
              <mat-header-cell *matHeaderCellDef>
                <div fxFlex fxLayout="row" fxLayoutAlign="end">Select</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let item">
                <div fxFlex fxLayout="row" fxLayoutAlign="end">
                  <mat-icon class="selected" aria-hidden="false" *ngIf="isSelected(item) | async">
                    done
                  </mat-icon>
                </div>
              </mat-cell>
            </ng-container>
          </ng-template>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns | mapToField: 'field'"></mat-header-row>
        <mat-row
          *matRowDef="let item; columns: columns | mapToField: 'field'"
          (click)="selectionChanged(item)"
        ></mat-row>
      </mat-table>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="flex-fix" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="selected">
        <span *ngIf="hasSelected$ | async">Selected Tags: {{ selectedCount$ | async }}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="end">
        <button mat-flat-button [mat-dialog-close] type="button">Cancel</button>
        <button
          mat-raised-button
          color="primary"
          [mat-dialog-close]="selected"
          [disabled]="selected.length === 0"
          (click)="assignSelected()"
          type="button"
        >
          Assign
        </button>
      </div>
    </div>
  </mat-dialog-actions>
</ng-template>

import { Injectable } from "@angular/core";
import { defaultDisplayTags, DisplayTag } from "@vp/models";

@Injectable({
  providedIn: "root"
})
export class UiDisplayTagService {
  displayTags: Record<string, string> = defaultDisplayTags;

  customizeDisplayTagsFromOrganization(organizationDisplayTags: DisplayTag[]) {
    organizationDisplayTags.forEach(displayTag => {
      this.displayTags[displayTag.tag] = displayTag.display;
    });
  }
}

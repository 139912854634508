import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute, ParamMap, QueryParamsHandling, Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { PageState } from "@vp/models";
import { combineLatest, EMPTY, Observable, Subject } from "rxjs";
import { exhaustMap, takeUntil } from "rxjs/operators";
import { DeviceAdminFilter } from "../models/device-admin-filter";
import * as DeviceAdminActions from "../state+/device-admin.actions";
import { DeviceAdminState } from "../state+/device.admin.state";

@Injectable()
export class DeviceAdminService implements OnDestroy {
  @Select(DeviceAdminState.currentFilter) currentFilter$!: Observable<DeviceAdminFilter>;
  @Select(DeviceAdminState.pageState) pageState$!: Observable<PageState>;

  private _destroyed$ = new Subject();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly store: Store
  ) {}

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  listenForSearchParameters() {
    this.activatedRoute.queryParamMap
      .pipe(
        takeUntil(this._destroyed$),
        exhaustMap((paramMap: ParamMap) => {
          const search: string | null = paramMap.get("search");
          const currentFilter = this.store.selectSnapshot(DeviceAdminState.currentFilter);
          return this.store.dispatch(
            new DeviceAdminActions.SetFilter({ ...currentFilter, search: search })
          );
        })
      )
      .subscribe();

    combineLatest([this.currentFilter$, this.pageState$])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(([filter]: [DeviceAdminFilter, PageState]) => {
        this.navigate(filter);
      });
  }

  navigate(
    searchParams: Partial<DeviceAdminFilter>,
    queryParamsHandling: QueryParamsHandling | null = "",
    skipLocationChange = false,
    replaceUrl = true
  ): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: searchParams,
      queryParamsHandling,
      skipLocationChange,
      replaceUrl
    });
  }

  listenForDeviceId() {
    this.activatedRoute.paramMap
      .pipe(
        takeUntil(this._destroyed$),
        exhaustMap((paramMap: ParamMap) => {
          const id: string | null = paramMap.get("id");
          if (id) {
            return this.store.dispatch(new DeviceAdminActions.SetSelectedDevice(id));
          }
          return EMPTY;
        })
      )
      .subscribe();
  }
}

<h1 mat-dialog-title *ngIf="data.title">
  <mat-icon color="accent">error_outline</mat-icon>
  <span>{{ data.title }}</span>
</h1>
<mat-dialog-content>
  <p>{{ data.message }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button *ngIf="data.showCancel" mat-flat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="true">
    {{ data.action ? data.action : "Confirm" }}
  </button>
</mat-dialog-actions>

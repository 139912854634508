import { InjectionToken } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import * as OrganizationActions from "@vp/data-access/organization";
import * as UserStateActions from "@vp/data-access/users";
import { Organization, OrganizationFeatures, User } from "@vp/models";
import { AccessControlService } from "@vp/shared/access-control";
import { AuthenticationService } from "@vp/shared/authentication";
import { FeatureConfig, FeatureService } from "@vp/shared/features";
import { LocalStorageService } from "@vp/shared/local-storage";
import { filterNullMap } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { SignalRService } from "@vp/shared/signal-r-service";
import { AppStoreService } from "@vp/shared/store/app";
import { UiDisplayTagService } from "@vp/shared/store/ui";
import { combineLatest } from "rxjs";
import { take, tap, withLatestFrom } from "rxjs/operators";

export const CONFIG_DEPENDENCIES = new InjectionToken<(() => unknown)[]>("CONFIG_DEPENDENCIES");

// WARNING! These DI properties need to match deps in the app.module
// IN THE EXACT ORDER

export function appInitializerFactory(
  accessControlService: AccessControlService,
  appStoreService: AppStoreService,
  authenticationService: AuthenticationService,
  _configDeps: (() => unknown)[],
  featureService: FeatureService,
  isIvyApi: boolean,
  localStorageService: LocalStorageService,
  permConst: PermissionsConstService,
  router: Router,
  signalRService: SignalRService,
  store: Store,
  uiDisplayTagService: UiDisplayTagService
): () => Promise<void> {
  /* APP_INITIALIZER requires a Promise to resolve*/
  return (): Promise<void> =>
    new Promise<boolean>(resolve => {
      authenticationService
        .checkAuth()
        .pipe(withLatestFrom(authenticationService.getAccount()), take(1))
        .subscribe(([isAuthenticated, account]) => {
          //NOT AUTHENTICATED
          if (!isAuthenticated) {
            const pathname = window.location.pathname;
            if (
              pathname !== "/" &&
              pathname !== "/home" &&
              pathname !== "/loggedout" &&
              pathname !== "/login/callback"
            ) {
              localStorageService.set({
                data: {
                  redirect: true,
                  pathname: window.location.pathname,
                  search: window.location.search
                }
              });
            }
            resolve(false);
          }
          // AUTHENTICATED
          else {
            if (account || isIvyApi) {
              accessControlService.initNgxPermissions();
              store.dispatch(new OrganizationActions.LoadOrganization());
              combineLatest([
                appStoreService.loadLoginUser().pipe(filterNullMap()),
                store.select(state => state.organization.organization).pipe(filterNullMap())
              ])
                .pipe(
                  tap(([user, organization]: [User, Organization]) => {
                    appStoreService.setOrganization(organization);
                    appStoreService.setUserRoles();
                    appStoreService.setUserDepartments(user);
                    store.dispatch(new UserStateActions.SetCurrentUser(user.userId));
                    if (
                      organization.features.some(
                        x => x.friendlyId === OrganizationFeatures.signalR && x.enabled
                      ) &&
                      !isIvyApi
                    ) {
                      signalRService.startConnection(user);
                    }
                    permConst.check(organization.permissionTags);
                    uiDisplayTagService.customizeDisplayTagsFromOrganization(
                      organization.displayTags
                    );
                    organization.features?.forEach((feature: FeatureConfig) => {
                      featureService.add(feature);
                    });
                  }),
                  take(1)
                )
                .subscribe(() => {
                  resolve(true);
                });
            }
          }
        });
    }).then(() => {
      router.initialNavigation();
    });
}

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TrackByFunction
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Select } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { TagsState } from "@vp/data-access/tags";
import {
  AssignmentModalOptions,
  Column,
  OrganizationFeatures,
  Tag,
  TagType,
  User,
  UserRole
} from "@vp/models";
import { ASSIGNMENT_MODAL_OPTIONS } from "@vp/shared/assignments/models";
import { FeatureService } from "@vp/shared/features";
import { NotificationService } from "@vp/shared/notification";
import { filterNullMap } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { TagTypePathPipe, TagWithParentsPipe } from "@vp/shared/pipes/context-display";
import { sortBy } from "lodash";
import { BehaviorSubject, combineLatest, Observable, of, Subject } from "rxjs";
import { concatMap, map, switchMap, take, takeUntil, withLatestFrom } from "rxjs/operators";
import { handleSelectedTagEvent } from "../shared-functions";
import { UserAdministrationService } from "../user-administration-state/services/user-administration.service";

@Component({
  selector: "vp-user-assign-access-tags",
  templateUrl: "./user-assign-access-tags.component.html",
  styleUrls: ["./user-assign-access-tags.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ASSIGNMENT_MODAL_OPTIONS,
      useValue: {
        columns: [
          {
            field: "tagTypeFriendlyId",
            header: "Tag Type",
            pipe: TagTypePathPipe
          },
          {
            field: "tagId",
            header: "Tag",
            pipe: TagWithParentsPipe
          },
          {
            field: "description",
            header: "Description"
          },
          {
            field: "selected",
            header: "Selected"
          }
        ],
        title: "Assign Access Tags",
        config: {
          disableClose: true,
          autoFocus: false,
          closeOnNavigation: true,
          width: "60vw"
        }
      }
    },
    TagTypePathPipe,
    TagWithParentsPipe
  ]
})
export class UserAssignAccessTagsComponent implements OnInit, OnDestroy {
  @Select(OrganizationState.tagTypes) tagTypes$!: Observable<TagType[]>;
  @Select(TagsState.tags) tags$!: Observable<Tag[]>;

  excludeProperties: string[] = [];
  roleSelector = new FormControl();
  selectedRoleId: string | null = null;

  hasSelected$: Observable<boolean>;
  search$: Observable<string | null>;
  selectedCount$: Observable<number>;
  selectedTags$: Observable<Tag[]>;
  tagTypesFilter$: Observable<string[] | null>;

  private _selectedTags$ = new BehaviorSubject<Tag[]>([]);

  private readonly _destroyed$ = new Subject();
  private readonly _displayedColumns$ = new BehaviorSubject<Column[]>([]);
  private readonly _search$ = new BehaviorSubject<string | null>(null);
  private readonly _tagTypesFilter$ = new BehaviorSubject<string[]>([]);

  constructor(
    @Inject(ASSIGNMENT_MODAL_OPTIONS) public assignmentModalOptions: AssignmentModalOptions,
    public permConst: PermissionsConstService,
    private featureService: FeatureService,
    private notificationService: NotificationService,
    private userAdministrationService: UserAdministrationService
  ) {
    this.search$ = this._search$.asObservable();
    this.selectedTags$ = this._selectedTags$.asObservable();
    this.tagTypesFilter$ = this._tagTypesFilter$.asObservable();

    this.selectedCount$ = this.selectedTags$.pipe(map(tags => tags.length));
    this.hasSelected$ = this.selectedTags$.pipe(map(tags => tags.length > 0));
  }

  assignableTagTypes$: Observable<TagType[]> = this.featureService
    .configurationLists$(OrganizationFeatures.accessTags)
    .pipe(
      filterNullMap(),
      map((list: Record<string, string[]>) => {
        const value = list["tagTypes"];
        if (Array.isArray(value)) {
          return list["tagTypes"];
        }
        return [];
      }),
      withLatestFrom(this.tagTypes$),
      map(([allowedTagTypes, tagTypes]: [string[], TagType[]]) => {
        return tagTypes.filter(t => allowedTagTypes.includes(t.friendlyId));
      })
    );

  assignedRoles$ = this.userAdministrationService.workingCopy$.pipe(
    filterNullMap(),
    map((user: User) => user.roles),
    takeUntil(this._destroyed$)
  );

  displayedColumns$ = this._displayedColumns$.asObservable();

  assignableTags$ = combineLatest([
    this.tags$,
    this.assignableTagTypes$,
    this._tagTypesFilter$
  ]).pipe(
    map(([tags, assignableTagTypes, tagTypesFilter]: [Tag[], TagType[], string[]]) => {
      const assignableTagTypeFriendlyIds: string[] = assignableTagTypes.map(t => t.friendlyId);
      let assignableTags: Tag[] = tags.filter(tag =>
        assignableTagTypeFriendlyIds.includes(tag.tagTypeFriendlyId)
      );
      if (tagTypesFilter.length > 0)
        assignableTags = assignableTags.filter(t => tagTypesFilter.includes(t.tagTypeFriendlyId));
      return sortBy(assignableTags, ["tagTypeFriendlyId", "displayName"]);
    })
  );

  ngOnInit(): void {
    this._displayedColumns$.next(this.assignmentModalOptions.columns);
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  get selected() {
    return this._selectedTags$.getValue();
  }

  roleSelectionChanged($event: UserRole) {
    this.selectedRoleId = $event.roleId;
  }

  tagTypeFilterChanged($event: TagType[]) {
    this._tagTypesFilter$.next($event.map((tagType: TagType) => tagType.friendlyId));
  }

  searchFilterChanged(event: string) {
    this._search$.next(event);
  }

  selectionChanged(tag: Tag) {
    this._selectedTags$
      .pipe(
        withLatestFrom(this.assignableTagTypes$),
        concatMap(([selected, tagTypes]: [Tag[], TagType[]]) => {
          handleSelectedTagEvent(tag, tagTypes, selected);
          return of(selected);
        }),
        take(1)
      )
      .subscribe(selected => {
        this._selectedTags$.next(selected);
      });
  }

  isSelected(tag: Tag): Observable<boolean> {
    return this.selectedTags$.pipe(
      map((selected: Tag[]) => selected?.findIndex(i => i.tagId === tag.tagId) > -1 ?? false)
    );
  }

  assignSelected() {
    if (this.selectedRoleId !== null) {
      this._selectedTags$
        .pipe(
          switchMap((tags: Tag[]) =>
            this.userAdministrationService.addAccessTags$(
              this.selectedRoleId as string,
              tags.map(t => t.tagId)
            )
          )
        )
        .subscribe();
    } else {
      this.notificationService.warningMessage("A role must be selected to assign access tags.");
    }
  }

  trackById: TrackByFunction<Tag> = (_: number, tag: Tag) => tag.tagId;
}

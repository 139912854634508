import { Injectable } from "@angular/core";
import { Action, Select, Selector, State, StateContext } from "@ngxs/store";
import { UserApiService } from "@vp/data-access/users";
import {
  AssignableGroupTypes,
  AssignableTagTypes,
  Department,
  Group,
  GroupRef,
  GroupType,
  LayoutConfigOption,
  Organization,
  Tag,
  TagType,
  User,
  UserTypeConfig
} from "@vp/models";
import { JSONSchema7 } from "json-schema";
import {
  defaultUserAdministrationState,
  UserAdmininstrationStateModel
} from "../models/user-administration-state.model";

import { GroupsState } from "@vp/data-access/groups";
import { OrganizationState } from "@vp/data-access/organization";
import { TagsState } from "@vp/data-access/tags";
import { UiSchemaConfigService, UiSchemaLayoutProvider } from "@vp/formly/ui-schema-config";
import { filterNullMap } from "@vp/shared/operators";
import { deeperCopy } from "@vp/shared/utilities";
import { combineLatest, EMPTY, Observable, of } from "rxjs";
import { concatMap, first, map, switchMap, take, tap } from "rxjs/operators";
import { UserOperations } from "../models/user-operations.model";
import * as UserAdministrationActions from "./user-administration.actions";

@State<UserAdmininstrationStateModel>({
  name: "userAdministration",
  defaults: defaultUserAdministrationState()
})
@Injectable()
export class UserAdmininstrationState {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;
  @Select(GroupsState.allGroups) allGroups$!: Observable<Group[]>;
  @Select(TagsState.tags) allTags$!: Observable<Tag[]>;

  constructor(
    private readonly uiSchemaConfigService: UiSchemaConfigService,
    private readonly uiSchemaLayoutProvider: UiSchemaLayoutProvider,
    private readonly userApiService: UserApiService
  ) {}

  @Selector()
  static user(state: UserAdmininstrationStateModel): User | null {
    return state.user;
  }

  @Selector()
  static workingCopy(state: UserAdmininstrationStateModel): User | null {
    return state.workingCopy;
  }

  @Selector()
  static assignableGroupTypes(state: UserAdmininstrationStateModel): GroupType[] {
    return state.assignableGroupTypes;
  }

  @Selector()
  static assignableGroups(state: UserAdmininstrationStateModel): Group[] {
    return state.assignableGroups;
  }

  @Selector()
  static assignableTagTypes(state: UserAdmininstrationStateModel): TagType[] {
    return state.assignableTagTypes;
  }

  @Selector()
  static assignableTags(state: UserAdmininstrationStateModel): Tag[] {
    return state.assignableTags;
  }

  @Selector()
  static pendingOperations(state: UserAdmininstrationStateModel): UserOperations | null {
    return state.pendingOperations;
  }

  @Selector()
  static layoutSchema(state: UserAdmininstrationStateModel): JSONSchema7 | null {
    return state.layoutSchema;
  }

  @Action(UserAdministrationActions.LoadUser)
  loadUser(
    ctx: StateContext<UserAdmininstrationStateModel>,
    action: UserAdministrationActions.LoadUser
  ) {
    return this.updateState$(action.user, action.active, ctx);
  }

  private updateState$ = (
    user: Partial<User>,
    active: boolean = true,
    ctx: StateContext<UserAdmininstrationStateModel>
  ) => {
    return this.organization$.pipe(
      concatMap((org: Organization) => {
        const copy: User = deeperCopy(user);
        copy.active = active;
        const assignableGroupTypes: GroupType[] = getAssignableGroupTypes(copy, org);
        const assignableTagTypes: TagType[] = getAssignableTagTypes(copy, org);
        return combineLatest([
          of(copy),
          of(assignableGroupTypes),
          this.getAssignableGroups$(copy, assignableGroupTypes),
          of(assignableTagTypes),
          this.getAssignableTags$(copy, assignableTagTypes),
          this.userLayoutSchema$(copy, org)
        ]);
      }),
      tap(
        ([
          user,
          assignableGroupTypes,
          assignableGroups,
          assignableTagTypes,
          assignableTags,
          layoutSchema
        ]: [User, GroupType[], Group[], TagType[], Tag[], JSONSchema7]) => {
          const state: UserAdmininstrationStateModel = {
            user: user,
            workingCopy: user,
            pendingOperations: null,
            assignableGroupTypes: assignableGroupTypes,
            assignableGroups: assignableGroups,
            assignableTagTypes: assignableTagTypes,
            assignableTags: assignableTags,
            layoutSchema: layoutSchema
          } as UserAdmininstrationStateModel;
          return ctx.patchState(state);
        }
      ),
      first()
    );
  };

  private userLayoutSchema$ = (user: User, org: Organization): Observable<JSONSchema7> => {
    const userType: string = user.userType.friendlyId;
    const userTypeConfig: UserTypeConfig | undefined = org.userTypeConfig.find(
      c => c.type == userType
    );
    if (userTypeConfig === undefined) return EMPTY;
    const layoutConfig: LayoutConfigOption = userTypeConfig.userLayout;
    this.uiSchemaConfigService.addScopedConfig(layoutConfig, `user-admininstration-${userType}`);
    const schema: JSONSchema7 = userTypeConfig.userSchema;
    return this.uiSchemaLayoutProvider.applyScopes(
      "userAdminComponent",
      schema,
      `user-admininstration-${userType}`
    );
  };

  private getAssignableGroups$ = (
    user: User,
    assignableGroupTypes: GroupType[]
  ): Observable<Group[]> => {
    return this.allGroups$.pipe(
      concatMap((groups: Group[]) => {
        const uniqueAssigmentGroupTypes: string[] = assignableGroupTypes
          .filter((groupType: GroupType) => groupType.uniqueAssignment === true)
          .map((groupType: GroupType) => groupType.groupTypeId);
        const userGroups: string[] = user.groups.map((groupRef: GroupRef) => groupRef.groupId);
        const groupsToCheck: Group[] = groups.filter(
          (group: Group) =>
            assignableGroupTypes.map(t => t.groupTypeId).includes(group.groupTypeId) &&
            uniqueAssigmentGroupTypes.includes(group.groupTypeId) &&
            !userGroups.includes(group.groupId)
        );
        const groupIdsToCheck: string[] = groupsToCheck.map(g => g.groupId);
        return combineLatest([
          of(groups),
          this.userApiService.getGroupsAssignedToUsers(groupIdsToCheck)
        ]);
      }),
      map(([groups, assignedGroupIds]: [Group[], string[]]) => {
        const assignableGroups = groups.filter(
          (group: Group) =>
            assignableGroupTypes.map(t => t.groupTypeId).includes(group.groupTypeId) &&
            assignedGroupIds.includes(group.groupId) === false
        );
        return assignableGroups;
      }),
      take(1)
    );
  };

  private getAssignableTags$ = (user: User, assignableTagTypes: TagType[]): Observable<Tag[]> => {
    return this.allTags$.pipe(
      concatMap((tags: Tag[]) => {
        const uniqueAssigmentTagTypes: string[] = assignableTagTypes
          .filter(t => t.uniqueAssignment === true)
          .map(t => t.tagTypeId);

        const tagsToCheck: Tag[] = tags.filter(
          (tag: Tag) =>
            assignableTagTypes.map(t => t.tagTypeId).includes(tag.tagTypeId) &&
            uniqueAssigmentTagTypes.includes(tag.tagTypeId) &&
            !user.assignedTags.includes(tag.tagId)
        );
        const tagIdsToCheck: string[] = tagsToCheck.map(t => t.tagId);
        return combineLatest([of(tags), this.userApiService.getTagsAssignedToUsers(tagIdsToCheck)]);
      }),
      map(([tags, assignedTagIds]: [Tag[], string[]]) => {
        const assignableTags = tags.filter(
          t =>
            assignableTagTypes.map(t => t.tagTypeId).includes(t.tagTypeId) &&
            assignedTagIds.includes(t.tagId) === false
        );
        return assignableTags;
      }),
      take(1)
    );
  };

  @Action(UserAdministrationActions.SetUser)
  setUser(
    ctx: StateContext<UserAdmininstrationStateModel>,
    action: UserAdministrationActions.SetUser
  ) {
    return this.userApiService.getUser(action.id).pipe(
      filterNullMap(),
      switchMap((user: User) => this.updateState$(user, user.active, ctx))
    );
  }

  @Action(UserAdministrationActions.SetWorkingCopy)
  setWorkingCopy(
    ctx: StateContext<UserAdmininstrationStateModel>,
    action: UserAdministrationActions.SetWorkingCopy
  ) {
    return this.organization$.pipe(
      concatMap((org: Organization) => {
        const assignableGroupTypes: GroupType[] = getAssignableGroupTypes(action.user, org);
        const assignableTagTypes: TagType[] = getAssignableTagTypes(action.user, org);
        return combineLatest([
          of(assignableGroupTypes),
          this.getAssignableGroups$(action.user, assignableGroupTypes),
          of(assignableTagTypes),
          this.getAssignableTags$(action.user, assignableTagTypes)
        ]);
      }),
      map(
        ([assignableGroupTypes, assignableGroups, assignableTagTypes, assignableTags]: [
          GroupType[],
          Group[],
          TagType[],
          Tag[]
        ]) => {
          const state = ctx.getState();
          action.user.groups = action.user.groups.filter(group =>
            assignableGroups.map(g => g.groupId).includes(group.groupId)
          );
          action.user.assignedTags = action.user.assignedTags.filter(tagId =>
            assignableTags.map(t => t.tagId).includes(tagId)
          );
          const updatedState: UserAdmininstrationStateModel = {
            ...state,
            workingCopy: action.user,
            assignableGroupTypes: assignableGroupTypes,
            assignableGroups: assignableGroups,
            assignableTagTypes: assignableTagTypes,
            assignableTags: assignableTags
          } as UserAdmininstrationStateModel;
          return ctx.patchState(updatedState);
        }
      ),
      take(1)
    );
  }

  @Action(UserAdministrationActions.SetPendingOperations)
  setPendingOperations(
    ctx: StateContext<UserAdmininstrationStateModel>,
    action: UserAdministrationActions.SetPendingOperations
  ) {
    const state = ctx.getState();
    state.pendingOperations = action.userOperations;
    ctx.patchState(state);
  }
}

const getAssignableGroupTypes = (user: User, org: Organization): GroupType[] => {
  const userDepartmentIds: Set<string> = new Set<string>();
  user.roles.forEach(r => r.departments.forEach(d => userDepartmentIds.add(d.departmentId)));
  const userDepartments: Department[] = org.departments.filter(d =>
    userDepartmentIds.has(d.departmentId)
  );
  const assignableGroupTypeFriendlyIds: Set<string> = new Set<string>();
  userDepartments.forEach((dept: Department) =>
    dept.assignableGroupTypes
      .filter(
        (assignableGroupTypes: AssignableGroupTypes) =>
          assignableGroupTypes.userType === user.userType.friendlyId
      )
      .forEach((assignableGroupTypes: AssignableGroupTypes) =>
        assignableGroupTypes.groupTypes?.forEach((groupTypeFriendlyId: string) =>
          assignableGroupTypeFriendlyIds.add(groupTypeFriendlyId)
        )
      )
  );

  return org.groupTypes.filter((groupType: GroupType) =>
    assignableGroupTypeFriendlyIds.has(groupType.friendlyId)
  );
};

const getAssignableTagTypes = (user: User, org: Organization): TagType[] => {
  const userDepartmentIds: Set<string> = new Set<string>();
  user.roles.forEach(r => r.departments.forEach(d => userDepartmentIds.add(d.departmentId)));
  const userDepartments: Department[] = org.departments.filter(d =>
    userDepartmentIds.has(d.departmentId)
  );
  const assignableTagTypeFriendlyIds: Set<string> = new Set<string>();
  userDepartments.forEach(d =>
    d.assignableTagTypes
      .filter(
        (assignableTagTypes: AssignableTagTypes) =>
          assignableTagTypes.userType === user.userType.friendlyId
      )
      .forEach((assignableTagTypes: AssignableTagTypes) =>
        assignableTagTypes.tagTypes?.forEach((tagTypeFriendlyId: string) =>
          assignableTagTypeFriendlyIds.add(tagTypeFriendlyId)
        )
      )
  );

  return org.tagTypes.filter((tagType: TagType) =>
    assignableTagTypeFriendlyIds.has(tagType.friendlyId)
  );
};

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTableModule } from "@angular/material/table";
import { AddOrEditSnippetDialogComponent } from "./add-or-edit-snippet-dialog/add-or-edit-snippet-dialog.component";
import { UserSnippetsComponent } from "./user-snippets.component";

@NgModule({
  declarations: [AddOrEditSnippetDialogComponent, UserSnippetsComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    MatDialogModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [AddOrEditSnippetDialogComponent, UserSnippetsComponent]
})
export class UserSnippetsModule {}

import { Tag, TagType, User } from "@vp/models";

export const handleSelectedTagEvent = (tag: Tag, tagTypes: TagType[], selected: Tag[]) => {
  const alreadySelected: boolean = selected.includes(tag);
  if (!alreadySelected) {
    const tagType: TagType | undefined = tagTypes.find(t => t.tagTypeId === tag.tagTypeId);
    if (tagType?.singleAssignment && selected.length >= 1) {
      for (let index = selected.length - 1; index >= 0; index--) {
        const tag: Tag = selected[index];
        if (tag.tagTypeId === tagType.tagTypeId) selected.splice(index, 1);
      }
    }
    selected.push(tag);
  } else {
    const index = selected.indexOf(tag);
    selected.splice(index, 1);
  }
};

export const getTagsToRemove = (
  selected: Tag[],
  tagTypes: TagType[],
  tags: Tag[],
  user: User
): string[] => {
  const tagsToRemove: string[] = [];
  if (selected.length === 0) return tagsToRemove;
  const assignedTags: Tag[] = [];
  user.assignedTags?.forEach(tagId => {
    const tag: Tag | undefined = tags.find(tag => tag.tagId === tagId);
    if (tag) assignedTags.push(tag);
  });
  if (assignedTags.length === 0) return tagsToRemove;
  selected.forEach((tag: Tag) => {
    const tagType: TagType | undefined = tagTypes.find(t => t.tagTypeId === tag.tagTypeId);
    if (tagType?.singleAssignment) {
      const tag: Tag | undefined = assignedTags.find(t => t.tagTypeId == tagType.tagTypeId);
      if (tag) tagsToRemove.push(tag.tagId);
    }
  });
  return tagsToRemove;
};

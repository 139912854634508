import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { CaseStatusNavDetailModule } from "@vp/case-search/ui/case-status-nav-detail";
import { CommunicationCountsNavModule } from "@vp/case-search/ui/communication-counts-nav";
import { ContentNavListModule } from "@vp/content-management/ui/content-nav-list";
import { MemoizePipeModule } from "@vp/shared/pipes/memoize";
import { SpinnerModule } from "@vp/shared/spinner";
import { NgxPermissionsModule } from "ngx-permissions";
import { ContentManagementLeftMenuComponent } from "./content-management-left-menu.component";

@NgModule({
  imports: [
    CommonModule,
    CommunicationCountsNavModule,
    FlexLayoutModule,
    MatIconModule,
    SpinnerModule,
    NgxPermissionsModule.forChild(),
    MemoizePipeModule,
    CaseStatusNavDetailModule,
    MatChipsModule,
    ContentNavListModule
  ],
  declarations: [ContentManagementLeftMenuComponent],
  exports: [ContentManagementLeftMenuComponent]
})
export class ContentManagementLeftMenuModule {}

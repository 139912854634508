import { CaseTypeStatus, Department, OrganizationStatus } from ".";

export const CASETYPE_STATUS_CATEGORY__ALL: CaseTypeStatus = {
  friendlyId: "all",
  statusId: "",
  displayName: "All",
  initial: true
};

export const DEPARTMENT_CATEGORY__ALL: Partial<Department> = {
  friendlyId: "all",
  displayName: "All",
  isDefault: true,
  departmentId: "",
  description: "",
  roles: []
};

export const ORGANIZATION_STATUS_CATEGORY__ALL: OrganizationStatus = {
  friendlyId: "all",
  displayName: "All",
  statusId: ""
};

export const FILTER_TYPE_LABEL = {
  search: "Search",
  status: "Status",
  department: "Department",
  caseType: "Opinion Type",
  unresolved: "Communication",
  unread: "Communication"
};

export const FILTER_TYPE_VALUE = {
  search: "search",
  status: "status",
  department: "department",
  caseType: "caseType",
  unresolved: "unresolved",
  unread: "unread"
};

export const DEFAULT_PAGER_LIST = [5, 10, 25, 100];

export const DEFAULT_PAGER_SKIP = 0;

export const DEFAULT_PAGER_TAKE = 10;

export const DEFAULT_CASES_QUERY_PARAMS = {
  take: DEFAULT_PAGER_TAKE,
  skip: DEFAULT_PAGER_SKIP,
  status: ORGANIZATION_STATUS_CATEGORY__ALL.friendlyId,
  dept: DEPARTMENT_CATEGORY__ALL.friendlyId,
  casetype: CASETYPE_STATUS_CATEGORY__ALL.friendlyId,
  search: null
};

export interface CasesQueryParams {
  take: number | null;
  skip: number | null;
  status: string | null;
  dept: string | null;
  casetype: string | string;
  search: string | null;
  sort: string | null;
  sortDirection: string | null;
  communicationUnread: true | null;
  communicationUnresolved: true | null;
}

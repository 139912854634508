<mat-form-field>
  <mat-label>{{ uiDisplayTagService.displayTags.departmentFilter }}</mat-label>
  <mat-select [value]="selectedDeptId" (selectionChange)="onChanged($event)">
    <mat-option value="all">All</mat-option>
    <mat-option
      *ngFor="let dept of userDepartments$ | async; trackBy: trackByDepartment"
      [value]="dept.departmentId"
    >
      {{ dept.displayName }}
    </mat-option>
  </mat-select>
</mat-form-field>

import { Injectable, OnDestroy } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { combineLatest, Observable, Subject } from "rxjs";
import { ContentDataFilter } from "../models/content-data-filter";
import * as ContentFilterState from "../state+/content-filter.state";
import * as ContentFilterStateActions from "../state+/content-filter-state.actions";
import { PageState } from "@vp/models";
import { ActivatedRoute, ParamMap, QueryParamsHandling, Router } from "@angular/router";
import { filterNullMap } from "@vp/shared/operators";
import { exhaustMap, map, takeUntil } from "rxjs/operators";

@Injectable()
export class ContentFilterStateActionsService implements OnDestroy {
  @Select(ContentFilterState.ContentFilterState.currentFilter)
  currentFilter$!: Observable<ContentDataFilter>;
  @Select(ContentFilterState.ContentFilterState.pageState) pageState$!: Observable<PageState>;

  private destroy = new Subject();

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store
  ) {}

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  listen() {
    this.activatedRoute.queryParamMap
      .pipe(
        map((paramMap: ParamMap) => paramMap.get("search")),
        filterNullMap(),
        exhaustMap((search: string) => {
          const currentFilter = this.store.selectSnapshot(
            ContentFilterState.ContentFilterState.currentFilter
          );
          return this.store.dispatch(
            new ContentFilterStateActions.SetFilter({
              ...currentFilter,
              search: search
            })
          );
        })
      )
      .subscribe();

    combineLatest([this.currentFilter$, this.pageState$])
      .pipe(takeUntil(this.destroy))
      .subscribe(([filter]: [ContentDataFilter, PageState]) => {
        this.navigate(filter);
      });
  }

  navigate(
    searchParams: Partial<ContentDataFilter>,
    queryParamsHandling: QueryParamsHandling | null = "",
    skipLocationChange = false,
    replaceUrl = true
  ): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: searchParams,
      queryParamsHandling,
      skipLocationChange,
      replaceUrl
    });
  }
}

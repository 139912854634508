import { animate, state, style, transition, trigger } from "@angular/animations";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Snippet } from "@vp/models";
import { SharedConfirmationService } from "@vp/shared/confirmation";
import { NotificationService } from "@vp/shared/notification";
import { EMPTY, Observable } from "rxjs";
import { concatMap, map } from "rxjs/operators";
import { UserAdministrationService } from "../user-administration-state/services/user-administration.service";
import { AddOrEditSnippetDialogComponent } from "./add-or-edit-snippet-dialog/add-or-edit-snippet-dialog.component";

@Component({
  selector: "vp-user-snippets",
  templateUrl: "./user-snippets.component.html",
  styleUrls: ["./user-snippets.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
    ])
  ]
})
export class UserSnippetsComponent {
  snippets: Observable<Snippet[]> = this.userAdministrationService.workingCopy$.pipe(
    map(user => user?.userData?.snippets ?? [])
  );

  expandedRow: Snippet | null = null;

  constructor(
    private readonly dialog: MatDialog,
    private notificationService: NotificationService,
    private readonly confirmationDialog: SharedConfirmationService,
    private readonly userAdministrationService: UserAdministrationService
  ) {}

  addOrEditSnippet(action: "Add" | "Edit", snippet?: Snippet, index?: number) {
    const dialogRef = this.dialog.open(AddOrEditSnippetDialogComponent, {
      width: "500px",
      data: { action: action, snippet: snippet }
    });

    dialogRef
      .afterClosed()
      .pipe(
        concatMap((dialogResult: Snippet) => {
          if (dialogResult) {
            return this.userAdministrationService.addOrEditSnippet$(action, dialogResult, index);
          }
          return EMPTY;
        }),
        concatMap(() => this.userAdministrationService.updateUser())
      )
      .subscribe(() => {
        this.notificationService.successMessage(
          `Snippet ${action === "Add" ? "added" : "updated"}`
        );
      });
  }

  deleteSnippet(index: number) {
    this.confirmationDialog
      .open("Delete this template?")
      .afterConfirmed()
      .pipe(
        concatMap((confirmed: boolean) => {
          if (confirmed) {
            return this.userAdministrationService.deleteSnippet$(index);
          }
          return EMPTY;
        }),
        concatMap(() => this.userAdministrationService.updateUser())
      )
      .subscribe({
        next: () => this.notificationService.successMessage("Successfully deleted"),
        error: () => this.notificationService.warningMessage("Failed to delete")
      });
  }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ContentNavListDetailModule } from "@vp/content-management/ui/content-nav-list-detail";
import { MemoizePipeModule } from "@vp/shared/pipes/memoize";
import { ContentNavListComponent } from "./content-nav-list.component";

@NgModule({
  imports: [CommonModule, FlexLayoutModule, ContentNavListDetailModule, MemoizePipeModule],
  declarations: [ContentNavListComponent],
  exports: [ContentNavListComponent]
})
export class ContentNavListModule {}

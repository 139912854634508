import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { ContentManagementRoutingModule } from "./content-management-routing.module";
import { ContentManagementComponent } from "./content-management.component";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ContentManagementDashboardModule } from "@vp/content-management/ui/content-management-dashboard";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ContentManagementLeftMenuModule } from "@vp/content-management/ui/content-management-left-menu";
import { ContentFilterState, ContentModule } from "@vp/content-management/data-access/content";
import { NgxsModule } from "@ngxs/store";
import { NewContentPageModule } from "@vp/content-management/ui/new-content-page";
import { ContentNavListModule } from "@vp/content-management/ui/content-nav-list";
import { ContentNavListDetailModule } from "@vp/content-management/ui/content-nav-list-detail";
import { ViewContentPageModule } from "@vp/content-management/ui/view-content-page";
import { RouterModule } from "@angular/router";

@NgModule({
  imports: [
    CommonModule,
    ContentManagementRoutingModule,
    ContentManagementLeftMenuModule,
    ContentManagementDashboardModule,
    NewContentPageModule,
    ContentNavListModule,
    ContentNavListDetailModule,
    ViewContentPageModule,
    CommonModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatIconModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSidenavModule,
    MatInputModule,
    ContentModule,
    RouterModule,
    NgxsModule.forFeature([ContentFilterState])
  ],
  declarations: [ContentManagementComponent],
  exports: [ContentManagementComponent]
})
export class ContentManagementModule {}

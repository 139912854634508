import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatTableModule } from "@angular/material/table";
import { UtilitiesModule } from "@vp/shared/utilities";
import { NgxPermissionsModule } from "ngx-permissions";
import { UserAssignAccessTagsModule } from "../user-assign-access-tags/user-assign-access-tags.module";
import { UserAssignedAccessTagsComponent } from "./user-assigned-access-tags.component";

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatTableModule,
    NgxPermissionsModule,
    UserAssignAccessTagsModule,
    UtilitiesModule
  ],
  declarations: [UserAssignedAccessTagsComponent],
  exports: [UserAssignedAccessTagsComponent]
})
export class UserAssignedAccessTagsModule {}

<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    counter: (counter$ | async)
  }"
>
</ng-container>

<ng-template
  #template
  let-counter="counter"
>
<div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">{{ countentTypeCount?.displayName }}</div>
    <mat-chip-list class="notification-area" fxLayout="row" fxLayoutAlign="end center">
    </mat-chip-list>
  </div>
</ng-template>

<div class="menu-section">
  <div fxLayout="column" fxLayoutAlign="start stretch">
    <div
      class="menu-item notification-badge"
      [class.active]="(vmSelectedItem$ | async) === 'unresolved'"
      (click)="onUnresolvedClicked()"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="row" fxLayoutAlign="start center">Action Required</div>
      <mat-chip-list class="notification-area" [selectable]="false" fxLayoutAlign="end center">
        <mat-chip [disableRipple]="true">{{ vmUnresolvedCommuncations$ | async }}</mat-chip>
      </mat-chip-list>
    </div>
    <div
      class="menu-item notification-badge"
      [class.active]="(vmSelectedItem$ | async) === 'unread'"
      (click)="onUnreadClicked()"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="row" fxLayoutAlign="start center">Unread</div>
      <mat-chip-list class="notification-area" [selectable]="false" fxLayoutAlign="end center">
        <mat-chip [disableRipple]="true">{{ vmUnreadCommunications$ | async }}</mat-chip>
      </mat-chip-list>
    </div>
  </div>
</div>

<ng-container
[ngTemplateOutlet]="template"
[ngTemplateOutletContext]="{
contentTypes: (contentTypes$ | async) || [],
pageState: pageState$ | async,
pageParams: pageParams$ | async,
results: results$ | async,
hasResult: (hasResult$ | async) || false
}"
>
<ng-template
  #template
  let-contentTypes="contentTypes"
  let-pageState="pageState"
  let-pageParams="pageParams"
  let-results="results"
  let-hasResult="hasResult"
>
  <!---TOP ROW  -->
  <div fxLayoutAlign="space-between none" class="horizontal-padding vertical-padding">
    <vp-breadcrumb></vp-breadcrumb>
    <vp-page-menu></vp-page-menu>
  </div>

  <!---FILTERS AND PAGE CONTROL -->
  <div
        class="content-search-actions"
        fxLayoutGap="1rem"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="space-between start"
        fxLayoutAlign.lt-md="start start"
        *ngIf="true"
        fxHide.lt-sm="true"
        fxShow="true"
        class="horizontal-padding"
      >
          <!---FILTER TOOLBAR -->
    <vp-filter-toolbar
    class="horizontal-padding"
    fxHide.xs="true"
    fxHide.sm="true"
    fxShow.gt-sm="true"
    fxShow="true"
    [items]="
      getFilteredToolbarItems
        | memoize
          : pageParams?.search
          : pageParams?.contentTypeId
          : contentTypes
    "
    (itemRemoved)="filterItemRemovedHandler($event)"
    (filterCleared)="filterClearedHandler()"
  >
  </vp-filter-toolbar>
              <!--PAGE CONTROL-->
              <div
              fxLayout="row"
              fxLayout.lt-md="column"
              fxLayoutAlign="space-between start"
              fxLayoutAlign.lt-md="start start"
            >
              <mat-paginator
              [length]="pageState?.totalRecords"
              [pageSize]="pageParams?.take"
              [pageIndex]="pageParams?.pageIndex"
              [pageSizeOptions]="pageParams?.pageSizeOptions ?? []"
              showFirstLastButtons
              (page)="handlePageEvent($event)"
              >
              </mat-paginator>
            </div>
  </div>

  <div class="mat-elevation-z1" >
    <table
      [style.width.%]="100"
      mat-table
      matSort
      [dataSource]="results || []"
    >
      <ng-container matColumnDef="contentName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Content Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element.displayName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="tags">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tags</th>
        <td mat-cell *matCellDef="let element">{{ element.tags }}</td>
      </ng-container>
      <ng-container matColumnDef="contentType">
        <th mat-header-cell *matHeaderCellDef>Content Type</th>
        <td mat-cell *matCellDef="let element">{{ element.contentTypeName }}</td>
      </ng-container>
      <ng-container matColumnDef="actions" >
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element" fxLayoutGap=".33rem" >

            <mat-icon
            class="mat-icon-action"
            aria-hidden="false"
            aria-label="edit icon"
            matTooltipPosition="left"
            matTooltip="Edit"
            routerLink="/content/new-content/"
            [queryParams]="{ contentId: element.contentId}"
            >edit</mat-icon>

            <mat-icon
            class="mat-icon-action"
            aria-hidden="false"
            aria-label="view icon"
            matTooltipPosition="left"
            matTooltip="View"
            (click)="viewResponse(element.contentId)"
            >
            remove_red_eye</mat-icon>

        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td *ngIf="{hasResult}" class="mat-cell" colspan="4">No data available.</td>
      </tr>
    </table>
  </div>
</ng-template>
</ng-container>

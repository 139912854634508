import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { Store } from "@ngxs/store";
import * as CaseActions from "@vp/data-access/case";
import {
  AssignUserEvent,
  CallLightActivatedEvent,
  CallLightDeactivatedEvent,
  CaseChatEvent,
  CaseDataChangedEvent,
  CaseStatusChanged,
  CaseStatusChangedEvent,
  CaseUpdatedEvent,
  DeviceCamerasUpdatedEvent,
  DeviceConnectionChangedEvent,
  DeviceMicrophonesUpdatedEvent,
  DeviceNetworkInterfacesUpdatedEvent,
  DevicePowerStatusUpdatedEvent,
  DeviceSpeakersUpdatedEvent,
  DeviceStethoscopeUpdatedEvent,
  MessageToPatientEvent,
  MovementInRoomDetected,
  RealTimeNotification,
  SignalRConnected,
  SignalrMethods,
  User,
  ZoomWebhookEvent
} from "@vp/models";
import { AuthenticationService } from "@vp/shared/authentication";
import { EventAggregator } from "@vp/shared/event-aggregator";
import { IS_IVY_API } from "@vp/shared/guards";
import { Logger } from "@vp/shared/logging-service";
import { AppStoreService } from "@vp/shared/store/app";
import { API_BASE_URL } from "@vp/shared/tokens";
import { BehaviorSubject, EMPTY, Observable } from "rxjs";
import { concatMap, take } from "rxjs/operators";

export interface SignalRConnectionInfo {
  url: string;
  accessToken: string;
}

export interface SignalREvent {
  method: string;
  data: any;
  eventTime: Date;
}

export interface SignalRHubConnection {
  state: string | null;
  lastUpdated: Date | null;
  connectionId: string | null;
  receivedEvents: SignalREvent[];
}

@Injectable({
  providedIn: "root"
})
export class SignalRService {
  private hubConnection!: HubConnection;
  private hubConnection$: BehaviorSubject<SignalRHubConnection> =
    new BehaviorSubject<SignalRHubConnection>({
      state: null,
      lastUpdated: null,
      connectionId: null,
      receivedEvents: []
    } as SignalRHubConnection);
  private addedToGroups: RealTimeNotification[] = [];

  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    @Inject(IS_IVY_API) private readonly isIvyApi: boolean,
    private authenticationService: AuthenticationService,
    private readonly _http: HttpClient,
    private readonly appStoreService: AppStoreService,
    private readonly eventAggregrator: EventAggregator,
    private readonly logger: Logger,
    private readonly store: Store
  ) {}

  get getHubConnection$(): Observable<SignalRHubConnection> {
    return this.hubConnection$.asObservable();
  }

  public startConnection = (user: User): void => {
    this.authenticationService
      .isLoggedIn$()
      .pipe(
        take(1),
        concatMap(isAuthenticated => {
          if (isAuthenticated) {
            return this.getSignalRConnection(user.userId);
          }
          return EMPTY;
        })
      )
      .subscribe(signalrConnection => {
        //signalRConnection contains the URL to the Azure SignalR instance
        //and the accessToken granting the user access
        const options = {
          accessTokenFactory: () => signalrConnection.accessToken
        };

        this.hubConnection = new HubConnectionBuilder()
          .withUrl(signalrConnection.url, options)
          .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: retryContext => {
              const tokenExpired = this.tokenExpired(signalrConnection.accessToken);
              // stop automatic reconnect if token already expired
              if (!tokenExpired) {
                if (retryContext.previousRetryCount < 50) {
                  return 1000;
                } else if (retryContext.previousRetryCount < 250) {
                  return 10000;
                }
                return null;
              }
              return null;
            }
          })
          .configureLogging(LogLevel.Warning)
          .build();

        this.registerConnectionEvents();
        this.registerClientMethods();

        this.start();
      });
  };

  private registerConnectionEvents = (): void => {
    this.hubConnection.onclose(connection => {
      this.logger.logEvent(
        `SignalR Connection Closed: ${connection?.message}. Stack: ${connection?.stack}`
      );
      this.updateConnectionState();

      //the signalR connection has been closed. We need to start a new connection in order to reconnect.
      if (this.appStoreService.user) {
        //ensure the previous connection has been closed before starting a new one.
        if (this.hubConnection) {
          this.hubConnection.stop();
        }
        this.startConnection(this.appStoreService.user);
      }
    });

    this.hubConnection.onreconnecting(connection => {
      this.logger.logEvent(
        `SignalR Reconnecting: ${connection?.message}. Stack: ${connection?.stack}`
      );
      this.updateConnectionState();
    });

    this.hubConnection.onreconnected(connection => {
      this.logger.logEvent(`SignalR Reconnected: ${connection}.`);
      this.updateConnectionState();
    });
  };

  private getSignalRConnection = (userId: string): Observable<SignalRConnectionInfo> => {
    const apiUrl = `${this.apiBaseUrl}/realtime/negotiate`;
    return this._http.get<SignalRConnectionInfo>(apiUrl, {
      //we must pass this header so signalR can assign a UserId per connection
      //this is needed in order to add users to groups, as that UserId identifies which connection to place in a group
      headers: new HttpHeaders().set("x-ms-signalr-userid", userId)
    });
  };

  private tokenExpired(token: string) {
    const expiry = JSON.parse(atob(token.split(".")[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  public addToGroup = (userId: string | undefined, groupName: string): void => {
    if (!this.isIvyApi) {
      const notification: RealTimeNotification = {
        groupName: groupName,
        userId: userId
      };
      const index = this.addedToGroups.findIndex(
        g => g.groupName === notification.groupName && g.userId === notification.userId
      );
      if (index === -1) {
        this.addedToGroups.push(notification);
      }

      const apiUrl = `${this.apiBaseUrl}/realtime/addUserToGroup`;
      this._http.post<boolean>(apiUrl, notification).subscribe();
    }
  };

  public removeFromGroup = (userId: string | undefined, groupName: string): void => {
    if (!this.isIvyApi) {
      const notification: RealTimeNotification = {
        groupName: groupName,
        userId: userId
      };

      this.addedToGroups = this.addedToGroups.filter(
        g => !(g.groupName === notification.groupName && g.userId === notification.userId)
      );

      const apiUrl = `${this.apiBaseUrl}/realtime/removeUserFromGroup`;
      this._http.post<boolean>(apiUrl, notification).subscribe();
    }
  };

  //expose client methods signalR can call from the API
  //when SignalR calls a method, the eventAggregrator will broadcast it to anyone subscribed
  private registerClientMethods = (): void => {
    if (!this.isIvyApi) {
      this.hubConnection.on(SignalrMethods.newCaseChat, data => {
        this.logSignalrEvent(SignalrMethods.newCaseChat, data);
        this.eventAggregrator.emit(new CaseChatEvent(data), SignalrMethods.newCaseChat);
      });

      this.hubConnection.on(SignalrMethods.newAssignUser, data => {
        this.logSignalrEvent(SignalrMethods.newAssignUser, data);
        this.eventAggregrator.emit(new AssignUserEvent(data), SignalrMethods.newAssignUser);
      });

      this.hubConnection.on(SignalrMethods.updatedCase, data => {
        this.logSignalrEvent(SignalrMethods.updatedCase, data);
        this.eventAggregrator.emit(new CaseUpdatedEvent(data), SignalrMethods.updatedCase);
      });

      this.hubConnection.on(SignalrMethods.callLightActivated, data => {
        this.logSignalrEvent(SignalrMethods.callLightActivated, data);
        this.eventAggregrator.emit(
          new CallLightActivatedEvent(data),
          SignalrMethods.callLightActivated
        );
      });

      this.hubConnection.on(SignalrMethods.callLightDeactivated, data => {
        this.logSignalrEvent(SignalrMethods.callLightDeactivated, data);
        this.eventAggregrator.emit(
          new CallLightDeactivatedEvent(data),
          SignalrMethods.callLightDeactivated
        );
      });

      this.hubConnection.on(SignalrMethods.movementInRoomDetected, data => {
        this.logSignalrEvent(SignalrMethods.movementInRoomDetected, data);
        this.eventAggregrator.emit(
          new MovementInRoomDetected(data),
          SignalrMethods.movementInRoomDetected
        );
      });

      this.hubConnection.on(SignalrMethods.deviceConnectionChanged, data => {
        this.logSignalrEvent(SignalrMethods.deviceConnectionChanged, data);
        this.eventAggregrator.emit(
          new DeviceConnectionChangedEvent(data),
          SignalrMethods.deviceConnectionChanged
        );
      });

      this.hubConnection.on(SignalrMethods.deviceCamerasUpdated, data => {
        this.logSignalrEvent(SignalrMethods.deviceCamerasUpdated, data);
        this.eventAggregrator.emit(
          new DeviceCamerasUpdatedEvent(data),
          SignalrMethods.deviceCamerasUpdated
        );
      });

      this.hubConnection.on(SignalrMethods.deviceMicrophonesUpdated, data => {
        this.logSignalrEvent(SignalrMethods.deviceMicrophonesUpdated, data);
        this.eventAggregrator.emit(
          new DeviceMicrophonesUpdatedEvent(data),
          SignalrMethods.deviceMicrophonesUpdated
        );
      });

      this.hubConnection.on(SignalrMethods.deviceSpeakersUpdated, data => {
        this.logSignalrEvent(SignalrMethods.deviceSpeakersUpdated, data);
        this.eventAggregrator.emit(
          new DeviceSpeakersUpdatedEvent(data),
          SignalrMethods.deviceSpeakersUpdated
        );
      });

      this.hubConnection.on(SignalrMethods.deviceNetworkInterfacesUpdated, data => {
        this.logSignalrEvent(SignalrMethods.deviceNetworkInterfacesUpdated, data);
        this.eventAggregrator.emit(
          new DeviceNetworkInterfacesUpdatedEvent(data),
          SignalrMethods.deviceNetworkInterfacesUpdated
        );
      });

      this.hubConnection.on(SignalrMethods.deviceStethoscopesUpdated, data => {
        this.logSignalrEvent(SignalrMethods.deviceStethoscopesUpdated, data);
        this.eventAggregrator.emit(
          new DeviceStethoscopeUpdatedEvent(data),
          SignalrMethods.deviceStethoscopesUpdated
        );
      });

      this.hubConnection.on(SignalrMethods.devicePowerStatusUpdated, data => {
        this.logSignalrEvent(SignalrMethods.devicePowerStatusUpdated, data);
        this.eventAggregrator.emit(
          new DevicePowerStatusUpdatedEvent(data),
          SignalrMethods.devicePowerStatusUpdated
        );
      });

      this.hubConnection.on(SignalrMethods.caseDataChanged, data => {
        this.logSignalrEvent(SignalrMethods.caseDataChanged, data);
        this.eventAggregrator.emit(new CaseDataChangedEvent(data), SignalrMethods.caseDataChanged);
      });

      this.hubConnection.on(SignalrMethods.interactiveSessionStarted, data => {
        this.logSignalrEvent(SignalrMethods.interactiveSessionStarted, data);
        this.eventAggregrator.emit(
          new ZoomWebhookEvent(data),
          SignalrMethods.interactiveSessionStarted
        );
      });

      this.hubConnection.on(SignalrMethods.interactiveSessionEnded, data => {
        this.logSignalrEvent(SignalrMethods.interactiveSessionEnded, data);
        this.eventAggregrator.emit(
          new ZoomWebhookEvent(data),
          SignalrMethods.interactiveSessionEnded
        );
      });

      this.hubConnection.on(SignalrMethods.messageToPatient, data => {
        this.logSignalrEvent(SignalrMethods.messageToPatient, data);
        this.eventAggregrator.emit(
          new MessageToPatientEvent(data),
          SignalrMethods.messageToPatient
        );
      });

      this.hubConnection.on(
        SignalrMethods.caseStatusChanged,
        (caseStatusChanged: CaseStatusChanged) => {
          this.logSignalrEvent(SignalrMethods.caseStatusChanged, caseStatusChanged);
          this.store.dispatch(new CaseActions.SetState(caseStatusChanged.caseId));
          this.eventAggregrator.emit(
            new CaseStatusChangedEvent(caseStatusChanged),
            SignalrMethods.caseStatusChanged
          );
        }
      );
    }
  };

  private start = async () => {
    await this.hubConnection
      .start()
      .then(() => {
        this.logger.logEvent("Connected to SignalR");
        this.eventAggregrator.emit(new SignalRConnected(true), "signalRConnected");
        this.updateConnectionState();
        //add the new connection to the list of previously added groups
        this.addedToGroups.forEach(notification => {
          if (notification.groupName) {
            this.addToGroup(notification.userId, notification.groupName);
          }
        });
      })
      .catch(err => {
        this.logger.logEvent("Error while connecting to SignalR: " + err);
        setTimeout(() => this.start(), 5000);
      });
  };

  private updateConnectionState = () => {
    const events = this.hubConnection$?.value.receivedEvents;
    this.hubConnection$.next({
      state: this.hubConnection.state,
      lastUpdated: new Date(),
      connectionId: this.hubConnection.connectionId,
      receivedEvents: events
    } as SignalRHubConnection);
  };

  private logSignalrEvent(method: string, data: any) {
    if (this.hubConnection$?.value) {
      const hubConnectionSubject = this.hubConnection$?.value;
      const parsedData = JSON.stringify(data, null, 4);

      const printedEvent = {
        method: method,
        data: parsedData,
        eventTime: new Date()
      } as SignalREvent;

      //rolling list of last 20 events.
      hubConnectionSubject.receivedEvents = hubConnectionSubject.receivedEvents.slice(
        //Get the last 19
        Math.max(hubConnectionSubject.receivedEvents.length - 19, 0)
      );
      //add the new one to the beginning of the list
      hubConnectionSubject.receivedEvents.unshift(printedEvent);
      this.hubConnection$?.next(hubConnectionSubject);
    }
  }

  public copySignalrLogs = (): void => {
    this.hubConnection$.pipe(take(1)).subscribe(hub => {
      let logs =
        "SignalR Status: " +
        hub.state +
        "\nConnection Last Updated: " +
        hub.lastUpdated?.toLocaleString() +
        "\nConnectionId: " +
        hub.connectionId +
        "\n\nEvents:\n";

      let events = hub.receivedEvents
        .map(e => `${e.eventTime.toLocaleString()}: ${e.method}\n${e.data}\n`)
        .join("\n");

      if (events.length === 0) {
        events = "No events received";
      }

      logs = logs.concat(events);
      navigator.clipboard.writeText(logs);
    });
  };
}

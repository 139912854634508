<mat-form-field floatLabel="always">
  <mat-label>Tag Filters</mat-label>
  <input
    title="Search Tags"
    type="text"
    matInput
    [formControl]="searchTagsControl"
    [matAutocomplete]="auto"
  />
  <button
    type="button"
    *ngIf="searchTagsControl.value"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="clearSelection()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayTag"
    (optionSelected)="onSelected()"
  >
    <mat-option *ngFor="let tag of filteredTags$ | async" [value]="tag">
      <span>{{ tag.displayName }}</span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

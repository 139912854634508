<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    form: (form$ | async) || null,
    active: (active$ | async) || false,
    hasPending: (hasPending$ | async),
    showRolesAndGroups: (showRolesAndGroups$ | async) || false,
    reason: (reason$ | async) || null,
    verified: (verified$ | async) || false,
    optIn: optIn
  }"
>
</ng-container>

<ng-template
  #template
  let-form="form"
  let-active="active"
  let-hasPending="hasPending"
  let-showRolesAndGroups="showRolesAndGroups"
  let-reason="reason"
  let-verified="verified"
  let-optIn="optIn"
>
  <div fxLayout="row" fxLayoutAlign="center stretch">
    <div
      fxLayout="column"
      fxLayoutAlign="start center"
      [fxFlex.gt-md]="showRolesAndGroups ? 90 : 60"
      fxFlex.lt-md="100"
    >
      <mat-card *ngIf="reason" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <mat-icon [color]="reason.type">info</mat-icon>
        <div fxLayout="column" fxlayoutAlign="start stretch">
          <span>{{ reason.title }}</span>
          <span>{{ reason.reason }}</span>
        </div>
      </mat-card>

      <mat-card *ngIf="form">
        <form fxFill [formGroup]="form">
          <div fxFill fxLayout="column" fxLayoutAlign="space-between stretch">
            <div fxLayout="row wrap" fxLayoutGap="16px grid" fxLayoutAlign="start">
              <div [fxFlex]="showRolesAndGroups ? 60 : 100" fxFlex.lt-md="100">
                <div fxLayout="row wrap" fxLayoutGap="16px grid" fxLayoutAlign="start">
                  <mat-form-field fxFlex>
                    <mat-label>Email Address</mat-label>
                    <input matInput placeholder="Email Address" readonly formControlName="email" />
                    <mat-icon
                      [color]="verified ? 'primary' : 'warn'"
                      [matTooltip]="
                        verified ? 'Email Address Verified' : 'Email Address Not Verified'
                      "
                      matSuffix
                      >{{ verified ? "done" : "report_problem" }}</mat-icon
                    >
                  </mat-form-field>
                </div>
                <div
                  formGroupName="profile"
                  fxLayout="row wrap"
                  fxLayoutGap="16px grid"
                  fxLayoutAlign="start"
                >
                  <mat-form-field fxFlex="15" fxFlex.lt-sm="100">
                    <mat-label>Salutation</mat-label>
                    <input matInput placeholder="Ms/Mr" formControlName="salutation" />
                  </mat-form-field>
                  <mat-form-field fxFlex="35" fxFlex.lt-sm="100">
                    <mat-label>First Name</mat-label>
                    <input matInput placeholder="First Name" formControlName="firstName" required />
                    <mat-error>This is a required field</mat-error>
                  </mat-form-field>
                  <mat-form-field fxFlex="35" fxFlex.lt-sm="100">
                    <mat-label>Last Name</mat-label>
                    <input matInput placeholder="Last Name" formControlName="lastName" required />
                    <mat-error>This is a required field</mat-error>
                  </mat-form-field>
                  <mat-form-field fxFlex="15" fxFlex.lt-sm="100">
                    <mat-label>Suffix</mat-label>
                    <input matInput placeholder="MD" formControlName="suffix" />
                  </mat-form-field>
                  <mat-form-field fxFlex="100">
                    <mat-label>Address</mat-label>
                    <input matInput placeholder="Address" formControlName="address1" />
                  </mat-form-field>
                  <mat-form-field fxFlex="100">
                    <mat-label>Address 2</mat-label>
                    <input matInput placeholder="Address" formControlName="address2" />
                  </mat-form-field>
                  <mat-form-field fxFlex="25" fxFlex.lt-sm="100">
                    <mat-label>City, Town, or Locality</mat-label>
                    <input matInput placeholder="City, Town, or Locality" formControlName="city" />
                  </mat-form-field>
                  <mat-form-field fxFlex="25" fxFlex.lt-sm="100">
                    <mat-label>State, Province, or Territory</mat-label>
                    <input
                      matInput
                      placeholder="State, Province, or Territory"
                      formControlName="state"
                    />
                  </mat-form-field>
                  <mat-form-field fxFlex="20" fxFlex.lt-sm="100">
                    <mat-label>Postal Code</mat-label>
                    <input matInput placeholder="Postal Code" formControlName="zipCode" />
                  </mat-form-field>
                  <mat-form-field fxFlex="30" fxFlex.lt-sm="100">
                    <mat-label>Country</mat-label>
                    <input
                      matInput
                      placeholder="Country"
                      formControlName="country"
                      type="text"
                      [matAutocomplete]="auto"
                    />
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option matInput *ngFor="let option of countryList" [value]="option.code">
                        {{ option.name }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <div fxFlex="50" fxFlex.lt-sm="100" fxLayout="column">
                    <mat-form-field>
                      <mat-label>Cell Phone</mat-label>
                      <vp-phone-input formControlName="primaryPhone"></vp-phone-input>
                    </mat-form-field>
                    <ng-template [featureEnabled]="'smsOptIn'">
                      <mat-checkbox [checked]="optIn" (change)="toggleOptIn($event.checked)">
                        {{ optInVerbiage | async }}
                      </mat-checkbox>
                    </ng-template>
                  </div>

                  <mat-form-field fxFlex="50" fxFlex.lt-sm="100">
                    <mat-label>Secondary Phone</mat-label>
                    <vp-phone-input formControlName="secondaryPhone"></vp-phone-input>
                  </mat-form-field>

                  <mat-form-field fxFlex="25" fxFlex.lt-sm="100">
                    <mat-label>Date of birth</mat-label>
                    <input
                      title="Date of Birth"
                      formControlName="dateOfBirth"
                      matInput
                      [matDatepicker]="picker"
                      [placeholder]="dateFormat"
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi="true" #picker></mat-datepicker>
                  </mat-form-field>

                  <mat-form-field fxFlex="25" fxFlex.lt-sm="100">
                    <mat-label>Gender</mat-label>
                    <mat-select formControlName="gender">
                      <mat-option>Select</mat-option>
                      <mat-option value="male">Male</mat-option>
                      <mat-option value="female">Female</mat-option>
                      <mat-option value="other">Other</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <ng-template featureFlag="showProfileInsuranceField" feature="common">
                    <mat-form-field fxFlex="50" fxFlex.lt-sm="100">
                      <mat-label>Insurance Provider</mat-label>
                      <input matInput placeholder="Insurance" formControlName="insurance" />
                    </mat-form-field>
                  </ng-template>
                </div>
              </div>
              <div
                *ngxPermissionsOnly="[this.permConst.Profile.DeptmentAndGroup.Read]"
                fxLayout="column"
                fxFlex="40"
                fxFlex.lt-md="100"
                fxLayoutAlign="start stretch"
                fxLayoutGap="16px"
              >
                <div
                  class="wrapper"
                  *ngxPermissionsOnly="[permConst.Admin.User.RoleAssignment.Read]"
                >
                  <vp-user-assigned-roles></vp-user-assigned-roles>
                </div>
                <div
                  class="wrapper"
                  *ngxPermissionsOnly="[permConst.Admin.User.GroupAssignment.Read]"
                >
                  <vp-user-assigned-groups></vp-user-assigned-groups>
                </div>
                <div class="wrapper" *ngxPermissionsOnly="[permConst.Admin.User.Snippet.Read]">
                  <vp-user-snippets></vp-user-snippets>
                </div>
              </div>
            </div>
            <div
              class="action-row"
              fxLayout="row"
              fxLayoutAlign="space-between center"
              fxLayoutGap="16px"
            >
              <div fxLayout="row" fxLayoutAlign="start center">
                <span *ngIf="hasPending === true">Form has pending changes.</span>
                <span *ngIf="form.invalid === true">Form Has Errors</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="end center">
                <button mat-flat-button type="button" [routerLink]="['/default']">
                  {{ form.dirty ? "Cancel" : "Close" }}
                </button>
                <button
                  mat-flat-button
                  color="primary"
                  type="submit"
                  [disabled]="form.invalid"
                  (click)="onSave()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </mat-card>
    </div>
  </div>
</ng-template>

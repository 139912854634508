<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    dataSource: dataSource$ | async,
    pageState: pageState$ | async
  }"
>
</ng-container>
<ng-template #template let-dataSource="dataSource" let-pageState="pageState">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <vp-department-selector
      [selectedDeptId]="selectedDeptId"
      (changedEvent)="departmentSelectionChanged($event)"
    ></vp-department-selector>
    <mat-paginator
      [pageIndex]="pageState.pageIndex"
      [pageSize]="pageState.pageSize"
      [length]="pageState.totalRecords"
      [pageSizeOptions]="pageSizeOptions"
      showFirstLastButtons
      (page)="handlePageEvent($event)"
    ></mat-paginator>
  </div>
  <table
    mat-table
    matSort
    [dataSource]="dataSource"
    (matSortChange)="sortChange($event)"
    class="mat-elevation-z1"
  >
    <ng-container matColumnDef="isOnline">
      <th mat-header-cell *matHeaderCellDef>Online</th>
      <td mat-cell *matCellDef="let device">
        <vp-status-indicator [isOnline]="device.isOnline"></vp-status-indicator>
      </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="email">FQDN</th>
      <td mat-cell *matCellDef="let device">
        {{ device.email }}
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="profile.firstName">Name</th>
      <td mat-cell *matCellDef="let device">
        {{ device.profile.firstName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="profile.lastName">Description</th>
      <td mat-cell *matCellDef="let device">
        {{ device.profile.lastName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="groups">
      <th mat-header-cell *matHeaderCellDef>Groups</th>
      <td mat-cell *matCellDef="let device">
        {{ device | userGroups | async }}
      </td>
    </ng-container>
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef>Location</th>
      <td mat-cell *matCellDef="let device">
        {{ device.patientConsoleDeviceData?.deviceAssignmentData?.previousAssignment?.location }}
      </td>
    </ng-container>
    <ng-container matColumnDef="version">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="patientConsoleDeviceData.patientConsoleInfo.applicationVersion"
      >
        PAC Versions
      </th>
      <td mat-cell *matCellDef="let device">
        {{ device.patientConsoleDeviceData?.patientConsoleInfo?.applicationVersion }}
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let device">
        <button
          type="button"
          mat-icon-button
          color="primary"
          [routerLink]="['../', device.userId]"
          matTooltip="Edit"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          type="button"
          mat-icon-button
          color="primary"
          (click)="showDeleteDialog(device)"
          matTooltip="Delete"
        >
          <mat-icon>delete</mat-icon>
        </button>
        <button
          type="button"
          mat-icon-button
          color="primary"
          *ngIf="device.patientConsoleDeviceData?.signalRConnection?.isOnline"
          (click)="showAdvancedCommands(device.userId)"
          matTooltip="Advanced Commands"
        >
          <mat-icon>computer</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</ng-template>

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { DepartmentSelectorComponent } from "./department-selector.component";

@NgModule({
  imports: [CommonModule, MatFormFieldModule, MatSelectModule],
  declarations: [DepartmentSelectorComponent],
  exports: [DepartmentSelectorComponent]
})
export class DepartmentSelectorModule {}

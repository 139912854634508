<div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlexFill>
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutAlign.lt-md="space-between"
    fxFlexFill
  >
    <div fxFlex="40px" fxLayout="row" fxLayoutAlign="space-between stretch" class="menu-actions">
      <div></div>
      <mat-icon class="icon" *ngIf="opened" (click)="onSidenavToggle()"
        >keyboard_arrow_left</mat-icon
      >
      <mat-icon class="icon" *ngIf="!opened" (click)="onSidenavToggle()">menu</mat-icon>
    </div>
    <div fxLayout="column" fxFlex="calc(100% - 40px)" fxFlex.lt-md="85" [fxShow]="opened">
      <div class="menu-section" fxFlex="0 1 auto" fxLayout="column">
        <div fxFlex="40px" class="menu-header" fxLayout="row" fxLayoutAlign="space-between center">
          <div fxFlexOffset="16px" fxLayout="row" fxLayoutAlign="start center">
            {{ uiDisplayTagService.displayTags.contentManagementNav }}
          </div>
          <div class="notification-area" fxLayout="row" fxLayoutAlign="center center">
            <vp-spinner [overlay]="false" forApiUrl="/content" [diameter]="18"></vp-spinner>
          </div>
        </div>
        <div fxFlex="0 0 calc(100% - 40px)" class="overflow-scroll">
          <vp-content-nav-list
          ></vp-content-nav-list>
        </div>
      </div>
    </div>
    <div class="menu-section" fxHide fxShow.lt-md="true">
      <div
        class="menu-item"
        fxLayout="row"
        fxLayoutAlign="space-between stretch"
        (click)="authenticationService.logout()"
      >
        <div
          *ngIf="authenticationService.isLoggedIn$() | async"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          Logout
        </div>
        <div class="notification-area" fxLayout="row" fxLayoutAlign="center center">
          <mat-icon class="icon">logout</mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ProfileGuard } from "@vp/administration/user/feature-user-admin";
import { AuthenticationGuard, RedirectGuard } from "@vp/shared/guards";
import { environment } from "../environments/environment";

const routes: Routes = [
  {
    path: "home",
    data: {
      breadcrumb: "homeNav"
    },
    loadChildren: () => import("./pages/home/home.module").then(m => m.HomeModule)
  },
  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: "loggedout", redirectTo: "/home", pathMatch: "full" },
  {
    path: "forbidden",
    loadChildren: () => import("./pages/forbidden/forbidden.module").then(m => m.ForbiddenModule)
  },
  {
    path: "unauthorized",
    loadChildren: () =>
      import("./pages/unauthorized/unauthorized.module").then(m => m.UnauthorizedModule)
  },
  {
    path: "wizard",
    data: {
      breadcrumb: "caseNav"
    },
    loadChildren: () => import("@vp/case-create/feature").then(m => m.CaseCreateFeatureModule)
  },

  // BEGIN authenticated only routes
  {
    path: "login/callback",
    pathMatch: "full",
    canActivate: [AuthenticationGuard, RedirectGuard],
    children: []
  },
  {
    path: "loggedin",
    pathMatch: "full",
    canActivate: [AuthenticationGuard, RedirectGuard],
    children: []
  },
  {
    path: "default",
    pathMatch: "full",
    canActivate: [AuthenticationGuard, RedirectGuard],
    children: []
  },
  {
    path: "profile",
    canLoad: [AuthenticationGuard],
    data: {
      breadcrumb: "profile"
    },
    loadChildren: () =>
      import("@vp/administration/user/feature-user-admin").then(m => m.ProfileFeatureModule)
  },
  {
    path: "case",
    canLoad: [AuthenticationGuard],
    canActivate: [ProfileGuard],
    loadChildren: () => import("@vp/case-dashboard/feature").then(m => m.CaseDashboardFeatureModule)
  },
  {
    path: "dashboard",
    canLoad: [AuthenticationGuard],
    canActivate: [ProfileGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () => import("./dashboard/dashboard.module").then(m => m.DashboardModule)
  },
  {
    path: "cases",
    canLoad: [AuthenticationGuard],
    canActivate: [ProfileGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () => import("@vp/case-search/feature/case-search").then(m => m.CaseSearchModule)
  },
  {
    path: "devices",
    canLoad: [AuthenticationGuard],
    canActivate: [ProfileGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () => import("@vp/direct-connect/feature").then(m => m.DirectConnectFeatureModule)
  },
  {
    path: "patients",
    canLoad: [AuthenticationGuard],
    canActivate: [ProfileGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () =>
      import("@vp/patient-manager/feature-patient-manager-page").then(
        m => m.FeaturePatientManagerPageModule
      )
  },
  // {
  //   path: "subjects",
  //   canLoad: [AuthenticationGuard],
  //   canActivate: [ProfileGuard],
  //   runGuardsAndResolvers: "always",
  //   loadChildren: () => import("@vp/subject-search/feature").then(m => m.SubjectSearchModule)
  // },
  {
    path: "queue",
    canLoad: [AuthenticationGuard],
    canActivate: [ProfileGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () => import("@vp/case-queue/feature").then(m => m.CaseQueueFeatureModule)
  },
  {
    path: "administration",
    canLoad: [AuthenticationGuard],
    canActivate: [ProfileGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () =>
      import("./administration/administration.module").then(m => m.AdministrationModule)
  },
  {
    path: "about",
    outlet: "sidebar",
    canLoad: [AuthenticationGuard],
    canActivate: [ProfileGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () => import("./pages/about/about.module").then(m => m.AboutModule)
  },
  {
    path: "content",
    canLoad: [AuthenticationGuard],
    canActivate: [ProfileGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () =>
      import("@vp/content-management/feature").then(m => m.ContentManagementModule)
  },
  { path: "**", redirectTo: "/default", pathMatch: "full" }
];

export const enableTracing = false; // <-- Set to true to debug router in dev mode

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: environment.production ? false : enableTracing,
      useHash: false,
      onSameUrlNavigation: "reload",
      initialNavigation: "disabled",
      relativeLinkResolution: "legacy"
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

export function isInIframe() {
  return window !== window.parent && !window.opener;
}

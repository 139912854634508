import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  InjectionToken,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatDrawer, MatDrawerMode } from "@angular/material/sidenav";
import { Router } from "@angular/router";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { Select } from "@ngxs/store";
import { ContentDataFilter, ContentFilterState } from "@vp/content-management/data-access/content";
import { ContentManagementService } from "@vp/content-management/feature";
import { ViewContentPageComponent } from "@vp/content-management/ui/view-content-page";
import { ContentData, ContentSearch } from "@vp/models";
import { NotificationService } from "@vp/shared/notification";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { UiDisplayTagService } from "@vp/shared/store/ui";
import { BehaviorSubject, EMPTY, Observable, of, Subject } from "rxjs";
import { map, switchMap, take, takeUntil } from "rxjs/operators";

export type PageMode = "desktop" | "mobile";
export const ANGULAR_EDITOR_CONFIG = new InjectionToken<AngularEditorConfig>(
  "ANGULAR_EDITOR_CONFIG"
);

@Component({
  selector: "vp-new-content-page",
  templateUrl: "./new-content-page.component.html",
  styleUrls: ["./new-content-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ANGULAR_EDITOR_CONFIG,
      useValue: {
        editable: true,
        spellcheck: true,
        width: "auto",
        minHeight: "40em",
        placeholder: "Enter text here...",
        translate: "no",
        sanitize: true,
        outline: true,
        defaultFontSize: "3",
        defaultParagraphSeparator: "p",
        toolbarHiddenButtons: [
          ["subscript", "superscript", "heading", "fontName"],
          [
            "insertImage",
            "insertVideo",
            "link",
            "unlink",
            "customClasses",
            "backgroundColor",
            "fontSize",
            "insertHorizontalRule",
            "removeFormat",
            "toggleEditorMode"
          ]
        ]
      }
    }
  ]
})
export class NewContentPageComponent implements OnDestroy, OnInit {
  @Select(ContentFilterState.workingCopy) workingCopy$!: Observable<ContentData | null>;
  @Select(ContentFilterState.currentFilter) currentFilter$!: Observable<Partial<ContentDataFilter>>;
  @ViewChild("drawer") public drawer!: MatDrawer;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  menuMode$: Observable<MatDrawerMode>;
  opened$: Observable<boolean>;
  pageMode$: Observable<PageMode>;
  panelOpenState: boolean;
  isClicked = false;

  viewMode$: Observable<boolean>;
  isActive$: Observable<boolean>;

  form!: FormGroup;
  showSpinner$: Observable<boolean>;
  errors$!: Observable<string[]>;
  showErrors$!: Observable<boolean>;
  contentData!: ContentData;

  private readonly _destroyed$ = new Subject();
  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);
  private readonly _menuMode$ = new BehaviorSubject<MatDrawerMode>("side");
  private readonly _opened$ = new BehaviorSubject<boolean>(true);
  private readonly _pageMode$ = new BehaviorSubject<PageMode>("desktop");
  private readonly _showSpinner$ = new BehaviorSubject<boolean>(false);
  private readonly _errors$ = new BehaviorSubject<string[]>([]);
  private readonly _viewMode$ = new BehaviorSubject<boolean>(true);
  private readonly _isActive$ = new BehaviorSubject<boolean>(true);

  constructor(
    @Inject(ANGULAR_EDITOR_CONFIG) public config: AngularEditorConfig,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    public contentManagementService: ContentManagementService,
    private readonly notificationService: NotificationService,
    public permConst: PermissionsConstService,
    private dialog: MatDialog,
    public readonly uiDisplayTagService: UiDisplayTagService
  ) {
    this.menuMode$ = this._menuMode$.asObservable();
    this.opened$ = this._opened$.asObservable();
    this.pageMode$ = this._pageMode$.asObservable();
    this.panelOpenState = true;
    this.showSpinner$ = this._showSpinner$.asObservable();
    this.errors$ = this._errors$.asObservable();
    this.showErrors$ = this._errors$.pipe(map(e => e.length > 0));

    this.viewMode$ = this._viewMode$.asObservable();
    this.isActive$ = this._isActive$.asObservable();
  }
  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  ngOnInit(): void {
    this.buildForm();
    const contentDataDefault: Partial<ContentData> = {
      contentId: "",
      friendlyId: "",
      displayName: "",
      description: "",
      active: true,
      roles: [],
      permissionTags: [],
      content: "",
      contentTypeId: "",
      isPublic: true,
      createdBy: "",
      createdDateTime: new Date(),
      lastUpdatedDateTime: new Date(),
      lastUpdatedBy: "",
      tags: []
    };

    this.contentManagementService
      .loadExistingContent(contentDataDefault)
      .pipe(
        map((contentData: ContentData) => {
          if (Object.keys(contentData).length > 0) {
            this.form.patchValue(contentData, { emitEvent: false });
          }
          return contentData;
        }),
        take(1)
      )
      .subscribe();

    this.form.valueChanges
      .pipe(
        takeUntil(this._destroyed$),
        switchMap((formData: Record<string, unknown>) => {
          return this.contentManagementService.updateWorkingCopy(formData);
        })
      )
      .subscribe();
  }
  private buildForm() {
    this.form = this.formBuilder.group({
      active: [true],
      contentTypeId: ["", Validators.required],
      displayName: ["", Validators.required],
      description: [""],
      content: [""]
    });
  }
  get isLoading$(): Observable<boolean> {
    return this._isLoading$.asObservable();
  }

  onCancelClick(): void {
    this.router.navigate(["content"]);
  }

  public viewPreview = () => {
    this.openEditResponseDialog(false).subscribe();
  };

  private openEditResponseDialog = (
    editMode: boolean = false
  ): Observable<ContentSearch | null> => {
    const dialogRef = this.dialog.open(ViewContentPageComponent, {
      width: "80vw",
      data: {
        editMode
      },
      panelClass: "mobile-responsive"
    });
    return dialogRef.afterClosed().pipe(takeUntil(this._destroyed$));
  };

  save = () => {
    this._errors$.next([]);
    of(this.form)
      .pipe(
        switchMap((form: FormGroup) => {
          if (form.invalid) {
            return EMPTY;
          }
          this.isClicked = true;
          this._showSpinner$.next(true);
          return this.contentManagementService.createOrEditContent();
        })
      )
      .subscribe({
        next: () => {
          this._showSpinner$.next(false);
          this.notificationService.successMessage("Content created or modified successfully");
          this.router.navigate(["content"]);
        },
        error: error => {
          this._showSpinner$.next(false);
          this.isClicked = false;
          this._errors$.next([error]);
        }
      });
  };

  toggleviewMode() {
    this._viewMode$.next(!this._viewMode$.getValue());
  }
  toggleIsActiveMode() {
    this._isActive$.next(!this._isActive$.getValue());
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TrackByFunction
} from "@angular/core";
import { MatSelectChange } from "@angular/material/select";
import { Select } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { UserState } from "@vp/data-access/users";
import { Department, UserRole } from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import { UiDisplayTagService } from "@vp/shared/store/ui";
import { Observable, Subject } from "rxjs";
import { map, takeUntil, withLatestFrom } from "rxjs/operators";

@Component({
  selector: "vp-department-selector",
  templateUrl: "./department-selector.component.html",
  styleUrls: ["./department-selector.component.css"]
})
export class DepartmentSelectorComponent implements OnInit, OnDestroy {
  @Select(OrganizationState.departments) allDepartments$!: Observable<Department[]>;
  @Select(UserState.currentUserRole) currentUserRole$!: Observable<UserRole>;

  @Input() selectedDeptId = "all";
  @Output() changedEvent = new EventEmitter<string>();

  userDepartments$!: Observable<Department[]>;

  private readonly _destroyed$ = new Subject();

  constructor(public readonly uiDisplayTagService: UiDisplayTagService) {}
  ngOnInit(): void {
    this.userDepartments$ = this.currentUserRole$.pipe(
      filterNullMap(),
      withLatestFrom(this.allDepartments$),
      takeUntil(this._destroyed$),
      map(([userRole, allDepartments]: [UserRole, Department[]]) => {
        const userDepartmentIds: string[] = userRole.departments.map(d => d.departmentId);
        return allDepartments.filter(d => userDepartmentIds.includes(d.departmentId));
      })
    );
  }
  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  onChanged(event: MatSelectChange) {
    this.changedEvent.emit(event.value);
  }

  // Satisfies template-use-track-by-function
  trackByDepartment: TrackByFunction<Department> = (_index, item) => item.departmentId;
}

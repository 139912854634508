import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { Router } from "@angular/router";
import { Select } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { countryList, Organization, OrganizationFeatures, Role, User } from "@vp/models";
import { AccessControlService } from "@vp/shared/access-control";
import { FeatureService } from "@vp/shared/features";
import { LocaleService } from "@vp/shared/locale-service";
import { NotificationService } from "@vp/shared/notification";
import { filterNullMap } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { AppStoreService } from "@vp/shared/store/app";
import { deeperCopy, mergeDeep, RouterUtilities } from "@vp/shared/utilities";
import moment from "moment";
import { NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, combineLatest, EMPTY, from, Observable, of, Subject, zip } from "rxjs";
import {
  concatMap,
  first,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  withLatestFrom
} from "rxjs/operators";
import { UserAdministrationService } from "../user-administration-state/services/user-administration.service";

export interface IWarningReason {
  title: string;
  reason: string;
}

@Component({
  selector: "vp-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    UserAdministrationService,
    {
      provide: MAT_DATE_LOCALE,
      useFactory: (localeService: LocaleService) => {
        return localeService.getLocale();
      },
      deps: [LocaleService]
    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    {
      provide: MAT_DATE_FORMATS,
      useFactory: (localeService: LocaleService) => {
        return localeService.getDateFormat();
      },
      deps: [LocaleService]
    }
  ]
})
export class ProfileComponent implements OnInit, OnDestroy {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;

  active$: Observable<boolean>;
  countryList = countryList;
  dateFormat = "";
  emailVerificationForm = new FormGroup({});
  firstLogin$!: Observable<boolean>;
  form$!: Observable<FormGroup>;
  hasPending$: Observable<boolean>;
  imported$!: Observable<boolean>;
  reason$: Observable<IWarningReason | null>;
  showRolesAndGroups$: Observable<boolean>;
  user$!: Observable<User>;
  optIn!: boolean;

  // messages needs to be consolidated into configurationlists
  optInVerbiage = this.featureService.messages$(OrganizationFeatures.smsOptIn).pipe(
    map(messages => {
      const defaultOptInMsg = "Opt-in SMS notification";
      if (messages) {
        return messages["optInMessage"] ?? defaultOptInMsg;
      }
      return defaultOptInMsg;
    })
  );

  // This observable is used for email verification, and the functionality is disabled
  // so it always returns true by default until we need it again for a new app instance
  verified$!: Observable<boolean>;

  private _destroyed$ = new Subject();
  private _form$ = new BehaviorSubject<FormGroup | null>(null);

  constructor(
    public permConst: PermissionsConstService,
    private localeService: LocaleService,
    private readonly formBuilder: FormBuilder,
    private readonly appStoreService: AppStoreService,
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly accessControlService: AccessControlService,
    private readonly router: Router,
    private readonly userAdministrationService: UserAdministrationService,
    private readonly routerUtilitiesService: RouterUtilities,
    private readonly notificationService: NotificationService,
    private readonly featureService: FeatureService
  ) {
    this.buildForm();

    this.user$ = this.appStoreService.userStream.pipe(filterNullMap(), take(1));
    this.form$ = this._form$.pipe(filterNullMap());
    this.active$ = this.user$.pipe(map(user => user.active));

    this.imported$ = this.user$.pipe(
      map(user => user.tags),
      map(tags => tags.includes("import")),
      takeUntil(this._destroyed$)
    );

    this.verified$ = this.user$.pipe(
      map(user => user.tags),
      withLatestFrom(this.imported$),
      map(([tags, imported]) => {
        if (imported) {
          return tags.includes("email.verified");
        }
        return true;
      }),
      takeUntil(this._destroyed$)
    );

    this.firstLogin$ = this.user$.pipe(
      shareReplay(1),
      map(user => user.tags),
      map(tags => {
        return !tags.includes("profile.complete");
      }),
      takeUntil(this._destroyed$)
    );

    this.user$
      .pipe(
        concatMap(user =>
          zip(
            of(user),
            this.firstLogin$,
            this.featureService.featureEnabled$(OrganizationFeatures.smsOptIn),
            this.featureService
              .configurationLists$(OrganizationFeatures.smsOptIn)
              .pipe(map(configs => (configs ? configs["defaultOptInDepartments"] : null)))
          )
        ),
        takeUntil(this._destroyed$)
      )
      .subscribe(([user, firstLogin, smsFeatureEnabled, defaultOptInDepartments]) => {
        if (smsFeatureEnabled) {
          // on first login, default sms opt in to true if user belongs to one of the default opt in departments
          if (firstLogin) {
            this.optIn = user.roles.some(r => {
              for (let department of r.departments) {
                if (department.friendlyId) {
                  const inDepartment =
                    defaultOptInDepartments !== null
                      ? defaultOptInDepartments.includes(department.friendlyId)
                      : false;
                  if (inDepartment) {
                    return true;
                  }
                }
              }
              return false;
            });
          } else {
            this.optIn = user.tags.includes("sms-opt-in");
          }
        } else {
          this.optIn = false;
        }
      });

    this.hasPending$ = this.form$.pipe(map(form => form.dirty === true));

    this.showRolesAndGroups$ = this.ngxPermissionsService.permissions$.pipe(
      filterNullMap(),
      mergeMap(() => {
        return from(
          this.ngxPermissionsService.hasPermission([this.permConst.Profile.DeptmentAndGroup.Read])
        );
      })
    );

    this.reason$ = combineLatest([this.firstLogin$, this.imported$]).pipe(
      map(([firstLogin, _imported]) => {
        if (firstLogin) {
          return {
            type: "accent",
            title: "Thank you for registering!",
            reason:
              "Some details are missing from your profile. Please add the missing information to continue."
          } as IWarningReason;
        }
        return null;
      }),
      takeUntil(this._destroyed$)
    );
  }

  ngOnInit(): void {
    this.dateFormat = this.localeService.getLocaleDateFormat();

    // TODO: This is temporary until we can consolidate the user state it is just
    // required for the roles and groups components right now
    this.appStoreService.userStream
      .pipe(
        filterNullMap(),
        take(1),
        mergeMap(user => {
          return this.userAdministrationService.loadUser(user);
        })
      )
      .subscribe();

    this.appStoreService.userStream
      .pipe(
        filterNullMap(),
        map(user => deeperCopy(user)),
        withLatestFrom(this._form$.pipe(filterNullMap())),
        takeUntil(this._destroyed$)
      )
      .subscribe(([user, form]: [User, FormGroup]) => {
        if (user.profile.dateOfBirth) {
          /* I hate this but I cant think of another way around this, the moment
           * date adapter is turning this into a moment object which throws an
           * error so this just converts it back to a js date.
           */
          user.profile.dateOfBirth = moment(user.profile.dateOfBirth).toDate();
        }
        form.patchValue(user, { emitEvent: false });
        if (form.invalid) {
          form.markAllAsTouched();
        }
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private buildForm() {
    const profile = this.formBuilder.group({
      salutation: [null],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      suffix: [null],
      gender: [null],
      dateOfBirth: [null],
      address1: [null],
      address2: [null],
      city: [null],
      state: [null],
      zipCode: [null],
      country: [null],
      primaryPhone: [null],
      secondaryPhone: [null],
      insurance: [null]
    });

    let form = this.formBuilder.group({
      email: [{ value: null, disabled: true }],
      profile
    });

    this.registerForm(form);
  }

  registerForm(form: FormGroup) {
    this._form$.next(form);
  }

  onSave = () => {
    this.form$
      .pipe(
        withLatestFrom(this.user$),
        switchMap(([form, user]: [FormGroup, User]) => {
          if (form.valid) {
            var modified = mergeDeep(user, form.value);
            if (!modified.tags.includes("profile.complete")) {
              modified.tags.push("profile.complete");
            }
            if (this.optIn) {
              if (!modified.tags.includes("sms-opt-in")) {
                modified.tags.push("sms-opt-in");
              }
            } else {
              modified.tags = modified.tags.filter((t: string) => t !== "sms-opt-in");
            }
            return this.appStoreService.patchUser(modified);
            //return firstLogin;
          }
          return EMPTY;
        }),
        mergeMap(() => this.gotoCreateCaseIfNeeded()),
        first()
      )
      .subscribe({
        next: (success: boolean) => {
          if (success) {
            this.notificationService.successMessage("Profile saved");
          } else {
            this.notificationService.errorMessage(
              "There was a problem saving your profile information."
            );
          }
        }
      });
  };

  gotoCreateCaseIfNeeded = () => {
    return this.firstLogin$.pipe(
      withLatestFrom(
        this.organization$.pipe(map(org => org.autoCreateInitialCaseRoles ?? [])),
        this.appStoreService.selectedRole
      ),
      switchMap(([firstLogin, autoCreateRoles, selectedRole]: [boolean, string[], Role]) => {
        if (firstLogin) {
          return this.accessControlService.userSelectedRoleIncludes(autoCreateRoles).pipe(
            switchMap(hasRoles => {
              if (hasRoles) {
                return this.router.navigate(["wizard"]);
              }

              const { path, queryParams } =
                this.routerUtilitiesService.getRouteDefaultFromRole(selectedRole);
              return this.router.navigate([path], { queryParams });
            })
          );
        }
        return of(true);
      }),
      first()
    );
  };

  toggleOptIn(event: boolean) {
    this.optIn = event;
  }
}

import { PageState, User } from "@vp/models";
import { defaultDeviceAdminFilter, DeviceAdminFilter } from "./device-admin-filter";

export interface DeviceAdminStateModel {
  mode: "desktop" | "mobile";
  opened: boolean;
  filter: DeviceAdminFilter;
  pageState: PageState;
  devices: User[];
  errors: string[];
  selectedDevice: User | null;
}

export const defaultDeviceAdminState = (): DeviceAdminStateModel => {
  return {
    mode: "desktop",
    opened: true,
    filter: defaultDeviceAdminFilter(),
    pageState: {
      totalRecords: 0,
      pageIndex: 0,
      pageCount: 0,
      pageSize: 10,
      lastPage: 1
    },
    devices: [],
    errors: [],
    selectedDevice: null
  };
};

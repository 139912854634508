import { Injectable, InjectionToken, OnDestroy } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import * as ContentFilterStateActions from "@vp/content-management/data-access/content";
import {
  ContentApiService,
  ContentDataFilter,
  ContentFilterState,
  ContentOperations
} from "@vp/content-management/data-access/content";
import { OrganizationState } from "@vp/data-access/organization";
import { ContentData, Organization, User } from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import { AppStoreService } from "@vp/shared/store/app";
import { camelize, mergeDeep } from "@vp/shared/utilities";
import { nanoid } from "nanoid";
import { createPatch } from "rfc6902";
import { combineLatest, EMPTY, Observable, of, Subject, throwError, zip } from "rxjs";
import { concatMap, map, switchMap, take, takeUntil, tap, withLatestFrom } from "rxjs/operators";

export const CONTENT_MANAGEMENT_API_BASE_URL = new InjectionToken<string>("API_BASE_URL");

export const DEFAULT_PAGER_LIST = [5, 10, 25, 100];
export const DEFAULT_PAGER_SKIP = 0;
export const DEFAULT_PAGER_TAKE = 10;
export const DEFAULT_MOBILE_PAGER_TAKE = 50;

@Injectable({
  providedIn: "root"
})
export class ContentManagementService implements OnDestroy {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;
  @Select(ContentFilterState.workingCopy) workingCopy$!: Observable<ContentData | null>;
  @Select(ContentFilterState.contentData) contentData$!: Observable<ContentData | null>;

  @Select(ContentFilterState.pendingOperations)
  pendingOperations$!: Observable<ContentOperations | null>;
  private readonly _destroyed$ = new Subject();

  contentTypes$ = this.organization$.pipe(
    filterNullMap(),
    map(org => {
      return org.contentTypes ?? [];
    }),
    takeUntil(this._destroyed$)
  );

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store,
    private readonly contentApiService: ContentApiService,
    private readonly appStoreService: AppStoreService
  ) {}

  get pageParams() {
    return this.getQueryParams().pipe(
      map((paramMap: ParamMap) => {
        return this.getStateFromParams(paramMap);
      })
    );
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  setWorkingCopy(workingCopy: ContentData) {
    return this.workingCopy$?.pipe(
      filterNullMap(),
      switchMap((original: ContentData) => {
        return combineLatest([
          of(original),
          of(workingCopy).pipe(
            tap(workingCopy =>
              this.store.dispatch(new ContentFilterStateActions.SetWorkingCopy(workingCopy))
            )
          )
        ]);
      })
    );
  }

  loadExistingContent(partialContentData: Partial<ContentData>): Observable<ContentData> {
    this.store.dispatch(new ContentFilterStateActions.LoadContentData(partialContentData, true));
    return this.pageParams.pipe(
      map((paramMap: PageParams) => {
        return {
          contentId: paramMap.contentId || null
        } as Partial<ContentDataFilter>;
      }),
      concatMap((params: Partial<ContentDataFilter>) => {
        if (params?.contentId) {
          return this.contentApiService.getContent(params.contentId);
        }
        return EMPTY;
      }),
      tap((workingCopy: ContentData) => {
        if (workingCopy) {
          this.store.dispatch(new ContentFilterStateActions.SetWorkingCopy(workingCopy));
          this.store.dispatch(new ContentFilterStateActions.SetContentData(workingCopy));
        }
      })
    );
  }

  updateWorkingCopy = (formData: Record<string, unknown>) => {
    return this.appStoreService.user$.pipe(
      take(1),
      withLatestFrom(
        this.workingCopy$.pipe(filterNullMap()),
        this.contentData$.pipe(filterNullMap())
      ),
      switchMap(([user, workingCopy, original]: [User, ContentData, ContentData]) => {
        const updated = {
          ...workingCopy,
          createdBy: workingCopy.createdBy == "" ? user.userId : workingCopy.createdBy,
          lastUpdatedBy: user.userId,
          contentId: workingCopy.contentId == "" ? nanoid() : workingCopy.contentId,
          friendlyId: camelize(workingCopy.displayName),
          tags: ["Tag 1", "Tag 2"]
        };
        const merged: ContentData = mergeDeep(updated, formData, "merge", true);
        return zip(
          of(original),
          of(merged),
          this.store.dispatch(new ContentFilterStateActions.SetWorkingCopy(merged))
        );
      }),
      map(([original, merged]) => {
        return this.getOperations(original, merged);
      }),
      tap(contentOperations => {
        this.store.dispatch(new ContentFilterStateActions.SetPendingOperations(contentOperations));
      })
    );
  };

  getQueryParams = (): Observable<ParamMap> => {
    return this.activatedRoute.queryParamMap;
  };

  private getOperations(original: ContentData, updated: ContentData) {
    return {
      contentId: updated.contentId,
      operations: createPatch(original, updated)
    } as ContentOperations;
  }

  private getStateFromParams(paramMap: ParamMap): PageParams {
    const search = paramMap.get("search") || null;
    const take = Number(paramMap.get("take"));
    const skip = Number(paramMap.get("skip"));
    const contentId = paramMap.get("contentId");
    return {
      pageSizeOptions: DEFAULT_PAGER_LIST,
      search: search,
      take: take,
      skip: skip,
      pageIndex: skip / take,
      contentTypeId: paramMap.get("contentTypeId") || "all",
      contentId: contentId
    } as PageParams;
  }

  createOrEditContent() {
    return this.workingCopy$.pipe(
      filterNullMap(),
      take(1),
      switchMap((content: ContentData) => {
        if (!content) {
          return throwError("Content working copy was not set.");
        }
        if (!content.tags) {
          return throwError("Please assign one or more tags to Content");
        }
        return of(content);
      }),
      withLatestFrom(this.pageParams, this.pendingOperations$),
      switchMap(
        ([content, params, pendingOperations]: [
          ContentData,
          PageParams,
          ContentOperations | null
        ]) => {
          if (!params.contentId) {
            return this.contentApiService.createContent(content);
          }
          return this.contentApiService.patch(
            pendingOperations?.contentId,
            pendingOperations?.operations
          );
        }
      )
    );
  }
}

export interface PageParams {
  pageSizeOptions: number[];
  search: string;
  take: number;
  skip: number;
  pageIndex: number;
  deptId: string;
  contentTypeId: string;
  contentId: string;
}

export interface UserFilter {
  take: number;
  skip: number;
  search?: string | null | undefined;
  tagIds?: string[] | null | undefined;
  sort?: string | null | undefined;
  sortDirection?: string | null | undefined;
  filters?: string[] | null | undefined;
}

export const defaultUserFilter = (): UserFilter => {
  return {
    take: 25,
    skip: 0,
    tagIds: [],
    sort: "email",
    sortDirection: "asc"
  };
};

<mat-form-field>
  <mat-label>Tag Types</mat-label>
  <mat-select
    [formControl]="formControl"
    [multiple]="multiple"
    #selectionList
    (selectionChange)="selectionChanged($event)"
  >
    <mat-option *ngFor="let tagType of availableTagTypes | async" [value]="tagType">{{
      tagType.displayName
    }}</mat-option>
  </mat-select>
</mat-form-field>

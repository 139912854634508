<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="1rem">
  <div
    *ngIf="authenticationService.isLoggedIn$() | async"
    [matMenuTriggerFor]="userMenu"
    class="user"
    fxLayout="row"
    fxLayoutAlign="end center"
    aria-label="User Menu"
  >
    <mat-icon color="primary" inline="true">account_circle</mat-icon>
    <div fxLayout="column" fxLayoutAlign="center stretch" fxShow fxHide.lt-lg>
      <span class="full-name">{{ userDisplayName$ | async }}</span>
      <span class="selected-role">{{ selectedRoleDisplayName$ | async }}</span>
    </div>
  </div>

  <mat-menu #userMenu="matMenu">
    <div
      mat-menu-item
      *ngxPermissionsOnly="[permConst.Global.ProfileDetailsPage.View]"
      [routerLink]="'/profile'"
    >
      {{ uiDisplayTagService.displayTags.profileNav }}
    </div>
    <div mat-menu-item [matMenuTriggerFor]="switchRoles">Switch Roles</div>
    <div mat-menu-item (click)="onLogout()">Logout</div>
  </mat-menu>

  <mat-menu #switchRoles="matMenu">
    <div
      *ngFor="let role of appStoreService.userRoles$ | async; trackBy: trackByFn"
      mat-menu-item
      (click)="switchUserRole(role.roleId)"
    >
      {{ role.displayName }}
    </div>
  </mat-menu>

  <div *ngIf="isIvyApi; else login">
    <span>Ivy</span>
  </div>

  <ng-template #login>
    <button
      type="button"
      mat-icon-button
      *ngIf="(authenticationService.isLoggedIn$() | async) === false"
      (click)="onLogin()"
      matTooltip="Login"
    >
      <mat-icon class="login" inline="true"> login </mat-icon>
    </button>
  </ng-template>
</div>

<div
  *ngIf="signalREnabled$ | async"
  class="signalr-popover-parent"
  #popoverTrigger="mtxPopoverTrigger"
  [mtxPopoverTriggerFor]="popover"
>
  <mtx-popover
    class="signalr-popover-panel"
    #popover="mtxPopover"
    [enterDelay]="3000"
    [leaveDelay]="1000"
  >
    <div>SignalR Status: {{ (signalRService.getHubConnection$ | async)?.state }}</div>
    <mat-list
      dense
      role="list"
      *ngIf="((signalRService.getHubConnection$ | async)?.receivedEvents)!.length > 0"
    >
      <div>Events:</div>
      <mat-list-item
        role="listitem"
        class="incoming-event"
        *ngFor="let item of (signalRService.getHubConnection$ | async)?.receivedEvents"
      >
        {{ item.eventTime | date: "mediumTime" }}: {{ item.method }}
        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-list>
    <button type="button" (click)="signalRService.copySignalrLogs()">Copy Debug Info</button>
  </mtx-popover>
</div>

<ng-container *ngIf="(isPaid | async) === false">
  <p>
    A fee of <b>{{ fee | async | currency }}</b> is payable.
  </p>
  <div fxLayout="row" fxLayoutAlign="center" *ngIf="(isLoading | async) === true">
    <mat-spinner diameter="40"></mat-spinner>
  </div>

  <form
    (ngSubmit)="onSubmit(form)"
    #form="ngForm"
    fxLayout="column"
    fxLayoutGap="10px"
    fxLayoutAlign="space-around"
    [ngClass]="{ isLoading: isLoading | async }"
  >
    <div>
      <label id="cardNumber-label">Card Number</label>
      <div id="number-container" class="form-control"></div>
    </div>
    <div>
      <label for="securityCode-container">Security Code</label>
      <div id="securityCode-container" class="form-control"></div>
    </div>

    <div>
      <label for="expMonth">Expiry month</label>
      <select id="expMonth" class="form-control" name="expMonth" ngModel>
        <option>01</option>
        <option>02</option>
        <option>03</option>
        <option>04</option>
        <option>05</option>
        <option>06</option>
        <option>07</option>
        <option>08</option>
        <option>09</option>
        <option>10</option>
        <option>11</option>
        <option>12</option>
      </select>
    </div>
    <div>
      <label for="expYear">Expiry year</label>
      <select id="expYear" class="form-control" name="expYear" ngModel>
        <option *ngFor="let year of yearList">{{ year }}</option>
      </select>
    </div>
    <button mat-raised-button color="primary">Pay</button>
  </form>
</ng-container>

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { QuickSearchModule } from "@vp/shared/components/quick-search";
import { TagTypeSelectorModule } from "@vp/shared/components/tag-type-selector";
import { ContextDisplayModule } from "@vp/shared/pipes/context-display";
import { FilterTermPipeModule } from "@vp/shared/pipes/filter-term";
import { UtilitiesModule } from "@vp/shared/utilities";
import { UserAssignAccessTagsComponent } from "./user-assign-access-tags.component";

@NgModule({
  imports: [
    CommonModule,
    ContextDisplayModule,
    FilterTermPipeModule,
    FlexLayoutModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatListModule,
    MatSelectModule,
    MatTableModule,
    QuickSearchModule,
    ReactiveFormsModule,
    TagTypeSelectorModule,
    UtilitiesModule
  ],
  declarations: [UserAssignAccessTagsComponent],
  exports: [UserAssignAccessTagsComponent]
})
export class UserAssignAccessTagsModule {}

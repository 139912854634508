import { ContentData, PageState } from "@vp/models";
import { ContentDataFilter } from "../models/content-data-filter";
import { ContentOperations } from "../models/content-operations.model";
import { ContentFilterStateModel } from "./content-filter.state";

export class SetFilter {
  public static readonly type = "[ContentSearch Filter] Set Filter";
  constructor(public filter: Partial<ContentDataFilter>) {}
}

export class GetFiltered {
  public static readonly type = "[ContentSearch Filter] Get Filtered";
}

export class SetPageState {
  public static readonly type = "[ContentSearch Filter] Set Page State";
  constructor(public pageState: Partial<PageState>) {}
}

export class UpdateState {
  public static readonly type = "[ContentSearch Filter] Set Initial State";
  constructor(public state: ContentFilterStateModel) {}
}

export class LoadState {
  public static readonly type = "[ContentSearch Filter] Load State";
}

export class DeleteContent {
  public static readonly type = "[Content Data] Delete Content";
  constructor(public contentId: string) {}
}
export class LoadContentData {
  public static readonly type = "[Content Data] Load Content Data";
  constructor(public contentData: Partial<ContentData>, public active: boolean = true) {}
}

export class SetWorkingCopy {
  public static readonly type = "[Content Data] Set Working Copy";
  constructor(public contentData: ContentData) {}
}

export class Patch {
  public static readonly type = "[Content Data] Update";
  constructor(public contentData: ContentData) {}
}

export class SetContentData {
  public static readonly type = "[Content Data] Set Content Data";
  constructor(public contentData: ContentData) {}
}

export class SetPendingOperations {
  public static readonly type = "[Content Data] Set Pending Operations";
  constructor(public contentOperations: ContentOperations) {}
}
